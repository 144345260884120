import { mdiFilterVariant } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Button, Grid, Skeleton, Typography } from '@mui/material';
import { useState } from 'react';

import { useUserContext } from '../../contexts/User.context';
import { CartaoUsuario } from '../../models/CartaoUsuario.model';
import { FiltroAssociarCartao } from '../../models/FiltroAssociarCartao';
import CartaoService from '../../services/Cartao.service';
import { Colors } from '../../shared/colors';
import useInfiniteScroll from '../../shared/hooks/infiniteScroll';
import CardAssociarCartao from '../components/CardAssociarCartao';
import DrawerFiltrarAssociarCartoes from '../features/filtrar-associar-cartoes/DrawerFiltrarAssociarCartoes';
import DrawerGerarImportarCartao from '../features/gerar-importar-cartao/DrawerGerarImportarCartao';

const AssociarCartao = () => {
  const [openDrawerArquivo, setOpenDrawerArquivo] = useState(false);
  const [openDrawerFiltroUsuario, setOpenDrawerFiltroUsuario] = useState(false);
  const [filtroUsuarios, setFiltroUsuarios] = useState<FiltroAssociarCartao>();
  const { userClaim } = useUserContext();

  const closeDrawerFiltro = (Filtro: FiltroAssociarCartao) => {
    setOpenDrawerFiltroUsuario(false);
    setFiltroUsuarios(Filtro);
  };

  const { loading, items, lastElementRef, hasNext, refresh } =
    useInfiniteScroll<CartaoUsuario, FiltroAssociarCartao>({
      load: CartaoService.listarClientes,
      search: filtroUsuarios
    });

  const carregandoItens = (loading && hasNext) || loading;
  const admin = userClaim === 'Administrativo';

  return (
    <Grid container spacing={2} paddingY={2}>
      <Grid
        item
        container
        xs={12}
        spacing={2}
        alignItems="center"
        display="flex"
      >
        <Grid
          item
          container
          xs={12}
          display="flex"
          justifyContent="space-between"
        >
          <Grid item sm={12} md={6}>
            <Typography variant="h5" color={Colors.secondary} fontWeight={700}>
              Associação de cartões
            </Typography>
          </Grid>
          <Grid
            item
            sm={12}
            md={6}
            gap={2}
            container
            alignItems="center"
            mt={{ xs: 1, md: 0 }}
            justifyContent={{ xs: 'space-between', md: 'flex-end' }}
          >
            {admin && (
              <Box>
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{ height: 32 }}
                  onClick={() => {
                    setOpenDrawerArquivo(true);
                  }}
                >
                  <Typography textTransform="none">Gerar / Importar</Typography>
                </Button>
              </Box>
            )}
            <Box
              display="flex"
              onClick={() => setOpenDrawerFiltroUsuario(true)}
              sx={{ cursor: 'pointer' }}
              gap={1}
            >
              <Typography fontWeight="bold" color={Colors.primary.main}>
                Filtrar
              </Typography>
              <Icon
                path={mdiFilterVariant}
                size={1}
                color={Colors.primary.main}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {items.map((cartaoUsuario, index, arr) => {
          const lastElement = arr.length === index + 1;
          const ref = lastElement && hasNext ? lastElementRef : null;
          return (
            <div ref={ref} key={cartaoUsuario.idCarteira}>
              <CardAssociarCartao
                onClose={refresh}
                cartaoUsuario={cartaoUsuario}
              />
            </div>
          );
        })}
      </Grid>

      {carregandoItens && (
        <Grid container gap={2} ml={2}>
          {Array(5)
            .fill(1)
            .map((_item, index) => index)
            .map((value) => (
              <Skeleton
                key={value}
                variant="rounded"
                width="100%"
                height={70}
              />
            ))}
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography
          mt={3}
          variant="body1"
          fontWeight={500}
          color={Colors.gray1}
          textAlign="center"
        >
          {!hasNext && !loading && 'Não há dados a serem exibidos'}
        </Typography>
      </Grid>

      <DrawerFiltrarAssociarCartoes
        open={openDrawerFiltroUsuario}
        onClose={closeDrawerFiltro}
      />
      <DrawerGerarImportarCartao
        open={openDrawerArquivo}
        onClose={() => {
          setOpenDrawerArquivo(false);
        }}
      />
    </Grid>
  );
};

export default AssociarCartao;
