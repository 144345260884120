import { mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography
} from '@mui/material';
import { Colors } from '@vabembarqueja/embarque-ja-shared-components';
import { PropsWithChildren } from 'react';

interface Props {
  titulo: string;
}

const Instruction = ({ titulo, children }: PropsWithChildren<Props>) => {
  return (
    <Box py={1}>
      <Accordion sx={{ border: `1px solid ${Colors.primary.main}` }}>
        <AccordionSummary
          id="panel1a-header"
          onClick={() => {}}
          aria-controls="panel1a-content"
          expandIcon={
            <Icon
              size={1.3}
              path={mdiChevronDown}
              color={Colors.primary.main}
            />
          }
        >
          <Typography variant="h6" color={Colors.primary.main}>
            {titulo}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box pb={2}>
            <Divider />
          </Box>
          {children}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default Instruction;
