import { useState } from 'react';

import {
  FiltroUsuario,
  FILTRO_USUARIO
} from '../../../models/FiltroUsuario.model';
import Drawer from '../../components/Drawer';

import FiltrarUsuario from './FiltrarUsuario';

interface Props {
  open: boolean;
  onClose: (data: FiltroUsuario) => void;
  permitirFiltrarUsuarios?: boolean;
}

const DrawerFiltrarUsuario = ({ open, onClose }: Props) => {
  const [filtroUsuario, setFiltroUsuario] =
    useState<FiltroUsuario>(FILTRO_USUARIO);

  const onCloseDrawer = (filtroUsuario: FiltroUsuario) => {
    setFiltroUsuario(filtroUsuario);
    onClose(filtroUsuario);
  };

  return (
    <Drawer
      title="Filtrar"
      open={open}
      onClose={() => {
        onCloseDrawer(filtroUsuario);
      }}
    >
      <FiltrarUsuario
        filtroUsuario={filtroUsuario}
        onChangeFilter={setFiltroUsuario}
        onClose={onCloseDrawer}
      />
    </Drawer>
  );
};

export default DrawerFiltrarUsuario;
