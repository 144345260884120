import { Box } from '@mui/material';

import { BASE_CONFIG } from '../../shared/baseConfig';
import Alert from '../components/Alert.doc';
import Instruction from '../components/Instruction.doc';
import Step from '../components/Step.doc';

export const EditarUsuario = () => (
  <Instruction titulo="Editar dados da conta de Pessoa Jurídica">
    <Box display="flex" flexDirection="column" alignItems="center">
      <Step descricao={`1. Navegue para ${BASE_CONFIG.App.ClienteUrl}`} />
      <Step
        descricao='2. Clique em "Minha conta"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/07292d61-9853-4124-91c1-a8a881d5a12b/ascreenshot.jpeg?tl_px=967,0&br_px=1827,480&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,18"
      />
      <Alert
        descricao="Alert: Edite os campos desejados para atualizar seu cadastro. Alguns campos não podem ser
    atualizados e estarão desabilitados por padrão."
      />

      <Step
        descricao='3. Clique em "Salvar alterações"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/457fec43-2eeb-4f8b-8413-cf6ba7a911f5/ascreenshot.jpeg?tl_px=970,448&br_px=1830,929&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,426"
      />

      <Step
        descricao='4. Clique em "Concluir"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/83907861-34fa-421f-b877-b55822c09b87/ascreenshot.jpeg?tl_px=573,309&br_px=1433,790&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
    </Box>
  </Instruction>
);
