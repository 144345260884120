import { mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Grid } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

import { Colors } from '../../../shared/colors';

import { BloqueioCliente } from './BloqueioCliente';
import { CargaDistancia } from './CargaDistancia';
import { ClientePosPago } from './ClientePosPago';
import { DetalheCliente } from './DetalheCliente';
import { MigracaoClientePos } from './MigracaoClientePos';
import { SaldoCliente } from './SaldoCliente';

interface Props {
  onClickEdit?: () => void;
  infoContaCliente: ReactNode;
  infoSaldoCliente?: ReactNode;
  infoContaBloqueada?: ReactNode;
}

const CardCliente = ({
  onClickEdit,
  infoContaCliente,
  infoSaldoCliente,
  infoContaBloqueada,
  children
}: PropsWithChildren<Props>) => {
  return (
    <Grid
      container
      gap={2}
      border={1}
      marginBottom={2}
      borderRadius={1}
      alignItems="center"
      justifyContent="space-between"
      sx={{ borderColor: Colors.primary.main }}
    >
      <Grid item xs={12}></Grid>
      {infoContaCliente}

      <Grid item display="flex" alignItems="center" flexDirection="column">
        {children}
      </Grid>
      <Grid item display="flex" alignItems="center" flexDirection="column">
        {infoSaldoCliente}
      </Grid>
      {onClickEdit && (
        <Box
          mr={2}
          onClick={onClickEdit}
          sx={{ cursor: 'pointer' }}
          borderColor={Colors.primary.main}
        >
          <Icon path={mdiPencil} size={1} color={Colors.primary.main} />
        </Box>
      )}
      <Grid item xs={12}>
        {infoContaBloqueada}
      </Grid>
    </Grid>
  );
};

CardCliente.DetalheCliente = DetalheCliente;
CardCliente.SaldoCliente = SaldoCliente;
CardCliente.MigracaoClientePos = MigracaoClientePos;
CardCliente.ClientePosPago = ClientePosPago;
CardCliente.CargaDistancia = CargaDistancia;
CardCliente.BloqueioCliente = BloqueioCliente;
export default CardCliente;
