import { Box, Button, Grid, InputLabel, Typography } from '@mui/material';
import { useState } from 'react';

import { Colors } from '../../../shared/colors';
import FileUpload from '../../components/FileUpload';

interface Props {
  importarCartoes: (data: FormData) => void;
}

const FormImportarCartao = ({ importarCartoes }: Props) => {
  const [files, setFiles] = useState<File[]>([]);

  const importarArquivo = () => {
    const formData = new FormData();
    const [file] = files;
    formData.set('requestFile', file);
    importarCartoes(formData);
  };

  return (
    <>
      <Grid item xs={12} mt={4}>
        <Typography variant="h6" color={Colors.secondary}>
          Importar arquivo de cartões
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <InputLabel margin="dense">
          Clique ou arraste um arquivo (.csv) para adicionar.
        </InputLabel>
        <Box mt={0.5}>
          <FileUpload accept={{ 'text/csv': ['.csv'] }} onChange={setFiles} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          disabled={!files.length}
          onClick={importarArquivo}
        >
          <Typography textTransform="none">Importar</Typography>
        </Button>
      </Grid>
    </>
  );
};
export default FormImportarCartao;
