import { BilhetePassagem } from './LinkPagamento.model';
import { TipoPagamento } from './TipoPagamento.model';

export const TIPO_TRANSACAO = {
  1: 'Saldo adicionado',
  2: 'Compra de passagem',
  3: 'Estorno de passagem',
  4: 'Transação creditada',
  5: 'Transferência enviada',
  6: 'Transferência recebida',
  7: 'Transferência estornada'
} as const;

export type TipoTransacao = keyof typeof TIPO_TRANSACAO;
export interface Transacao {
  idTransacao: string;
  dataHora: Date | null;
  valor: number;
  tipoPagamento: TipoPagamento;
  tipoTransacao: TipoTransacao;
  tipoTransacaoDescricao: string;
  idAutorizacao: string;
  origemDestinoCarteira: string | null;
  origemDestinoNome: string | null;
  origemDestinoCpfCnpj: string | null;
  bilhetes?: BilhetePassagem[];
}
