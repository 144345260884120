import {
  AssociarCartao,
  ASSOCIAR_CARTAO_DEFAULT
} from '../../../models/AssociarCartao.model';
import { CartaoUsuario } from '../../../models/CartaoUsuario.model';
import Drawer from '../../components/Drawer';

import FormAssociarCartao from './FormAssociarCartao';

interface Props {
  cartaoUsuario: CartaoUsuario;
  open: boolean;
  onClose: () => void;
}

const DrawerAssociarCartao = ({ cartaoUsuario, open, onClose }: Props) => {
  const associarCartao: AssociarCartao = {
    ...ASSOCIAR_CARTAO_DEFAULT,
    usuarioId: cartaoUsuario.usuarioId,
    idCarteira: cartaoUsuario.idCarteira,
    numeroCartao: cartaoUsuario.numeroCartaoFisico
  };

  return (
    <Drawer title="Associar cartão" open={open} onClose={onClose}>
      <FormAssociarCartao onClose={onClose} associarCartao={associarCartao} />
    </Drawer>
  );
};

export default DrawerAssociarCartao;
