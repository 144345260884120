import { Box } from '@mui/material';

import { BASE_CONFIG } from '../../shared/baseConfig';
import Instruction from '../components/Instruction.doc';
import Step from '../components/Step.doc';
import Tip from '../components/Tip.doc';

export const FiltrarTransacoes = () => (
  <Instruction titulo="FIltrar transações da conta no EmbarqueJá">
    <Box display="flex" flexDirection="column" alignItems="center">
      <Step descricao={`1. Navegue para ${BASE_CONFIG.App.BaseUrl}`} />
      <Step
        descricao='2. Clique na aba "Transações"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-27/5ed1216e-dfb5-4cd2-bc54-f08b1a63f8e8/ascreenshot.jpeg?tl_px=80,0&br_px=826,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,58"
      />
      <Step
        descricao='3. Clique em "Filtrar"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-27/60476127-b679-43a1-b3b0-663ec9130fe6/ascreenshot.jpeg?tl_px=1106,0&br_px=1852,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,105"
      />
      <Tip descricao="Tip: Preencha os campos de acordo com sua necessidade. Como exemplo, será realizada a busca pela pessoa utilizando o CPF." />
      <Step
        descricao='4. Clique no campo "CPF/CNPJ" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-27/16924150-558d-40eb-8d2b-a4f0a538f487/ascreenshot.jpeg?tl_px=1156,132&br_px=1902,552&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='5. Clique em "Filtrar".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-27/b45cd2eb-a125-4ac0-8713-07db5df87ed9/ascreenshot.jpeg?tl_px=1173,509&br_px=1919,929&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=296,262"
      />
    </Box>
  </Instruction>
);
