import { responsiveFontSizes, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { PropsWithChildren } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { UserProvider } from '../../contexts/User.context';
import { muiTheme } from '../../shared/theme';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { AuthenticationProvider } from '../features/authentication/authenticationContext';

const AppProviders = ({ children }: PropsWithChildren) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <ThemeProvider theme={responsiveFontSizes(muiTheme)}>
        <ErrorBoundary>
          <BrowserRouter>
            <AuthenticationProvider>
              <UserProvider>{children}</UserProvider>
            </AuthenticationProvider>
          </BrowserRouter>
        </ErrorBoundary>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default AppProviders;
