import { Box } from '@mui/material';

import { BASE_CONFIG } from '../../shared/baseConfig';
import Instruction from '../components/Instruction.doc';
import Step from '../components/Step.doc';

export const AdicionarSaldoCartaoCredito = () => (
  <Instruction titulo="Adicionar saldo com cartão de crédito.">
    <Box display="flex" flexDirection="column" alignItems="center">
      <Step descricao={`1. Navegue para ${BASE_CONFIG.App.ClienteUrl}`} />
      <Step
        descricao='2. Clique em "Recarregar saldo&quot'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/d8b4297b-0f4d-4aeb-8691-6ad0b98f1b1d/ascreenshot.jpeg?tl_px=1013,0&br_px=1873,480&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,150"
      />
      <Step
        descricao="3. Clique em um dos valores pré-definidos."
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/27fb11d7-5abe-4114-ba6a-f74203bb7e43/ascreenshot.jpeg?tl_px=1039,0&br_px=1899,480&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,159"
      />
      <Step
        descricao="4. Caso queria informar um valor diferente, clique no campo e preencha-o."
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/747d2ca9-26e7-448c-b759-71c114d9b9ca/ascreenshot.jpeg?tl_px=960,39&br_px=1820,520&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Step
        descricao='5. Clique em "Próximo"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/433415cb-23bd-4bd8-afa5-4372ee9ffccc/ascreenshot.jpeg?tl_px=1060,448&br_px=1920,929&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=604,400"
      />
      <Step
        descricao="6. Clique em selecionar no card de Cartão de crédito."
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/ea493072-da63-444d-bfc5-37fbe56d94a0/ascreenshot.jpeg?tl_px=1060,41&br_px=1920,522&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=650,212"
      />
      <Step
        descricao="7. Preencha o formulário com as informações referentes ao seu cartão."
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/f16dcfc4-ce21-4844-8234-ff174e972331/ascreenshot.jpeg?tl_px=1060,0&br_px=1920,480&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=454,164"
      />
      <Step
        descricao="8. Clique em pagar para efetuar o pagamento."
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/8f362db8-ebf4-4a45-9326-18def2209d16/ascreenshot.jpeg?tl_px=1060,178&br_px=1920,659&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=545,212"
      />
      <Step
        descricao="9. Após finalizado, clique em fechar para retornar à tela inicial."
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/322c334b-e3a0-4e5e-8b66-8ed26e1b4099/ascreenshot.jpeg?tl_px=1060,448&br_px=1920,929&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=461,400"
      />
    </Box>
  </Instruction>
);
