import { Box, Button } from '@mui/material'; // Certifique-se de que está importando o Button corretamente
import {
  Colors,
  ModalEditSuccess
} from '@vabembarqueja/embarque-ja-shared-components';
import { useState } from 'react';

import UsuarioService from '../../services/Usuario.service';
import DrawerBloqueioUsuario from '../features/gestao-clientes/DrawerBloqueioUsuario';

import Loading from './Loading';

interface Props {
  disabled?: boolean;
  usuario: Usuario;
  onChangeStatus: () => void;
}

interface Usuario {
  id: string;
  nome: string;
  cpfCnpj: string;
  bloqueado: boolean;
}

export const BloquearUsuario = ({
  disabled = false,
  usuario,
  onChangeStatus
}: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
    setOpen(false);
    onChangeStatus();
  };

  const handleBlockUser = () => {
    if (!usuario.bloqueado) {
      setOpenModal(true);
    } else {
      onClick();
    }
  };

  const onClick = () => {
    setLoading(true);
    UsuarioService.alterarBloqueio(usuario.id, !usuario.bloqueado)
      .then(() => {
        setOpen(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  if (loading) {
    return <Loading fullscreen bgColor={Colors.white} show />;
  }

  return (
    <Box width="100%" display="flex" justifyContent="flex-end">
      {!disabled && (
        <Button
          sx={{
            textTransform: 'none',
            fontSize: 12
          }}
          color={usuario.bloqueado ? 'primary' : 'error'}
          variant="contained"
          onClick={handleBlockUser}
          disabled={disabled}
        >
          {usuario.bloqueado ? 'Desbloquear Usuário' : 'Bloquear Usuário'}
        </Button>
      )}
      {!usuario.bloqueado && (
        <DrawerBloqueioUsuario
          usuario={usuario}
          open={openModal}
          onClose={handleClose}
        />
      )}
      <ModalEditSuccess open={open} setOpen={setOpen} onClose={handleClose} />
    </Box>
  );
};
