import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { addDays, isAfter } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';

import { Agencia } from '../../../models/Agencia';
import {
  FILTRO_DEFAULT,
  FiltroComissoes,
  filtroComissoesFormModelSchema
} from '../../../models/FiltroComissoes.model';
import ComissaoService from '../../../services/Comissao.service';
import FileService from '../../../services/File.service';
import TotalBusService from '../../../services/TotalBus.service';
import AutocompleteAsync from '../../components/Form/AutocompleteAsync';
import DatePicker from '../../components/Form/DatePicker';
import { FormSwitch } from '../../components/Form/FormSwitch';
import { Input } from '../../components/Form/Input';

interface Props {
  onClose: (filtroComissoes: FiltroComissoes) => void;
  onChangeFilter: (filtroComissoes: FiltroComissoes) => void;
  filtroComissoes: FiltroComissoes;
  permitirFiltrarUsuarios?: boolean;
}

const FiltrarTransacoes = ({
  filtroComissoes,
  onClose,
  onChangeFilter
}: Props) => {
  const form = useForm<FiltroComissoes>({
    defaultValues: filtroComissoes ?? FILTRO_DEFAULT,
    resolver: yupResolver(filtroComissoesFormModelSchema)
  });

  const dataFim = form.watch('dataFinal') ?? (null as any);
  const dataInicio = form.watch('dataInicio') ?? (null as any);

  const limparCamposVazios = (filtros: FiltroComissoes) => {
    return Object.fromEntries(Object.entries(filtros).filter(([_, v]) => v));
  };

  const filtrarTransacoes = (filtros: FiltroComissoes) => {
    onClose(limparCamposVazios(filtros));
  };

  const limparFiltros = () => {
    onChangeFilter({});
    form.reset(FILTRO_DEFAULT);
  };

  const downloadArquivo = () => {
    const filtros = limparCamposVazios(form.getValues());

    ComissaoService.downloadTransacoes(filtros)
      .then((data) => {
        const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        FileService.downloadBlob(blob, 'download.csv');
      })
      .catch(console.error);
  };

  const nomeAgencia = form.watch('nomeAgencia') ?? '';

  const autocompleteAgencia = nomeAgencia ? { nomeAgencia } : null;

  return (
    <FormProvider {...form}>
      <Box display="flex" flexDirection="column" alignItems="space-between">
        <Grid container spacing={2} padding={3}>
          <Grid item xs={6}>
            <AutocompleteAsync<Agencia, FiltroComissoes>
              name="nomeAgencia"
              label="Nome da agência"
              placeholder="*Pesquise pelo nome da agência"
              control={form.control}
              value={autocompleteAgencia}
              load={TotalBusService.buscarAgencia}
              getOptionLabel={(option) => option.nomeAgencia}
              isOptionEqualToValue={(o, v) => o.nomeAgencia === v.nomeAgencia}
              onChange={(_e, value) => {
                form.setValue('nomeAgencia', value?.nomeAgencia);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              control={form.control}
              name="agenteMatricula"
              label="Matrícula TotalBus Agente"
              placeholder="Matrícula TotalBus"
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              name="dataInicio"
              control={form.control}
              maxDate={new Date()}
              label="Data de início"
              placeholder="Data de início"
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              name="dataFinal"
              label="Data final"
              minDate={dataInicio}
              maxDate={
                isAfter(new Date(), addDays(dataInicio, 30))
                  ? addDays(dataInicio, 30)
                  : new Date()
              }
              control={form.control}
              placeholder="Data final"
            />
          </Grid>
          <Grid item xs={12}>
            <FormSwitch
              control={form.control}
              name="primeiraCargaPF"
              label="Primeira Carga PF"
            />
          </Grid>
          <Grid item xs={12}>
            <FormSwitch
              control={form.control}
              name="primeiraCargaPJ"
              label="Primeira Carga PJ"
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          spacing={2}
          padding={3}
          alignContent="flex-end"
        >
          <Grid item xs={12}>
            <Box display={{ xs: 'none', sm: 'block' }}>
              <Button
                fullWidth
                variant="outlined"
                disabled={
                  !dataInicio || !dataFim || isAfter(dataInicio, dataFim)
                }
                onClick={downloadArquivo}
              >
                <Typography textTransform="none">Download</Typography>
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Button fullWidth variant="outlined" onClick={limparFiltros}>
              <Typography textTransform="none">Limpar filtros</Typography>
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              onClick={form.handleSubmit(filtrarTransacoes, console.log)}
            >
              <Typography textTransform="none">Filtrar</Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
};

export default FiltrarTransacoes;
