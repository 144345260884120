import { Box } from '@mui/material';

import { BASE_CONFIG } from '../../shared/baseConfig';
import Alert from '../components/Alert.doc';
import Instruction from '../components/Instruction.doc';
import Step from '../components/Step.doc';
import Tip from '../components/Tip.doc';

export const PrimeiroAcesso = () => (
  <Instruction titulo="Realizar o primeiro acesso na plataforma EmbarqueJá">
    <Box display="flex" flexDirection="column" alignItems="center">
      <Step descricao={`1. Navegue para ${BASE_CONFIG.App.ClienteUrl}`} />
      <Alert descricao="Alert: Os dados necessário para a efetuação do primeiro acesso podem ser encontrados no e-mail fornecido durante o cadastro na plataforma. " />
      <Tip descricao="Tip: Para o campo de CPF/CNPJ deve-se informar apenas números, não adicione ( . ) ou ( - ) durante o preenchimento." />
      <Step
        descricao='2. Clique no campo "CPF/CNPJ" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/5fb906c5-daba-479a-b915-37304ed8b45d/ascreenshot.jpeg?tl_px=583,121&br_px=1443,602&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Step
        descricao='3. Clique no campo "Senha" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/31220740-9385-413c-97b0-05793cb4cf3b/ascreenshot.jpeg?tl_px=477,229&br_px=1337,710&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Step
        descricao='4. Clique em "Entrar".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/1beff0e1-54ce-4e72-af41-f839939386dd/ascreenshot.jpeg?tl_px=521,333&br_px=1381,814&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Tip descricao="Tip: Durante o primeiro acesso você será direcionado para alterar sua senha temporária." />
      <Step
        descricao='5. Clique no campo "Senha*" e preencha-o com sua senha temporária.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/685982a3-0e25-49b9-a485-5f71b2c589e5/ascreenshot.jpeg?tl_px=529,200&br_px=1389,681&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Step
        descricao='6. Clique no campo "Nova Senha*" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/48a37920-2176-4643-a646-1e888f24cb39/ascreenshot.jpeg?tl_px=469,291&br_px=1329,772&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Step
        descricao='7. Clique no campo "Confirmar Nova Senha*" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/c0dd39bf-5f30-4f74-82a9-ae33e05db398/ascreenshot.jpeg?tl_px=492,370&br_px=1352,851&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Step
        descricao='8. Clique em "Continuar"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/fe5fc0db-b56f-47ef-82c2-208600ad5cbe/ascreenshot.jpeg?tl_px=591,440&br_px=1451,921&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
    </Box>
  </Instruction>
);
