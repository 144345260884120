import { Box, Typography } from '@mui/material';

import { Cliente } from '../../../models/Cliente.model';
import { Colors } from '../../../shared/colors';
interface Props {
  cliente: Cliente;
}
export const BloqueioCliente = ({ cliente }: Props) => (
  <>
    {cliente.saldoBloqueado && (
      <Box
        display="flex"
        justifyContent="center"
        color={Colors.white}
        bgcolor={Colors.error.dark}
        sx={{
          borderBottomLeftRadius: 7,
          borderBottomRightRadius: 7
        }}
      >
        <Typography fontWeight="bold" fontSize={14}>
          Bloqueado
        </Typography>
      </Box>
    )}
  </>
);
