import { mdiFilterVariant, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Skeleton,
  Typography
} from '@mui/material';
import { useState } from 'react';

import { FiltroUsuario } from '../../models/FiltroUsuario.model';
import { Usuario, USUARIO_DEFAULT } from '../../models/Usuario.model';
import UsuarioService from '../../services/Usuario.service';
import { Colors } from '../../shared/colors';
import useInfiniteScroll from '../../shared/hooks/infiniteScroll';
import CardUsuario from '../components/CardUsuario';
import DrawerCadastrarUsuario from '../features/cadastrar-usuario-admin/DrawerCadastrarUsuario';
import DrawerFiltrarUsuario from '../features/filtrar-usuarios/DrawerFiltrarUsuario';

const GestaoUsuarios = () => {
  const [openDrawerCadastroUsuario, setOpenDrawerCadastroUsuario] =
    useState(false);
  const [openDrawerFiltroUsuario, setOpenDrawerFiltroUsuario] = useState(false);
  const [filtroUsuarios, setFiltroUsuarios] = useState<FiltroUsuario>();
  const [usuarioDefault, setUsuarioDefault] =
    useState<Usuario>(USUARIO_DEFAULT);

  const {
    refresh,
    hasNext,
    loading,
    lastElementRef,
    items: contasAssociadas
  } = useInfiniteScroll<Usuario, FiltroUsuario>({
    load: UsuarioService.listar,
    search: filtroUsuarios
  });

  const closeDrawerFiltro = (FiltroUsuario: FiltroUsuario) => {
    setOpenDrawerFiltroUsuario(false);
    setFiltroUsuarios(FiltroUsuario);
  };

  function editarUsuario(dataUser: Usuario) {
    setUsuarioDefault(dataUser);
    setOpenDrawerCadastroUsuario(true);
  }

  function onCloseDrawer() {
    setUsuarioDefault(USUARIO_DEFAULT);
    setOpenDrawerCadastroUsuario(false);
    refresh();
  }

  const carregandoItens = (loading && hasNext) || loading;

  return (
    <>
      <Grid container spacing={2} paddingY={1.5}>
        <Grid
          item
          container
          xs={12}
          spacing={2}
          alignItems="center"
          display="flex"
        >
          <Grid item xs={8} sm={6}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="h5"
                color={Colors.secondary}
                fontWeight={700}
              >
                Usuários do sistema
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
            sm={6}
            gap={2}
            container
            alignItems="center"
            justifyContent="flex-end"
          >
            <Box display={{ xs: 'none', sm: 'block' }}>
              <Button
                fullWidth
                variant="outlined"
                sx={{ height: 35 }}
                onClick={() => setOpenDrawerCadastroUsuario(true)}
              >
                <Typography textTransform="none">Cadastrar usuário</Typography>
              </Button>
            </Box>
            <Box
              display="flex"
              onClick={() => setOpenDrawerFiltroUsuario(true)}
              sx={{ cursor: 'pointer' }}
              gap={1}
            >
              <Typography
                fontWeight="bold"
                color={Colors.primary.main}
                display={{ xs: 'none', sm: 'block' }}
              >
                Filtrar
              </Typography>
              <Icon
                path={mdiFilterVariant}
                size={1}
                color={Colors.primary.main}
              />
            </Box>
            <Box
              display={{ sm: 'none' }}
              onClick={() => setOpenDrawerCadastroUsuario(true)}
            >
              <IconButton color="secondary">
                <Icon path={mdiPlus} size={1} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {contasAssociadas.map((contaUsuario, index, arr) => {
            const lastElement = arr.length === index + 1;
            const ref = lastElement && hasNext ? lastElementRef : null;
            return (
              <div ref={ref} key={contaUsuario.id}>
                <CardUsuario
                  usuario={contaUsuario}
                  onEditarUsuario={editarUsuario}
                />
              </div>
            );
          })}
        </Grid>

        {carregandoItens && (
          <Grid container gap={2} ml={2}>
            {Array(5)
              .fill(1)
              .map((_item, index) => index)
              .map((value) => (
                <Skeleton
                  key={value}
                  variant="rounded"
                  width="100%"
                  height={70}
                />
              ))}
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography
            mt={3}
            variant="body1"
            fontWeight={500}
            color={Colors.gray1}
            textAlign="center"
          >
            {!hasNext && !loading && 'Não há dados a serem exibidos'}
          </Typography>
        </Grid>
      </Grid>

      <DrawerFiltrarUsuario
        open={openDrawerFiltroUsuario}
        onClose={closeDrawerFiltro}
      />
      <DrawerCadastrarUsuario
        onClose={() => onCloseDrawer()}
        open={openDrawerCadastroUsuario}
        userDefault={usuarioDefault}
      />
    </>
  );
};

export default GestaoUsuarios;
