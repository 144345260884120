import { addDays } from 'date-fns';

import { APIPaginatedRequest } from '../models/APIPaginatedRequest';
import { APIPaginatedResponse } from '../models/APIPaginatedResponse';
import { APIResponse } from '../models/ApiResponse.model';
import { Comissao } from '../models/Comissao.model';
import { FiltroComissoes } from '../models/FiltroComissoes.model';

import Service from './Service';

class ComissaoService extends Service {
  static async listar(
    data: APIPaginatedRequest<FiltroComissoes>,
    signal?: AbortSignal
  ): Promise<APIPaginatedResponse<Comissao>> {
    if ('dataFinal' in data.search && data.search.dataFinal)
      data.search = {
        ...data.search,
        dataFinal: addDays(data.search.dataFinal, 1)
      };
    return this.Http.post<APIResponse<APIPaginatedResponse<Comissao>>>(
      '/administrativo/comissoes/transacoes',
      data,
      { signal }
    )
      .then(this.getData)
      .then((resp) => resp.data);
  }

  static async downloadTransacoes(data: FiltroComissoes, signal?: AbortSignal) {
    if ('dataFinal' in data && data.dataFinal)
      data.dataFinal = addDays(data.dataFinal, 1);

    return this.Http.post<string>(
      '/administrativo/comissoes/transacoes/download',
      data,
      { signal }
    ).then(this.getData);
  }
}

export default ComissaoService;
