import { Box } from '@mui/material';

import { BASE_CONFIG } from '../../shared/baseConfig';
import Instruction from '../components/Instruction.doc';
import Step from '../components/Step.doc';

export const AdicionarSaldoDebito = () => (
  <Instruction titulo="Adicionar saldo com cartão de débito.">
    <Box display="flex" flexDirection="column" alignItems="center">
      <Step descricao={`1. Navegue para ${BASE_CONFIG.App.ClienteUrl}`} />
      <Step
        descricao='2. Clique em "Recarregar saldo"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/5f5cdd86-6c3e-4686-a9ce-883807a1a47b/File.jpeg?tl_px=1013,0&br_px=1873,480&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,150"
      />
      <Step
        descricao="3. Clique em um dos valores pré-definidos."
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/972f23a8-29e0-45a2-9120-aa99fa4a2929/File.jpeg?tl_px=1039,0&br_px=1899,480&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,159"
      />
      <Step
        descricao="4. Caso queria informar um valor diferente, clique no campo e preencha-o."
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/1cb30a09-b994-4b77-95c1-c048c17f5332/File.jpeg?tl_px=960,39&br_px=1820,520&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Step
        descricao='5. Clique em "Próximo"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/d1573fd5-f218-4951-82cb-0d98943c0c2b/File.jpeg?tl_px=1060,448&br_px=1920,929&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=604,400"
      />
      <Step
        descricao="6. Clique em selecionar no card de Cartão de débito."
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/fda13461-b619-42fb-a158-c52a4921b19e/ascreenshot.jpeg?tl_px=963,205&br_px=1823,686&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Step
        descricao="7. Preencha o formulário com as informações referentes ao seu cartão."
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/2e24c154-554c-473b-935b-e4ae8addb4b8/ascreenshot.jpeg?tl_px=1029,0&br_px=1889,480&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,158"
      />
      <Step
        descricao="8. Clique em pagar para efetuar o pagamento."
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/447eed99-2615-43c6-af04-6f6a5a20d098/File.jpeg?tl_px=1060,178&br_px=1920,659&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=545,212"
      />
      <Step
        descricao="9. Após finalizado, clique em fechar para retornar à tela inicial."
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/3346c899-f7c3-4981-85e7-18bd33c36fff/File.jpeg?tl_px=1060,448&br_px=1920,929&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=461,400"
      />
    </Box>
  </Instruction>
);
