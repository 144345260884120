class FileService {
  static async downloadBlob(
    blob: Blob,
    fileName: string,
    parent?: HTMLElement | null
  ) {
    if (!blob) return;

    // Create blob link to download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';

    link.setAttribute('download', fileName);
    console.log({ parent });
    // Append to html link element page
    parent ? parent.appendChild(link) : document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    URL.revokeObjectURL(url);
    link.parentNode && link.parentNode.removeChild(link);
  }
}

export default FileService;
