import { useState } from 'react';

import {
  FiltroAssociarCartao,
  FILTRO_ASSOCIAR_CARTAO_DEFAULT
} from '../../../models/FiltroAssociarCartao';
import Drawer from '../../components/Drawer';

import FiltrarTransacoes from './FiltrarAssociarCartoes';

interface Props {
  open: boolean;
  onClose: (data: FiltroAssociarCartao) => void;
  permitirFiltrarUsuarios?: boolean;
}

const DrawerFiltrarAssociarCartoes = ({
  open,
  onClose,
  permitirFiltrarUsuarios = false
}: Props) => {
  const [filtroTransacao, setFiltroTransacao] = useState<FiltroAssociarCartao>(
    FILTRO_ASSOCIAR_CARTAO_DEFAULT
  );

  const onCloseDrawer = (filtroTransacao: FiltroAssociarCartao) => {
    setFiltroTransacao(filtroTransacao);
    onClose(filtroTransacao);
  };

  return (
    <Drawer
      title="Filtrar"
      open={open}
      onClose={() => {
        onCloseDrawer(filtroTransacao);
      }}
    >
      <FiltrarTransacoes
        filtroAssociarCartao={filtroTransacao}
        onChangeFilter={setFiltroTransacao}
        onClose={onCloseDrawer}
        permitirFiltrarUsuarios={permitirFiltrarUsuarios}
      />
    </Drawer>
  );
};

export default DrawerFiltrarAssociarCartoes;
