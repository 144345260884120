import { Box } from '@mui/material';

import { BASE_CONFIG } from '../../shared/baseConfig';
import Alert from '../components/Alert.doc';
import Instruction from '../components/Instruction.doc';
import Step from '../components/Step.doc';
import Tip from '../components/Tip.doc';

export const CadastrarPJ = () => (
  <Instruction titulo="Cadastro Usuário Pessoa Jurídica no EmbarqueJá">
    <Box display="flex" flexDirection="column" alignItems="center">
      <Step descricao={`1. Navegue para ${BASE_CONFIG.App.BaseUrl}`} />
      <Step
        descricao='2. Click "Gestão de clientes"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/80f7c86b-2c95-4f75-b9b4-2b5f4ec5d16d/ascreenshot.jpeg?tl_px=509,0&br_px=1255,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,70"
      />
      <Step
        descricao='3. Click "Cadastrar usuário"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/0f402330-9fd4-4f21-898b-b13d531c91ef/ascreenshot.jpeg?tl_px=985,0&br_px=1731,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,101"
      />
      <Step
        descricao='4. Clique "Selecionar" no card Pessoa Jurídica.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/96035bfb-2c03-40e6-8838-6a8b846dc002/ascreenshot.jpeg?tl_px=755,206&br_px=1501,626&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='5. Clique no campo "*CNPJ" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/98fdf7a0-3624-455c-94fb-fb1f4507de15/ascreenshot.jpeg?tl_px=245,35&br_px=991,455&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='6. Clique no campo "*Nome fantasia" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/0b85b913-5a87-4a5e-8778-e6f9342baee7/ascreenshot.jpeg?tl_px=499,53&br_px=1245,473&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='7. Clique no campo "*Razão social" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/91a222eb-917e-4c79-ac01-0c4249dbba07/ascreenshot.jpeg?tl_px=851,25&br_px=1597,445&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='8. Clique no campo "*Inscrição estadual" e preencha-o ou marque-o como isento se for o caso.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/97047555-ea52-4406-a8da-87f087b153da/ascreenshot.jpeg?tl_px=335,145&br_px=1081,565&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='9. Clique no campo "*E-mail" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/ec6337e8-a87e-4b56-98c1-b6f2a212e253/ascreenshot.jpeg?tl_px=465,145&br_px=1211,565&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Alert descricao="Alert: Atenção, este e-mail será responsável por toda a comunicação referente ao seu acesso a plataforma (primeira senha, recuperação de senha, etc. )" />
      <Step
        descricao='10. Clique no campo "*Celular" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/ee553829-aa82-40ad-85e0-606a5423a523/ascreenshot.jpeg?tl_px=1021,160&br_px=1767,580&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Alert descricao="Alert: Atenção, cadastre um celular ativo que receba SMS, pois será usado para envio de token para utilização de certas funcionalidades no EmbarqueJá." />

      <Step
        descricao='11. Clique no campo "Número de funcionários" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/660a2409-532e-46d8-a543-43957f0ee253/ascreenshot.jpeg?tl_px=127,246&br_px=873,666&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='12. Clique no campo "*Nome" na seção "Dados do representante".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/5e2ab586-80c4-4003-b25e-aa18e4d3b358/ascreenshot.jpeg?tl_px=346,260&br_px=1092,680&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='3. Clique no campo "CPF" na seção "Dados do representante" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/4708a431-88d0-4df6-9f9a-063f5a09e9ae/ascreenshot.jpeg?tl_px=459,275&br_px=1205,695&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='14. Clique no campo "*RG" na seção "Dados do representante" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/7d695b10-5d2c-4560-adc2-4135a4e86519/ascreenshot.jpeg?tl_px=859,285&br_px=1605,705&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='15. Clique no campo "CEP" na seção "Endereço".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/e39a96b7-2603-41be-95d7-3e7b53c74782/ascreenshot.jpeg?tl_px=226,415&br_px=972,835&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Tip descricao='Tip: Caso o "CEP" seja encontrado, os campo "Estado", "Cidade", "Bairro" e "Rua" serão preenchidos automaticamente.' />
      <Step
        descricao='16. Caso preenchido automaticamente, verifique o campo "Estado".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/1235525e-628b-4458-b4f1-a37f5d36ca0b/ascreenshot.jpeg?tl_px=613,416&br_px=1359,836&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='17. Caso preenchido automaticamente, verifique o campo "Cidade".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/c524cf24-f241-4d16-9cb2-f5f9bc8c38ec/ascreenshot.jpeg?tl_px=903,404&br_px=1649,824&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='18. Caso preenchido automaticamente, verifique o campo "Bairro".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/1fd595cb-682a-4621-8592-e02f73890dd6/ascreenshot.jpeg?tl_px=269,509&br_px=1015,929&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,163"
      />
      <Step
        descricao='19. Caso preenchido automaticamente, verifique o campo "Rua".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/35377f92-c4f0-4ef3-99b0-fc89e76eedfa/ascreenshot.jpeg?tl_px=532,509&br_px=1278,929&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,147"
      />
      <Step
        descricao='20. Preencha o campo "Número".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/5bdef811-85b4-4cd9-b1e1-149f50548871/ascreenshot.jpeg?tl_px=862,508&br_px=1608,928&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='21. Clique no campo "Complemento" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/5e6b0ba3-fdda-4f63-a1f6-ab66ccc3e277/ascreenshot.jpeg?tl_px=286,509&br_px=1032,929&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,222"
      />

      <Step
        descricao='22. Click "Realizar cadastro"</p>'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/f73bd49a-38cc-4639-b004-b3119e08377a/ascreenshot.jpeg?tl_px=1080,509&br_px=1826,929&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,263"
      />
      <Step
        descricao='23. Click "Concluir"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/a4ec1e15-9bda-4a88-8842-44989111ab03/ascreenshot.jpeg?tl_px=725,407&br_px=1471,827&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Tip descricao='Tip: Ao final do cadastro, um e-mail será enviado com os dados de acesso a serem utilizados para acessar a plataforma. Esse é o e-mail que deverá ser utilizado para realizar o login, os passos estão disponibilizados na seção "Acessando a plataforma Embarque Já.' />
    </Box>
  </Instruction>
);
