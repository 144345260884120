import * as yup from 'yup';

import { validarCNPJ } from '../shared/functions/validarCNPJ';

export interface FiltroPosPago {
  nome?: string;
  cnpj?: string;
  mes?: number;
  ano?: number;
  idClienteTotalBus?: number | '';
}

export const FILTRO_POS_PAGO_DEFAULT: FiltroPosPago = {
  nome: '',
  cnpj: '',
  idClienteTotalBus: '',
  mes: new Date().getMonth() + 1,
  ano: new Date().getFullYear()
};

export const FiltroPosPagoFormModelSchema: yup.ObjectSchema<FiltroPosPago> = yup
  .object()
  .shape({
    nome: yup.string().optional(),
    idClienteTotalBus: yup.mixed<number | ''>(),
    cnpj: yup
      .string()
      .optional()
      .test('Teste documento', 'Documento inválido', (value) => {
        if (!value) return true;
        return validarCNPJ(value);
      })
      .transform((val) => val.replace(/[^\d]+/g, ''))
      .typeError('Campo obrigatório'),
    mes: yup.number().min(1).max(12).optional(),
    ano: yup.number().min(2000).optional()
  });
