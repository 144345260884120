import { Box } from '@mui/material';

import { BASE_CONFIG } from '../../shared/baseConfig';
import Instruction from '../components/Instruction.doc';
import Step from '../components/Step.doc';
import Tip from '../components/Tip.doc';

export const FiltrarComissoes = () => (
  <Instruction titulo="Como filtrar comissões no EmbarqueJá">
    <Box display="flex" flexDirection="column" alignItems="center">
      <Step descricao={`1. Navegue para ${BASE_CONFIG.App.BaseUrl}`} />
      <Step
        descricao='2. Clique na aba "Gestão de comissões"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-05/3d083913-71d8-4f61-9cb5-079de52e0166/ascreenshot.jpeg?tl_px=682,0&br_px=1428,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,69"
      />
      <Step
        descricao='3. Clique em "Filtrar".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-05/6a6d2fe2-22fe-4b4b-aad2-ae7cdfaa6dd1/ascreenshot.jpeg?tl_px=1128,0&br_px=1874,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,105"
      />
      <Tip descricao="Tip: Preencha os campos de filtro conforme necessário." />
      <Step
        descricao='4. Clique no campo "*Pesquise pelo nome da agência" e preencha-o caso necessário.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-05/0bfb88ea-67e7-49c7-b298-21ab0d8a1de2/ascreenshot.jpeg?tl_px=1038,0&br_px=1784,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,103"
      />
      <Step
        descricao="5. Escolha a agência na lista de seleção."
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-05/db50b3ab-9756-4268-b5f2-a49e225d856c/ascreenshot.jpeg?tl_px=968,309&br_px=1714,729&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='6. Clique em "Filtrar".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-05/92a1bfb3-799b-4036-96de-c91317a9632e/ascreenshot.jpeg?tl_px=1173,509&br_px=1919,929&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=290,262"
      />
    </Box>
  </Instruction>
);
