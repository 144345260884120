import { mdiAccount, mdiMenu } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Box,
  Checkbox,
  Drawer,
  Popover,
  Switch,
  Typography
} from '@mui/material';
import MaterialAppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import Logo from '../../../assets/logo.png';
import { useUserContext } from '../../../contexts/User.context';
import { ConfiguracaoAdm } from '../../../models/ConfiguracaoAdm.model';
import { Routes } from '../../../routes/index.routes';
import ConfiguracaoAdmService from '../../../services/ConfiguracaoAdm.service';
import { useAuthenticationContext } from '../../features/authentication/authenticationContext';
import Link from '../Link/Link';

function NavLinks() {
  const { userInfo } = useUserContext();

  const admin = userInfo?.extension_EmbarquejaAdmClaim === 'Administrativo';

  return (
    <>
      <Link to={Routes.Home}>Transações</Link>
      <Link to={Routes.associacaoCartoes.Principal}>Associação de cartões</Link>
      <Link to={Routes.cargaDistancia.Principal}>Carga a distância</Link>
      <Link to={Routes.gestaoClientes.Principal}>Gestão de clientes</Link>

      {admin && (
        <Link to={Routes.gestaoComissoes.Principal}>Gestão de comissões</Link>
      )}
      {admin && (
        <Link to={Routes.consumoPosPago.Principal}>Consumo pós-pago </Link>
      )}
      {admin && (
        <Link to={Routes.gestaoUsuarios.Principal}>Gestão de usuários</Link>
      )}
    </>
  );
}

function AppBar() {
  const { logout } = useAuthenticationContext();
  const { usuario, userInfo } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [threed, setThreed] = useState<ConfiguracaoAdm>({
    ativo3DS2: false,
    enviar3DS2: false
  });

  const openPopover = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const admin = userInfo?.extension_EmbarquejaAdmClaim === 'Administrativo';

  const irParaHome = () => navigate(Routes.Home);
  const fecharPopOver = () => setAnchorEl(null);

  const abrirPopOver = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const abrirAdmPopOver = (event: React.MouseEvent<HTMLDivElement>) => {
    if (admin) setAnchorEl(event.currentTarget);
  };

  const onCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;

    ConfiguracaoAdmService.configurar3DS2({
      ativo3DS2: isChecked ? threed.enviar3DS2 : false,
      enviar3DS2: isChecked
    })
      .then(obterThreedAtual)
      .catch(console.error);
  };

  const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;

    ConfiguracaoAdmService.configurar3DS2({
      ativo3DS2: isChecked,
      enviar3DS2: threed.enviar3DS2
    })
      .then(obterThreedAtual)
      .catch(console.error);
  };

  const obterThreedAtual = () => {
    ConfiguracaoAdmService.obter3DS2()
      .then((data) => setThreed(data))
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (admin) obterThreedAtual();
  }, [admin]);

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  return (
    <>
      <MaterialAppBar position="static" sx={{ zIndex: { xs: 1201 } }}>
        <Toolbar disableGutters sx={{ height: { xs: 60, sm: 80 } }}>
          <Container maxWidth="lg">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flex={1}
            >
              <Box
                gap={2}
                display={{ xs: 'block', md: 'none' }}
                onClick={() => setOpen((prev) => !prev)}
              >
                <Icon path={mdiMenu} size={1.5} />
              </Box>

              <Box onClick={irParaHome}>
                <img src={Logo} alt="logo" />
              </Box>

              {userInfo && (
                <>
                  <Box
                    display={{ xs: 'none', md: 'flex' }}
                    gap={2}
                    alignItems="center"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      sx={{ cursor: 'pointer' }}
                      onClick={abrirAdmPopOver}
                    >
                      <Typography fontWeight="bold">{userInfo.nome}</Typography>
                    </Box>

                    <Box
                      onClick={() => void logout()}
                      sx={{ cursor: 'pointer' }}
                      ml={2}
                    >
                      Sair
                    </Box>
                  </Box>

                  <Box
                    gap={2}
                    onClick={abrirPopOver}
                    sx={{ cursor: 'pointer' }}
                    display={{ xs: 'block', sm: 'none' }}
                  >
                    <Icon path={mdiAccount} size={1.5} />
                  </Box>
                  <Popover
                    id={id}
                    open={openPopover}
                    anchorEl={anchorEl}
                    onClose={fecharPopOver}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                  >
                    <Box p={1}>
                      {admin && (
                        <Box display="flex" alignItems="center">
                          <Checkbox
                            value={threed.enviar3DS2}
                            checked={threed.enviar3DS2}
                            onChange={onCheckChange}
                          />
                          <Typography>ExecuteThreeD</Typography>
                          <Switch
                            checked={threed.ativo3DS2}
                            disabled={!threed.enviar3DS2}
                            value={threed.ativo3DS2}
                            onChange={onSwitchChange}
                          />
                        </Box>
                      )}
                      <NavLink
                        to={Routes.manual.Principal}
                        style={{ textDecoration: 'none' }}
                      >
                        <Typography pl={1.5}>Manual de usuário</Typography>
                      </NavLink>
                      <Box
                        sx={{ cursor: 'pointer' }}
                        onClick={() => void logout()}
                        display={{ xs: 'block', sm: 'none' }}
                      >
                        <Typography pl={1.5} mt={1}>
                          Sair
                        </Typography>
                      </Box>
                    </Box>
                  </Popover>
                </>
              )}
            </Box>
          </Container>
        </Toolbar>
        {usuario?.ativo && (
          <Toolbar
            disableGutters
            sx={{
              bgcolor: 'primary.dark',
              display: { xs: 'none', md: 'flex' },
              alignItems: 'flex-end',
              minHeight: { xs: 56 }
            }}
          >
            <Box
              gap={3}
              flex={1}
              display="flex"
              alignItems="flex-end"
              justifyContent="center"
            >
              <NavLinks />
            </Box>
          </Toolbar>
        )}
      </MaterialAppBar>
      <Drawer
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{ sx: { width: '75%', pt: { xs: '60px', sm: '90px' } } }}
      >
        <Box
          p={2}
          gap={3}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <NavLinks />
        </Box>
      </Drawer>
    </>
  );
}
export default AppBar;
