import { Routes as ReactRouterRoutes, Route } from 'react-router-dom';

import CadastroPFController from '../app/features/cadastrar-usuario-web/CadastroPFController';
import CadastroPJController from '../app/features/cadastrar-usuario-web/CadastroPJController';
import Cadastro from '../app/pages/Cadastro';

export const CadastroRoutes = () => {
  return (
    <ReactRouterRoutes>
      <Route path="/" element={<Cadastro />} />
      <Route path="/pf" element={<CadastroPFController />} />
      <Route path="/pf/:id" element={<CadastroPFController />} />
      <Route path="/pj" element={<CadastroPJController />} />
      <Route path="/pj/:id" element={<CadastroPJController />} />
    </ReactRouterRoutes>
  );
};
