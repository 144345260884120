import * as yup from 'yup';

import { validarCPF } from '../shared/functions/validarCpf';
export interface FiltroAssociarCartao {
  nome?: string;
  cpf?: string;
  numeroCartao?: number | '';
}

export const FILTRO_ASSOCIAR_CARTAO_DEFAULT: FiltroAssociarCartao = {
  nome: '',
  cpf: '',
  numeroCartao: ''
};

const REQUIRED = 'Campo obrigatório';

export const FiltroAssociarCartaoFormModelSchema: yup.ObjectSchema<FiltroAssociarCartao> =
  yup.object().shape({
    nome: yup.string().optional().typeError(REQUIRED),
    cpf: yup
      .string()
      .optional()
      .test('Teste documento', 'Documento inválido', (value) => {
        if (!value) return true;
        return validarCPF(value);
      })
      .transform((val) => val.replace(/[^\d]+/g, ''))
      .typeError(REQUIRED),
    numeroCartao: yup.mixed<number | ''>()
  });
