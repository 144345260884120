import { Box } from '@mui/material';

import { BASE_CONFIG } from '../../shared/baseConfig';
import Instruction from '../components/Instruction.doc';
import Step from '../components/Step.doc';
import Tip from '../components/Tip.doc';

export const SolicitarMigracaoPosPago = () => (
  <Instruction titulo="Como solicitar a migração para o plano pós-pago EmbarqueJá.">
    <Box display="flex" flexDirection="column" alignItems="center">
      <Step descricao={`1. Navegue para ${BASE_CONFIG.App.ClienteUrl}`} />
      <Step
        descricao='2. Clique na aba "Consumo pós-pago".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/2dbfef69-d1d2-44d9-a3c8-1837dd0efe54/ascreenshot.jpeg?tl_px=714,0&br_px=1574,480&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,95"
      />
      <Step
        descricao='3. Clique em "Solicitar avaliação"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/3e3f5c53-73ad-42df-9c3e-0252db6be9b5/ascreenshot.jpeg?tl_px=1007,144&br_px=1867,625&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Tip descricao="Tip: Após solicitar, você será redirecionado para a nova tela informando que sua solicitação está em análise." />
      <Step
        descricao="4. Aguarde a análise da sua solicitação."
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/77ca5f8d-b388-481d-ba79-26915fdd60c4/ascreenshot.jpeg?tl_px=229,0&br_px=1606,769&force_format=png&width=1120.0&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=524,274"
      />
    </Box>
  </Instruction>
);
