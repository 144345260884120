import { APIPaginatedRequest } from '../models/APIPaginatedRequest';
import { APIPaginatedResponse } from '../models/APIPaginatedResponse';
import { APIResponse } from '../models/ApiResponse.model';
import { ConsumoPosPago } from '../models/ConsumoPosPago.model';
import { FiltroPosPago } from '../models/FiltroPosPago.model';

import Service from './Service';

class ConsumoPosPagoService extends Service {
  static async listarClientes(
    data: APIPaginatedRequest<FiltroPosPago>,
    signal?: AbortSignal
  ) {
    return this.Http.post<APIResponse<APIPaginatedResponse<ConsumoPosPago>>>(
      'administrativo/pos-pago/clientes/listar-cabecalhos',
      data,
      { signal }
    )
      .then(this.getData)
      .then((resp) => resp.data);
  }

  static async getDetalhesConsumo(
    usuarioPjId: string,
    mes: number,
    ano: number
  ) {
    return this.Http.post('/administrativo/pos-pago/clientes/listar-detalhes', {
      usuarioPjId,
      mes,
      ano
    })
      .then(this.getData)
      .then((resp) => resp.data);
  }
}

export default ConsumoPosPagoService;
