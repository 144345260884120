import { Box } from '@mui/material';

import { BASE_CONFIG } from '../../shared/baseConfig';
import Instruction from '../components/Instruction.doc';
import Step from '../components/Step.doc';
import Tip from '../components/Tip.doc';

export const FiltrarTransacoesExecutadas = () => (
  <Instruction titulo="Filtrar transações executadas no EmbarqueJá">
    <Box display="flex" flexDirection="column" alignItems="center">
      <Step descricao={`1. Navegue para ${BASE_CONFIG.App.BaseUrl}`} />
      <Tip descricao="Tip: Para filtrar as transações, não é necessário que todos os campos estejam preenchidos." />
      <Step
        descricao='2. Clique em "Filtrar"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/faba0ad8-31a2-4bad-9af3-87cff250eae2/ascreenshot.jpeg?tl_px=1121,0&br_px=1867,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,109"
      />
      <Step
        descricao='3. Clique em "Selecione o tipo de transação" e preencha com o tipo desejado.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/e84b08d3-5a98-47e6-a9df-7e8ef6403990/ascreenshot.jpeg?tl_px=1070,0&br_px=1816,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,133"
      />
      <Step
        descricao='4. Clique em "Nome do cliente" e preencha-o caso necessário.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/3c127cbe-0d51-4f2a-b509-865463313a3c/ascreenshot.jpeg?tl_px=1023,24&br_px=1769,444&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='5. Clique em "CPF/CNPJ" e preencha-o caso necessário.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/7d5a89bf-fd62-47bd-9f6a-27e9189859b8/ascreenshot.jpeg?tl_px=1052,130&br_px=1798,550&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='6. Clique em "Número de autorização" e preencha-o caso necessário.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/d2d3048b-9545-4427-aee4-96da32516081/ascreenshot.jpeg?tl_px=1033,241&br_px=1779,661&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='7. Clique em "*Pesquise pelo nome da agência" e preencha-o caso necessário.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/123a049c-8d25-4a56-a6ad-8a88dbdf1db1/ascreenshot.jpeg?tl_px=1021,317&br_px=1767,737&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='8. Clique em "Matrícula TotalBus" e preencha-o caso necessário.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/fc81a6f1-ba94-4770-bf82-34643f86455c/ascreenshot.jpeg?tl_px=1173,321&br_px=1919,741&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=375,139"
      />
      <Step
        descricao='9. Clique em "Data de início" e preencha-o caso necessário.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/c2a0e231-5393-41e5-97da-6455b7cf4d1b/ascreenshot.jpeg?tl_px=1016,420&br_px=1762,840&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='10. Clique em "Data final" e preencha-o caso necessário.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/f5b24ca0-ae44-4de3-a180-bdfbd211e447/ascreenshot.jpeg?tl_px=1173,417&br_px=1919,837&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=351,139"
      />
      <Step
        descricao='11. Clique em "Filtrar".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/ff023a1a-432f-4325-8635-dd95edf0be59/ascreenshot.jpeg?tl_px=1150,509&br_px=1896,929&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,260"
      />
    </Box>
  </Instruction>
);
