import { mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { PatternFormat } from 'react-number-format';

import { Usuario } from '../../models/Usuario.model';
import UsuarioService from '../../services/Usuario.service';
import { Colors } from '../../shared/colors';

export interface CardUsuarioProps {
  onEditarUsuario: (usuario: Usuario) => void;
  usuario: Usuario;
}

const CardUsuario = ({ onEditarUsuario, usuario }: CardUsuarioProps) => {
  const [usuarioAtivo, setUsuarioAtivo] = useState(usuario.ativo);
  const [loading, setLoading] = useState(false);

  async function changeUserStatus() {
    const prevValue = usuarioAtivo;
    setLoading(true);
    UsuarioService.alterarStatus({
      id: usuario.id ?? '',
      ativo: !usuarioAtivo
    })
      .then(() => setUsuarioAtivo(!prevValue))
      .catch(() => setUsuarioAtivo(prevValue))
      .finally(() => setLoading(false));
  }

  function onClickEdit() {
    const user = { ...usuario, ativo: usuarioAtivo };
    onEditarUsuario(user);
  }

  useEffect(() => {
    setUsuarioAtivo(usuario.ativo ?? true);
  }, [usuario.ativo]);

  return (
    <Grid
      container
      border={1}
      paddingX={2}
      paddingY={1.5}
      marginBottom={2}
      borderRadius={1}
      alignItems="center"
      justifyContent="space-between"
      sx={{ borderColor: Colors.primary.main }}
    >
      <Grid
        item
        xs={6}
        display="flex"
        alignItems="center"
        gap={1}
        justifyContent="flex-start"
      >
        <Box>
          <Typography variant="body1" color={Colors.gray1} lineHeight={1.5}>
            {usuario.nome}
          </Typography>
          <Typography variant="caption" color={Colors.gray1}>
            CPF:
            <PatternFormat
              displayType="text"
              value={usuario.cpf}
              format=" ###.###.###-##"
            />
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={6}
        gap={2}
        container
        alignItems="center"
        justifyContent="flex-end"
      >
        <FormControlLabel
          label="Ativo"
          labelPlacement="start"
          sx={{ color: Colors.gray1 }}
          control={
            loading ? (
              <Box my={1} mx={3}>
                <CircularProgress size={18} />
              </Box>
            ) : (
              <Switch
                checked={usuarioAtivo}
                disabled={loading}
                onChange={changeUserStatus}
              />
            )
          }
        />
        <Box
          onClick={onClickEdit}
          sx={{ cursor: 'pointer' }}
          borderColor={Colors.primary.main}
        >
          <Icon path={mdiPencil} size={1} color={Colors.primary.main} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default CardUsuario;
