import {
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch
} from '@mui/material';
import { useState } from 'react';

import { Cliente } from '../../../models/Cliente.model';
import UsuarioService from '../../../services/Usuario.service';
import { Colors } from '../../../shared/colors';

interface Props {
  cliente: Cliente;
  disabled?: boolean;
}

export const ClientePosPago = ({ cliente, disabled = false }: Props) => {
  const [posPagoStatus, setPosPagoStatus] = useState(cliente.posPagamentoAtivo);
  const [loading, setLoading] = useState(false);

  const onChange = () => {
    setLoading(true);
    UsuarioService.alterarMigracaoPosPago(cliente.id, !posPagoStatus)
      .then(() => setPosPagoStatus(!posPagoStatus))
      .finally(() => setLoading(false));
  };

  return (
    <Grid item alignItems="center">
      {cliente.clientePosPago && (
        <FormControlLabel
          label="Pós-pago"
          labelPlacement="start"
          sx={{ color: Colors.gray1, whiteSpace: 'nowrap' }}
          control={
            loading ? (
              <Box my={1} mx={3}>
                <CircularProgress size={18} />
              </Box>
            ) : (
              <Switch
                checked={posPagoStatus}
                disabled={loading || disabled}
                onChange={onChange}
              />
            )
          }
        />
      )}
    </Grid>
  );
};
