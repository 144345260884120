import {
  Box,
  Switch,
  SwitchProps as DefaultSwitchProps,
  Typography
} from '@mui/material';
import {
  Controller,
  ControllerProps,
  FieldError,
  FieldValues,
  get,
  useFormState
} from 'react-hook-form';

import { Colors } from '../../../shared/colors';

import InputError from './InputError';
export interface SwitchProps<T extends DefaultSwitchProps>
  extends Omit<ControllerProps<T>, 'render'>,
    Omit<DefaultSwitchProps, 'defaultValue' | 'name'> {
  errors?: FieldError | string;
  label: string;
}

export function FormSwitch<T extends FieldValues>({
  control,
  errors,
  name,
  label,
  ...switchProps
}: SwitchProps<T>) {
  const formState = useFormState<T>({ control });
  const error = get(formState.errors, name);

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Box display="flex" alignItems="center">
            <Typography color={Colors.primary.main}>{label}</Typography>
            <Switch
              {...switchProps}
              value={value}
              onChange={onChange}
              checked={value}
            />
          </Box>
        )}
      />
      <InputError error={error ?? errors} />
    </>
  );
}
