import { useState } from 'react';

import {
  FILTRO_DEFAULT,
  FiltroComissoes
} from '../../../models/FiltroComissoes.model';
import Drawer from '../../components/Drawer';

import FiltrarComissoes from './FiltrarComissoes';

interface Props {
  open: boolean;
  onClose: (data: FiltroComissoes) => void;
  permitirFiltrarUsuarios?: boolean;
}

const DrawerFiltrarComissoes = ({
  open,
  onClose,
  permitirFiltrarUsuarios = false
}: Props) => {
  const [filtroComissoes, setFiltroComissoes] =
    useState<FiltroComissoes>(FILTRO_DEFAULT);

  const onCloseDrawer = (filtroComissoes: FiltroComissoes) => {
    const filtroSemValoresVazios = Object.fromEntries(
      Object.entries(filtroComissoes).filter(([_, v]) => v)
    );
    setFiltroComissoes(filtroSemValoresVazios);
    onClose(filtroSemValoresVazios);
  };

  return (
    <Drawer
      title="Filtrar"
      open={open}
      onClose={() => {
        onCloseDrawer(filtroComissoes);
      }}
    >
      <FiltrarComissoes
        onClose={onCloseDrawer}
        filtroComissoes={filtroComissoes}
        onChangeFilter={setFiltroComissoes}
        permitirFiltrarUsuarios={permitirFiltrarUsuarios}
      />
    </Drawer>
  );
};

export default DrawerFiltrarComissoes;
