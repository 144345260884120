import { Box } from '@mui/material';

import { BASE_CONFIG } from '../../shared/baseConfig';
import Instruction from '../components/Instruction.doc';
import Step from '../components/Step.doc';
import Tip from '../components/Tip.doc';

export const FiltrarTransacoesPessoais = () => (
  <Instruction titulo="Filtrar transações pessoais no EmbarqueJá">
    <Box display="flex" flexDirection="column" alignItems="center">
      <Step descricao={`1. Navegue para ${BASE_CONFIG.App.ClienteUrl}`} />
      <Step
        descricao='2. Clique em "Filtrar".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-27/24c9dfb2-bff1-46a7-b77c-e0908a580348/ascreenshot.jpeg?tl_px=1060,251&br_px=1920,732&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=407,212"
      />

      <Tip
        descricao='Tip: Selecione os filtros de acordo com sua necessidade. Neste exemplo é filtrado as operações de
    "Saldo adcionado".'
      />
      <Step
        descricao='3. Clique no campo "Tipo de transação" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-27/145f5665-4407-44e6-b358-c9c979cd83d8/ascreenshot.jpeg?tl_px=1060,0&br_px=1920,480&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=670,145"
      />
      <Step
        descricao='4. Clique em "Filtrar".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-27/860aae68-588f-436f-ab6a-172134ef82e4/ascreenshot.jpeg?tl_px=1060,448&br_px=1920,929&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=497,400"
      />
    </Box>
  </Instruction>
);
