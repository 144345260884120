import { Box } from '@mui/material';

import { BASE_CONFIG } from '../../shared/baseConfig';
import Instruction from '../components/Instruction.doc';
import Step from '../components/Step.doc';

export const CadastrarUsuario = () => (
  <Instruction titulo="Como cadastrar um usuário administrativo no EmbarqueJá">
    <Box display="flex" flexDirection="column" alignItems="center">
      <Step descricao={`1. Navegue para ${BASE_CONFIG.App.BaseUrl}`} />
      <Step
        descricao='2. Clique em "Gestão de usuários"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/964e85aa-4e45-4b9f-9c8a-218b3e9de684/ascreenshot.jpeg?tl_px=1000,0&br_px=1746,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,67"
      />
      <Step
        descricao='3. Clique em "Cadastrar usuário"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/74aad544-0b63-4f1d-8989-abcca5ba0698/ascreenshot.jpeg?tl_px=1034,0&br_px=1780,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,96"
      />
      <Step
        descricao='4. Clique no campo "*Tipo de usuário".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/4de807f0-9ab3-43e9-b090-bc6a6fa071ed/ascreenshot.jpeg?tl_px=1045,0&br_px=1791,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,95"
      />
      <Step
        descricao='5. Clique na opção "Administrativo".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/73c4d226-0209-4cd9-9bcb-9dda6075ef40/ascreenshot.jpeg?tl_px=1028,3&br_px=1774,423&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='6. Clique no campo "*Nome" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/62eca780-3e06-4a87-8d3d-bced95fbf86b/ascreenshot.jpeg?tl_px=1154,61&br_px=1900,481&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='7. Clique no campo "*CPF" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/13aa9dce-bbad-407f-a7e3-dc32937a5813/ascreenshot.jpeg?tl_px=1147,123&br_px=1893,543&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='8. Clique no campo "*E-mail do usuário" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/d8f83af8-1405-4852-8bf3-9a6d16b7ce43/ascreenshot.jpeg?tl_px=998,243&br_px=1744,663&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='9. Clique no campo "Telefone" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/23b960da-968b-4a82-911c-691e024e73c5/ascreenshot.jpeg?tl_px=1015,327&br_px=1761,747&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='10. Clique em "Cadastrar"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/aa4a62f2-dd6d-475d-8f70-e3c74137c875/ascreenshot.jpeg?tl_px=1091,509&br_px=1837,929&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,266"
      />
    </Box>
  </Instruction>
);
