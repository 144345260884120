import { Box } from '@mui/material';

import { BASE_CONFIG } from '../../shared/baseConfig';
import Alert from '../components/Alert.doc';
import Instruction from '../components/Instruction.doc';
import Step from '../components/Step.doc';

const AddUsuarioPFPelaContaPJ = () => {
  return (
    <Instruction titulo="Cadastrar um usuário PF pela conta PJ no EmbarqueJa">
      <Box display="flex" flexDirection="column" alignItems="center">
        <Step descricao={`1. Navegue para ${BASE_CONFIG.App.ClienteUrl}`} />

        <Step
          descricao='2. Clique em "Gestão de usuários"'
          imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-31/77ccc18a-ddae-4ce6-8eb1-cac3454d43f0/ascreenshot.jpeg?tl_px=435,0&br_px=1295,480&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,84"
        />
        <Step
          descricao='3. Clique em "Associar usuários"'
          imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-31/452c0d33-ce11-45e6-b496-fea33e7283d6/ascreenshot.jpeg?tl_px=935,0&br_px=1795,480&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,144"
        />
        <Step
          descricao="4. Preencha os campos de o cadastro do usuário."
          imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-31/0bebe4f9-4938-45c1-b11c-db522be09d09/ascreenshot.jpeg?tl_px=1060,0&br_px=1920,480&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=448,185"
        />

        <Alert
          descricao="Alert: Caso necessário, pode-se também associar um novo cartão ao
          cadastro do usuário nesse momento."
        />
        <Step
          descricao='5. Clique em "Confirmar associação"'
          imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-31/782f1596-4efb-48e9-9c45-900c3cfd7ab2/ascreenshot.jpeg?tl_px=1060,442&br_px=1920,923&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=534,399"
        />
        <Step
          descricao='6. Clique em "Fechar"'
          imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-31/5f71dcd7-d72f-448d-b4ed-2d40ab429c5b/ascreenshot.jpeg?tl_px=970,442&br_px=1830,923&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,423"
        />
      </Box>
    </Instruction>
  );
};

export default AddUsuarioPFPelaContaPJ;
