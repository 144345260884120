import * as yup from 'yup';

export interface FiltroUsuario {
  nome?: string;
  cpf?: string;
}

export const FILTRO_USUARIO: FiltroUsuario = {
  nome: '',
  cpf: ''
};

export const FiltroUsuarioFormModelSchema: yup.ObjectSchema<FiltroUsuario> = yup
  .object()
  .shape({
    nome: yup.string().optional(),
    cpf: yup.string().optional()
  });
