import { useState } from 'react';

import { Cliente } from '../../../models/Cliente.model';
import {
  FILTRO_USUARIO,
  FiltroUsuario
} from '../../../models/FiltroUsuario.model';
import Drawer from '../../components/Drawer';

import FormAprovarMigracao from './FormAprovarMigracao';

interface Props {
  open: boolean;
  cliente: Cliente | null;
  onClose: (data?: FiltroUsuario) => void;
}

const DrawerAprovarMigracao = ({ open, cliente, onClose }: Props) => {
  const [filtroUsuario, setFiltroUsuario] =
    useState<FiltroUsuario>(FILTRO_USUARIO);

  const onCloseDrawer = (filtroUsuario: FiltroUsuario) => {
    setFiltroUsuario(filtroUsuario);
    onClose(filtroUsuario);
  };

  return (
    <Drawer
      title="Migração de Plano"
      open={open}
      onClose={() => {
        onCloseDrawer(filtroUsuario);
      }}
    >
      {cliente?.id && (
        <FormAprovarMigracao onClose={onClose} idCliente={cliente.id} />
      )}
    </Drawer>
  );
};

export default DrawerAprovarMigracao;
