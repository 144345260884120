import { Box } from '@mui/material';

import { BASE_CONFIG } from '../../shared/baseConfig';
import Instruction from '../components/Instruction.doc';
import Step from '../components/Step.doc';
import Tip from '../components/Tip.doc';

export const GerarListaCartoes = () => (
  <Instruction titulo="Gerar lista de cartões a serem produzidos no EmbarqueJá">
    <Box display="flex" flexDirection="column" alignItems="center">
      <Step descricao={`1. Navegue para ${BASE_CONFIG.App.BaseUrl}`} />
      <Step
        descricao='2. Clique na aba "Associação de cartões"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/02fb5b11-938b-4e32-aaa5-df5301f86bde/ascreenshot.jpeg?tl_px=229,0&br_px=975,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,60"
      />
      <Step
        descricao='3. Clique no botão "Gerar / Importar"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/f8a340c1-81bd-4f32-b531-6f391a8c2d3d/ascreenshot.jpeg?tl_px=969,0&br_px=1715,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,105"
      />
      <Step
        descricao="4. Preencha a quantidade de cartões a ser gerada."
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/2529bb64-8a27-41a1-a8b9-79040f8ac923/ascreenshot.jpeg?tl_px=980,0&br_px=1726,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,125"
      />
      <Step
        descricao='5. Clique em "Gerar"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/bacaa72d-3d7c-42cb-bf26-973f4d649c1c/ascreenshot.jpeg?tl_px=1091,45&br_px=1837,465&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='6. Clique em "Fechar"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/b0cf86eb-ac52-4837-b72d-bf89b91e5fe2/ascreenshot.jpeg?tl_px=1000,452&br_px=1746,872&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,267"
      />
      <Tip descricao="Tip: Após clicar em download seu arquivo será baixado e estará na pasta de Downloads do seu dispositivo." />
    </Box>
  </Instruction>
);
