import { Box, Typography } from '@mui/material';

import { Colors } from '../../shared/colors';

interface Props {
  descricao: string;
}

const Alert = ({ descricao }: Props) => {
  return (
    <Box
      p={4}
      my={1}
      border={1}
      borderRadius={1}
      bgcolor={Colors.errorLight}
      borderColor={Colors.error.dark}
    >
      <Typography color={Colors.error.dark}>{descricao}</Typography>
    </Box>
  );
};

export default Alert;
