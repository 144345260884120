import { mdiContentCopy } from '@mdi/js';
import Icon from '@mdi/react';
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  LogLevel
} from '@microsoft/signalr';
import { Box, Typography } from '@mui/material';
import { Colors } from '@vabembarqueja/embarque-ja-shared-components';
import { useEffect, useState } from 'react';

import Success from '../../../assets/success.svg';
import { BASE_CONFIG } from '../../../shared/baseConfig';
import FeedbackDrawer from '../../components/FeedbackDrawer';
import { useMsalService } from '../authentication/services/msal.service';

type Props = {
  onClose: () => void;
  idPagamento: string;
};

interface RetornoPicPay {
  sucesso: boolean;
  idSolicitacao?: string;
  idTransacao: string;
  valor: number;
}

const LinkPagamentoGerado = ({ onClose, idPagamento }: Props) => {
  const { getAccessToken } = useMsalService();
  const [connection, setConnection] = useState<null | HubConnection>(null);
  const [linkPagamento, setLinkPagamento] = useState('');
  const [pagamentoRecebido, setPagamentoRecebido] = useState(false);

  useEffect(() => {
    async function buildConnection() {
      const token = await getAccessToken();
      const query = idPagamento ? `?id_solicitacao=${idPagamento}` : '';

      return new HubConnectionBuilder()
        .configureLogging(LogLevel.Debug)
        .withUrl(BASE_CONFIG.Api.HubUrl + '/hub/pagamento-hub' + query, {
          // accessTokenFactory: getAccessToken,
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .withAutomaticReconnect()
        .build();
    }

    buildConnection().then(setConnection);
  }, [getAccessToken, idPagamento]);

  useEffect(() => {
    if (!connection) return;
    connection
      .start()
      .then(() => {
        connection.on('PagamentoRecebido', (message: RetornoPicPay) => {
          if (message.idSolicitacao && message.idSolicitacao !== idPagamento)
            return;
          if (message.sucesso) setPagamentoRecebido(true);
          else setPagamentoRecebido(false);
        });
      })
      .catch((error: any) => console.log(error));

    return () => {
      connection.stop();
    };
  }, [connection, idPagamento]);

  useEffect(() => {
    const link = `${BASE_CONFIG.App.ClienteUrl}/pagamento/${idPagamento}`;
    setLinkPagamento(link);
  }, [idPagamento]);

  const copiar = () => {
    const link = linkPagamento;
    navigator.clipboard.writeText(linkPagamento);
    setLinkPagamento('Link copiado com sucesso!');

    setTimeout(() => {
      setLinkPagamento(link);
    }, 2000);
  };

  return (
    <FeedbackDrawer
      titulo={pagamentoRecebido ? 'Pagamento Realizado!' : 'Carga solicitada!'}
      subtitulo={
        pagamentoRecebido
          ? 'O pagamento da carga foi realizado!'
          : 'Carga solicitada com sucesso!'
      }
      textoBotao="Fechar"
      onClick={onClose}
      imagemModal={Success}
    >
      {!pagamentoRecebido && (
        <Box
          width="100%"
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          onClick={copiar}
          sx={{ cursor: 'pointer' }}
        >
          <Box display={'flex'} gap={1}>
            <Icon path={mdiContentCopy} size={1} color={Colors.primary.main} />
            <Typography>Copiar link de carga</Typography>
          </Box>
          <Box padding={1}>
            <Typography color={Colors.primary.dark} variant="h6">
              {linkPagamento}
            </Typography>
          </Box>
        </Box>
      )}
    </FeedbackDrawer>
  );
};

export default LinkPagamentoGerado;
