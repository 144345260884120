import { Box, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';

import { Comissao } from '../../models/Comissao.model';
import { TIPO_TRANSACAO } from '../../models/Transacao.model';
import { Colors } from '../../shared/colors';

import CpfCnpjFormatter from './CpfCnpjFormatter';
import Currency from './Currency';

interface Props {
  comissao: Comissao;
  onClick?: () => void;
}

const CardComissao = ({ comissao }: Props) => {
  return (
    <Grid
      container
      border={1}
      paddingX={2}
      paddingY={1.5}
      marginBottom={2}
      borderRadius={1}
      display="flex"
      sx={{ borderColor: Colors.primary.main }}
    >
      <Grid item container xs={12}>
        <Grid xs={12} sm={5} item mb={1}>
          <Typography
            lineHeight={1.5}
            color={Colors.secondary}
            whiteSpace="nowrap"
            fontWeight={600}
          >
            {comissao.nomeAgencia ? `Agência: ${comissao.nomeAgencia}` : ''}
          </Typography>
        </Grid>
        {comissao.agenteMatricula && (
          <Grid xs={12} sm={5} item mb={1}>
            <Typography
              lineHeight={1.5}
              color={Colors.secondary}
              whiteSpace="nowrap"
              fontWeight={600}
            >
              Matrícula do agente: {comissao.agenteMatricula}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item container flex={1} display="flex">
        <Grid item container xs={10} sm={5} display="flex" gap={2}>
          <Grid item display="flex" flexDirection="column">
            <Box display="flex">
              <Typography
                variant="body1"
                color={Colors.gray1}
                lineHeight={1.5}
                whiteSpace="nowrap"
              >
                {TIPO_TRANSACAO[comissao.tipoTransacao]}
              </Typography>
            </Box>
            <Typography variant="caption" color={Colors.gray1}>
              Autorização: {comissao.idAutorizacao}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container xs={12} sm={5} mt={{ xs: 1, md: 0 }}>
          <Grid item xs={9} container display="flex" flexDirection="column">
            <Typography variant="body1" color={Colors.gray1} lineHeight={1.5}>
              {comissao.nome}
            </Typography>
            {comissao.cpfCnpj && (
              <Typography variant="caption" color={Colors.gray1}>
                {comissao.cpfCnpj.length > 11 ? 'CNPJ: ' : 'CPF: '}
                <CpfCnpjFormatter value={comissao.cpfCnpj} />
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={2}
          mt={{ xs: 1, md: 0 }}
          flexShrink={1}
          display="flex"
          flexDirection="column"
          alignItems={{ md: 'flex-end' }}
        >
          <Typography
            variant="body1"
            fontWeight={500}
            lineHeight={1.5}
            whiteSpace="nowrap"
            color={Colors.primary.main}
          >
            <Currency value={comissao.valor} />
          </Typography>
          <Typography
            variant="caption"
            color={Colors.gray1}
            textAlign={{ md: 'right' }}
            variantMapping={{ caption: 'p' }}
            sx={{ textAlignLast: { md: 'right' } }}
          >
            {format(new Date(comissao.dataHora ?? ''), 'dd/MM/yyyy HH:mm')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CardComissao;
