import { Box, Button, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

import { Colors } from '../../shared/colors';

interface Props {
  titulo: string;
  subtitulo: string;
  textoBotao: string;
  imagemModal: string;
  onClick: () => void;
}

const FeedbackDrawer = ({
  children,
  titulo,
  subtitulo,
  textoBotao,
  imagemModal,
  onClick
}: PropsWithChildren<Props>) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
        gap={4}
      >
        <Typography color="secondary" variant="h5" fontWeight="bold">
          {titulo}
        </Typography>
        <Typography variant="body1" fontSize="1.25rem" color={Colors.gray1}>
          {subtitulo}
        </Typography>
        <Box>
          <img src={imagemModal} alt="pagamento_realizado" />
        </Box>
        <Box mt={2}>{children}</Box>
      </Box>
      <Box padding={2} display="flex" justifyContent="flex-end">
        <Button fullWidth variant="contained" onClick={onClick}>
          <Typography textTransform="none">{textoBotao}</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default FeedbackDrawer;
