import { Box, Typography } from '@mui/material';

import { Colors } from '../../shared/colors';

interface Props {
  descricao: string;
}

const Tip = ({ descricao }: Props) => {
  return (
    <Box
      p={4}
      my={1}
      border={1}
      borderRadius={1}
      bgcolor={Colors.primary.light}
      borderColor={Colors.primary.dark}
    >
      <Typography color={Colors.primary.dark}>{descricao}</Typography>
    </Box>
  );
};

export default Tip;
