import { Box } from '@mui/material';

import { BASE_CONFIG } from '../../shared/baseConfig';
import Instruction from '../components/Instruction.doc';
import Step from '../components/Step.doc';
import Tip from '../components/Tip.doc';

export const FiltrarClientes = () => (
  <Instruction titulo="Como filtrar os clientes no EmbarqueJá">
    <Box display="flex" flexDirection="column" alignItems="center">
      <Step descricao={`1. Navegue para ${BASE_CONFIG.App.BaseUrl}`} />
      <Step
        descricao='2. Clique na aba de "Gestão de clientes"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/25df9b6c-1cc9-4280-b0f5-a67ba23d79dd/ascreenshot.jpeg?tl_px=568,0&br_px=1314,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,60"
      />
      <Step
        descricao='3. Clique no botão "Filtrar".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/354b764c-2d7e-4f5e-829b-6b565d5705a1/ascreenshot.jpeg?tl_px=1136,0&br_px=1882,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,110"
      />
      <Tip descricao="Tip: Para filtrar os clientes, não é necessário que todos os campos estejam preenchidos. Preencha-os de acordo com sua necessidade." />
      <Step
        descricao='4. Clique em "Codigo" e preencha-o caso necessário.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/e02fa392-82b6-45c0-930a-37a049b3753f/ascreenshot.jpeg?tl_px=1119,0&br_px=1865,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,111"
      />
      <Step
        descricao='5. Clique em "Nome do cliente" e preencha-o caso necessário.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/e09f5d76-7fa2-4d1e-bb4c-82ef5dcd9454/ascreenshot.jpeg?tl_px=923,35&br_px=1669,455&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='6. Clique em "CPF/CNPJ" e preencha-o caso necessário.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/a89c1935-ddb9-4a83-b8e8-c0f838271bfa/ascreenshot.jpeg?tl_px=1173,145&br_px=1919,565&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=300,139"
      />
      <Step
        descricao="7. Ative o filtro para buscar por clientes com saldo negativo caso necessário."
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/bc763bea-00f7-4a86-933b-9668dd0cd0f7/ascreenshot.jpeg?tl_px=1086,211&br_px=1832,631&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao="8. Escolha o tipo do cliente a ser filtrado, caso necessário."
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/40cccdcc-924d-4a04-84a5-9ba47b0a2ff2/ascreenshot.jpeg?tl_px=913,277&br_px=1659,697&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='9. Click the "Pós-pago ativo" field.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/cf85b4f6-95d6-4cf0-80c9-cf3206702a9c/ascreenshot.jpeg?tl_px=1173,310&br_px=1919,730&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=295,139"
      />
      <Step
        descricao="10. Escolha a situação dos cliente pós-pago caso necessário."
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/50a8a02c-1091-4def-9a08-91c7286200e9/ascreenshot.jpeg?tl_px=1173,281&br_px=1919,701&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=305,139"
      />
      <Step
        descricao='11. Clique em "Filtrar"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/6b434cc3-81c7-492e-aee8-df92888c8a16/ascreenshot.jpeg?tl_px=1160,509&br_px=1906,929&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,268"
      />
    </Box>
  </Instruction>
);
