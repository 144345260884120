import { Box, Grid } from '@mui/material';
import { useCallback, useRef, useState } from 'react';

import Associar from '../../../assets/associar-cartao.svg';
import AssociarError from '../../../assets/associar-error.svg';
import CartaoService from '../../../services/Cartao.service';
import FileService from '../../../services/File.service';
import Drawer from '../../components/Drawer';
import FeedbackDrawer from '../../components/FeedbackDrawer';
import Loading from '../../components/Loading';

import FormGerarCartao from './FormGerarCartao';
import FormImportarCartao from './FormImportarCartao';

interface Props {
  open: boolean;
  onClose: () => void;
}

const DrawerGerarImportarCartao = ({ open, onClose }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');

  const gerarArquivo = useCallback((qtd: number) => {
    setErrorMsg('');
    setSuccessMsg('');
    setLoading(true);

    CartaoService.gerarSequencialCartoes(qtd)
      .then((data) => {
        if (!ref.current) return;

        const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        FileService.downloadBlob(blob, 'cartoes.csv', ref.current)
          .then(() => {
            setSuccessMsg(
              'Seu arquivo foi gerado com sucesso, ele pode ser encontrado na pasta de Downloads do seu dispositivo!'
            );
          })
          .catch((e) => setErrorMsg(e.message));
      })
      .catch((e) => setErrorMsg(e.message))
      .finally(() => setLoading(false));
  }, []);

  const importarCartoes = useCallback((data: FormData) => {
    setErrorMsg('');
    setSuccessMsg('');
    setLoading(true);

    CartaoService.lerUIDCartoes(data)
      .then(() => {
        setSuccessMsg('Lista de cartões atualizada com sucesso!');
      })
      .catch((e) => setErrorMsg(e.message))
      .finally(() => setLoading(false));
  }, []);

  const closeDrawer = () => {
    setErrorMsg('');
    setLoading(false);
    setSuccessMsg('');
    onClose();
  };

  const getComponent = () => {
    if (successMsg)
      return (
        <FeedbackDrawer
          titulo="Deu tudo certo!"
          subtitulo={successMsg}
          textoBotao="Fechar"
          onClick={closeDrawer}
          imagemModal={Associar}
        />
      );

    if (errorMsg)
      return (
        <FeedbackDrawer
          titulo="Algo deu errado!"
          subtitulo={errorMsg}
          textoBotao="Fechar"
          onClick={closeDrawer}
          imagemModal={AssociarError}
        />
      );

    if (loading) return <Loading show={loading} />;

    return (
      <Grid container spacing={2} padding={2}>
        <FormGerarCartao gerarArquivo={gerarArquivo} />
        <FormImportarCartao importarCartoes={importarCartoes} />
      </Grid>
    );
  };

  return (
    <Drawer title="Gerar / Importar cartões" open={open} onClose={closeDrawer}>
      <Box
        ref={ref}
        width={1}
        display="flex"
        flexDirection="column"
        alignItems="space-between"
      >
        {getComponent()}
      </Box>
    </Drawer>
  );
};

export default DrawerGerarImportarCartao;
