import { LoadingWrapper } from './Loading.styles';

interface LoadingProps {
  show?: boolean;
  fullscreen?: boolean;
  bgColor?: string;
}

export default function Loading({
  show,
  fullscreen,
  bgColor = 'whitesmoke'
}: LoadingProps) {
  if (!show) return null;

  return (
    <LoadingWrapper $fullscreen={fullscreen} $bgColor={bgColor}>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </LoadingWrapper>
  );
}
