import { Box } from '@mui/material';

import { BASE_CONFIG } from '../../shared/baseConfig';
import Alert from '../components/Alert.doc';
import Instruction from '../components/Instruction.doc';
import Step from '../components/Step.doc';
import Tip from '../components/Tip.doc';

export const CadastrarPF = () => (
  <Instruction titulo="Cadastro Usuário Pessoa Física no EmbarqueJá">
    <Box display="flex" flexDirection="column" alignItems="center">
      <Step descricao={`1. Navegue para ${BASE_CONFIG.App.BaseUrl}`} />
      <Step
        descricao='2. Click "Gestão de clientes"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/c949feb7-c02d-425a-8709-54307f30c63a/ascreenshot.jpeg?tl_px=561,0&br_px=1307,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,75"
      />
      <Step
        descricao='3. Clique em "Cadastrar usuário"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/170a9ae1-8456-4a49-b14f-2eef96b4a80b/ascreenshot.jpeg?tl_px=898,0&br_px=1644,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,102"
      />
      <Step
        descricao='4. Clique em "Selecionar" na caixa de Pessoa Física'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/0b7131d8-e875-4515-9e45-92cf7ab3066b/ascreenshot.jpeg?tl_px=437,208&br_px=1183,628&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Tip descricao="Tip: Preencha suas informações de acordo com o nome do campo." />
      <Step
        descricao='5. Clique no campo "*Nome completo" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/9626f681-2788-411c-a9e5-3280687cb5a9/ascreenshot.jpeg?tl_px=206,36&br_px=952,456&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='6. Clique no campo "*CPF" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/8e060554-8c81-4175-906a-9520ff214dc0/ascreenshot.jpeg?tl_px=460,50&br_px=1206,470&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='7. Clique no campo "*RG" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/9f7efd55-3934-477a-9965-b32c86b2548c/ascreenshot.jpeg?tl_px=809,57&br_px=1555,477&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='8. Clique no campo "Data de nascimento" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/7ca7bc4c-9aa9-4d64-ba91-88f3219a3192/ascreenshot.jpeg?tl_px=356,137&br_px=1102,557&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='9. Clique no campo "*E-mail" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/86e72b81-c13b-465b-8ddf-648ccf40d811/ascreenshot.jpeg?tl_px=480,138&br_px=1226,558&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Alert descricao="Alert: Atenção, este e-mail será responsável por toda a comunicação referente ao seu acesso a plataforma (primeira senha, recuperação de senha, etc. )" />
      <Step
        descricao='10. Clique no campo "*Celular (Recebe SMS)" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/9b67c554-5c37-40d3-b006-b4ace9c3c80f/ascreenshot.jpeg?tl_px=925,131&br_px=1671,551&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Alert descricao="Alert: Atenção, cadastre um celular ativo que receba SMS, pois será usado para envio de token para utilização de certas funcionalidades no EmbarqueJá." />
      <Step
        descricao='11. Clique no campo "CEP" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/d712c93c-ea1d-4b7b-a506-06ec6782dd6d/ascreenshot.jpeg?tl_px=307,267&br_px=1053,687&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Tip descricao='Tip: Caso o "CEP" seja encontrado, os campo "Estado", "Cidade", "Bairro" e "Rua" serão preenchidos automaticamente.' />
      <Step
        descricao='12. Caso preenchido automaticamente, verifique o campo "Estado".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/25efaf2e-f939-4538-b287-c13e7b155abf/ascreenshot.jpeg?tl_px=538,288&br_px=1284,708&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='13. Caso preenchido automaticamente, verifique o campo "Cidade".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/d97f3bc5-3868-41af-9554-e50aca6c573b/ascreenshot.jpeg?tl_px=921,272&br_px=1667,692&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='14. Caso preenchido automaticamente, verifique o campo "Bairro".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/68d14656-0e0a-4bad-a49a-34d464155746/ascreenshot.jpeg?tl_px=304,371&br_px=1050,791&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='15. Caso preenchido automaticamente, verifique o campo "Rua".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/fabe7461-2bc5-4cb6-80d8-20259e8b0337/ascreenshot.jpeg?tl_px=488,391&br_px=1234,811&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='16. Clique o preencha o campo "Número".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/86a10915-8145-413c-ba8a-30e44d6a14a9/ascreenshot.jpeg?tl_px=847,398&br_px=1593,818&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='17. Clique e preencha o campo "Complemento".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/c1b03481-72ff-432f-be63-407e528da9fa/ascreenshot.jpeg?tl_px=45,484&br_px=791,904&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='18. Clique em "Realizar cadastro" para finalizar.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/5b3df277-9280-4aa5-9d2e-5929f5dd0036/ascreenshot.jpeg?tl_px=1037,509&br_px=1783,929&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,160"
      />
      <Step
        descricao='19. Clique em "Concluir".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/3f3d2426-90a2-415d-bbd5-640516dedcec/ascreenshot.jpeg?tl_px=623,399&br_px=1369,819&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Tip descricao='Tip: Ao final do cadastro, um e-mail será enviado com os dados de acesso a serem utilizados para acessar a plataforma. Utilize-os para realizar o login, os passos estão disponibilizados na seção "Acessando a plataforma Embarque Já".' />
    </Box>
  </Instruction>
);
