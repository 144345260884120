import { Box } from '@mui/material';

import { BASE_CONFIG } from '../../shared/baseConfig';
import Instruction from '../components/Instruction.doc';
import Step from '../components/Step.doc';
import Tip from '../components/Tip.doc';

export const FiltrarTransacoesCartaoEmpresa = () => (
  <Instruction titulo="Filtrar transações com cartão da empresa no EmbarqueJá">
    <Box display="flex" flexDirection="column" alignItems="center">
      <Step descricao={`1. Navegue para ${BASE_CONFIG.App.ClienteUrl}`} />
      <Step
        descricao='2. Clique na aba "TRANSAÇÕES EMPRESA"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-27/b53691cd-ac70-4880-9404-f8fd853af257/ascreenshot.jpeg?tl_px=502,251&br_px=1362,732&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />

      <Tip
        descricao="Tip: Preencha os campos de acordo com sua necessidade. Como exemplo, será realizada a busca pelas
    transações de compra de passagem."
      />
      <Step
        descricao='3. Clique em "Filtrar".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-27/7c9215ec-0a5e-4603-b4fc-bff94a54219c/ascreenshot.jpeg?tl_px=1060,258&br_px=1920,739&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=432,212"
      />

      <Step
        descricao='4. Clique no campo "Tipo de transação" e preeencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-27/89215766-64df-4262-9ecf-f248e7a2f58a/ascreenshot.jpeg?tl_px=1060,0&br_px=1920,480&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=512,117"
      />

      <Step
        descricao='5. Clique em "Filtrar".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-27/4dcad929-d7c2-4f37-b317-106286aff678/ascreenshot.jpeg?tl_px=1060,448&br_px=1920,929&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=492,412"
      />
    </Box>
  </Instruction>
);
