import * as yup from 'yup';

import { validarCPF } from '../shared/functions/validarCpf';

export enum TipoUsuario {
  PESSOA_FISICA = 1,
  PESSOA_JURIDICA = 2
}

export const TIPO_USUARIO_GESTOR = {
  ADMINISTRATIVO: 1,
  AGENTE: 2
} as const;

export const USUARIO_POLICY = {
  Administrativo: TIPO_USUARIO_GESTOR.ADMINISTRATIVO,
  'Agente de vendas': TIPO_USUARIO_GESTOR.AGENTE
} as const;

export type TipoUsuarioGestor = keyof typeof TIPO_USUARIO_GESTOR;

export enum SolicitacaoState {
  NAO_SOLICITADO = 1,
  PENDENTE = 2,
  AVALIADO = 3
}

export interface UsuarioPosPagoState {
  posPagoAtivo: boolean;
  solicitacao: SolicitacaoState;
}

export interface Usuario {
  id?: string;
  nome: string;
  cpf: string;
  email: string;
  telefone?: string;
  ativo?: boolean;
  matriculaTotalBus?: string;
  nomeAgencia?: string;
  policy?: keyof TipoUsuarioGestor | '';
}

export const USUARIO_DEFAULT: Usuario = {
  id: '',
  nome: '',
  cpf: '',
  email: '',
  telefone: '',
  ativo: true,
  policy: '',
  matriculaTotalBus: '',
  nomeAgencia: ''
};

const MSG_REQUIRED = 'Campo obrigatório';

export const cadastroPFFormModelSchema: yup.ObjectSchema<Usuario> = yup
  .object()
  .shape({
    id: yup.string().optional(),
    nome: yup.string().required(MSG_REQUIRED),
    policy: yup
      .mixed<keyof TipoUsuarioGestor | ''>()
      .notOneOf([''], MSG_REQUIRED)
      .required(MSG_REQUIRED)
      .typeError(MSG_REQUIRED),

    email: yup.string().email('Email inválido').required(MSG_REQUIRED),
    cpf: yup
      .string()
      .required(MSG_REQUIRED)
      .test('Teste documento', 'Documento inválido', (value) => {
        if (!value) return false;
        return validarCPF(value);
      })
      .transform((val) => val.replace(/[^\d]+/g, '')),
    telefone: yup.string().optional(),
    ativo: yup.boolean().optional(),
    matriculaTotalBus: yup.string().when(['policy'], {
      is: TIPO_USUARIO_GESTOR.AGENTE,
      then: (schema) => schema.required(MSG_REQUIRED),
      otherwise: (schema) => schema.optional().nullable()
    }),
    nomeAgencia: yup.string().when(['policy'], {
      is: TIPO_USUARIO_GESTOR.AGENTE,
      then: (schema) => schema.required(MSG_REQUIRED),
      otherwise: (schema) => schema.optional().nullable()
    })
  });
