import { APIPaginatedRequest } from '../models/APIPaginatedRequest';
import { APIPaginatedResponse } from '../models/APIPaginatedResponse';
import { APIResponse } from '../models/ApiResponse.model';
import {
  AssociarCartao,
  DesassociarCartao
} from '../models/AssociarCartao.model';
import { CartaoUsuario } from '../models/CartaoUsuario.model';
import { FiltroAssociarCartao } from '../models/FiltroAssociarCartao';

import Service from './Service';

class CartaoService extends Service {
  static async listarClientes(
    data: APIPaginatedRequest<FiltroAssociarCartao>,
    signal?: AbortSignal
  ) {
    return this.Http.post<APIResponse<APIPaginatedResponse<CartaoUsuario>>>(
      '/administrativo/associacao-cartao/clientes',
      data,
      { signal }
    )
      .then(this.getData)
      .then((resp) => resp.data);
  }

  static async gerarSequencialCartoes(quantidade: number) {
    return this.Http.post<string>('administrativo/gerar-sequencial-cartoes', {
      quantidade
    }).then(this.getData);
  }

  static async lerUIDCartoes(data: FormData) {
    return this.Http.post('administrativo/ler-uid-cartoes', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(this.getData)
      .then(console.log);
  }

  static async associar(data: AssociarCartao) {
    return this.Http.post(
      'administrativo/associacao-cartao/clientes/cliente/associar-cartao',
      data
    );
  }

  static async desassociar(data: DesassociarCartao) {
    return this.Http.post(
      'administrativo/associacao-cartao/clientes/cliente/desassociar-cartao',
      data
    );
  }
}

export default CartaoService;
