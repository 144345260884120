import { Box } from '@mui/material';

import { BASE_CONFIG } from '../../shared/baseConfig';
import Instruction from '../components/Instruction.doc';
import Step from '../components/Step.doc';
import Tip from '../components/Tip.doc';

export const AdicionarCargaDistanciaDebito = () => (
  <Instruction titulo=" Adicionar carga a distância com cartão de crédito no EmbarqueJá">
    <Box display="flex" flexDirection="column" alignItems="center">
      <Step descricao={`1. Navegue para ${BASE_CONFIG.App.BaseUrl}`} />
      <Step
        descricao='2. Clique na aba "Carga a distância".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/94120b39-276f-4686-9adb-4bcbe69547d0/ascreenshot.jpeg?tl_px=439,0&br_px=1185,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,57"
      />
      <Step
        descricao='3. Clique em "Adicionar saldo"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/a0d990a9-cb4e-4312-a159-f14bac9b7cb9/ascreenshot.jpeg?tl_px=1047,49&br_px=1793,469&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao="4. Selecione a opção referente à carga com cartão de crédito."
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/9662b116-a587-41dc-b4ad-2d95b37b52ba/ascreenshot.jpeg?tl_px=1173,0&br_px=1919,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=388,111"
      />
      <Step
        descricao="5. Informe o valor a ser adicionado."
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/5e383e4f-c096-4cc8-a30a-343faf14f23c/ascreenshot.jpeg?tl_px=1063,308&br_px=1809,728&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='6. Clique em "Adicionar saldo"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/c791cf31-ab58-4e52-9280-4383dc4a4907/ascreenshot.jpeg?tl_px=1173,509&br_px=1919,929&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=448,241"
      />
      <Tip descricao="Um novo código será gerado, copie-o e envie-o para o cliente desejado." />
      <Step
        descricao='7. Click "Copiar link de carga"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/b49bc00f-7c9e-466a-9ce2-f398966a7d67/ascreenshot.jpeg?tl_px=1173,368&br_px=1919,788&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=301,139"
      />
      <Step
        descricao='8. Clique em "Fechar".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/92220b4b-544d-458b-9ebe-f34522838ea8/ascreenshot.jpeg?tl_px=1173,509&br_px=1919,929&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=270,268"
      />
    </Box>
  </Instruction>
);
