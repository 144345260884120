import { Box } from '@mui/material';

import { BASE_CONFIG } from '../../shared/baseConfig';
import Alert from '../components/Alert.doc';
import Instruction from '../components/Instruction.doc';
import Step from '../components/Step.doc';

export const AdicionarSaldoPix = () => (
  <Instruction titulo="Adicionar saldo com PIX.">
    <Box display="flex" flexDirection="column" alignItems="center">
      <Step descricao={`1. Navegue para ${BASE_CONFIG.App.ClienteUrl}`} />
      <Step
        descricao='2. Clique em "Recarregar saldo"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-24/1dfb73c0-b874-44a6-b5c0-9abfa2d9d826/ascreenshot.jpeg?tl_px=980,0&br_px=1840,480&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,85"
      />
      <Step
        descricao="3. Clique em um dos valores pré-definidos."
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-24/bff3e558-53b9-4136-bc9f-d6309dbd0618/ascreenshot.jpeg?tl_px=1043,0&br_px=1903,480&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,131"
      />
      <Step
        descricao="4. Caso queria informar um valor diferente, clique no campo e preencha-o."
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-24/d54a9f29-1992-4621-a221-fc19d89e19ab/screenshot.png?tl_px=0,0&br_px=684,373&force_format=png&width=860"
      />
      <Step
        descricao='5. Clique em "Próximo"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-24/f81782af-baa7-4216-baf3-cf2c905f10b9/ascreenshot.jpeg?tl_px=1060,448&br_px=1920,929&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=470,404"
      />
      <Step
        descricao="6. Selecione a opção PIX"
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-24/979191c0-e520-400f-a66e-176c7af55964/ascreenshot.jpeg?tl_px=1060,27&br_px=1920,508&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=487,212"
      />
      <Step
        descricao='7. Clique em "Continuar para pix"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-24/6bc61bac-59eb-439c-b3ba-51f0cf938362/ascreenshot.jpeg?tl_px=1055,0&br_px=1915,480&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,114"
      />
      <Step
        descricao="8. Escaneie o QRCode e faça o pagamento."
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-24/1ad51625-88f9-4c34-bce2-21b5998771d0/screenshot.png?tl_px=47,103&br_px=906,584&force_format=png&width=860"
      />
      <Alert descricao="Alert: O QRCode é válido por 15 minutos." />
      <Step
        descricao="9. Ao concluir o pagamento, basta clicar em fechar."
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-24/2c86954a-1433-4cd5-8065-1409ffa7f832/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=672,710&force_format=png&width=1120.0"
      />
    </Box>
  </Instruction>
);
