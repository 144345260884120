import jwt_decode from 'jwt-decode';

import Service from '../../../../services/Service';
import { UserInfo } from '../authenticationState';

export default class TokenService extends Service {
  public static async parseToken(token?: string) {
    if (!token) return null;
    const decoded: any = jwt_decode(token);

    const info: UserInfo = {
      id: '',
      email: decoded.email,
      nome: `${decoded.given_name} ${decoded.family_name ?? ''}`,
      extension_EmbarquejaAdmClaim: decoded.extension_EmbarquejaAdmClaim
    };

    return info;
  }
}
