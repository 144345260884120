import { mdiFilterVariant } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';

import { ConsumoPosPago as ConsumoPosPagoModel } from '../../models/ConsumoPosPago.model';
import { FiltroPosPago } from '../../models/FiltroPosPago.model';
import ConsumoPosPagoService from '../../services/ConsumoPosPago.service';
import { Colors } from '../../shared/colors';
import useInfiniteScroll from '../../shared/hooks/infiniteScroll';
import CardPosPago from '../components/CardPosPago';
import DrawerFiltrarConsumoPosPago from '../features/filtrar-consumo-pos-pago/DrawerFiltrarConsumoPosPago';

const ConsumoPosPago = () => {
  const [periodo, setPeriodo] = useState(new Date());
  const [filtroUsuarios, setFiltroUsuarios] = useState<FiltroPosPago>();
  const [openDrawerFiltroUsuario, setOpenDrawerFiltroUsuario] = useState(false);

  const closeDrawerFiltro = (filtro: FiltroPosPago) => {
    setOpenDrawerFiltroUsuario(false);
    setFiltroUsuarios(filtro);
  };

  const { loading, items, lastElementRef, hasNext } = useInfiniteScroll<
    ConsumoPosPagoModel,
    FiltroPosPago
  >({
    load: ConsumoPosPagoService.listarClientes,
    search: filtroUsuarios
  });

  const carregandoItens = (loading && hasNext) || loading;

  useEffect(() => {
    // Soma mais 1, pois getMonth retorna inicando em 0.
    const mes = periodo.getMonth() + 1;
    const ano = periodo.getFullYear();

    setFiltroUsuarios((prev) => ({ ...prev, mes, ano }));
  }, [periodo]);

  return (
    <Grid container spacing={2} paddingY={2}>
      <Grid
        item
        xs={12}
        container
        spacing={2}
        display="flex"
        alignItems="center"
      >
        <Grid item xs={12} sm={8} md={9} lg={10} justifyContent="flex-end">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5" color={Colors.secondary} fontWeight={700}>
              Consumo pós-pago
            </Typography>
            <Box
              gap={1}
              display="flex"
              sx={{ cursor: 'pointer' }}
              onClick={() => setOpenDrawerFiltroUsuario(true)}
            >
              <Typography fontWeight="bold" color={Colors.primary.main}>
                Filtrar
              </Typography>
              <Icon
                size={1}
                path={mdiFilterVariant}
                color={Colors.primary.main}
              />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={4}
          md={3}
          lg={2}
          justifyContent="flex-end"
        >
          <Grid item xs={12}>
            <DatePicker
              value={periodo}
              views={['month', 'year']}
              onChange={(value) => setPeriodo(value ?? new Date())}
              slotProps={{
                textField: {
                  fullWidth: true,
                  size: 'small',
                  color: 'primary',
                  onKeyDown: (e) => e.preventDefault()
                }
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {items.map((consumoPosPago, index, arr) => {
          const lastElement = arr.length === index + 1;
          const ref = lastElement && hasNext ? lastElementRef : null;
          return (
            <div ref={ref} key={consumoPosPago.cnpj}>
              <CardPosPago consumoPosPago={consumoPosPago} />
            </div>
          );
        })}
      </Grid>

      {carregandoItens && (
        <Grid container gap={2} ml={2}>
          {Array(5)
            .fill(1)
            .map((_item, index) => index)
            .map((value) => (
              <Skeleton
                key={value}
                variant="rounded"
                width="100%"
                height={70}
              />
            ))}
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography
          mt={3}
          variant="body1"
          fontWeight={500}
          color={Colors.gray1}
          textAlign="center"
        >
          {!hasNext && !loading && 'Não há dados a serem exibidos'}
        </Typography>
      </Grid>

      <DrawerFiltrarConsumoPosPago
        periodo={periodo}
        open={openDrawerFiltroUsuario}
        onClose={closeDrawerFiltro}
      />
    </Grid>
  );
};

export default ConsumoPosPago;
