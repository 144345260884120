export const BASE_CONFIG = {
  Enviroment: process.env.NODE_ENV,
  App: {
    ClienteUrl: process.env.REACT_APP_CLIENTE_URL,
    BaseUrl: process.env.REACT_APP_BASE_URL,
    Version: process.env.REACT_APP_VERSION,
    Name: process.env.REACT_APP_NAME
  },
  Api: {
    BaseUrl: process.env.REACT_APP_BASE_API_URL,
    HubUrl: process.env.REACT_APP_BASE_NOTIFICATION_URL
  },
  AppInsights: {
    ConnectionString: process.env.REACT_APP_INSIGHTS_CONNECTION_STRING
  },
  Auth: {
    TenantHeader: process.env.REACT_APP_BASE_API_URL
  }
} as const;
