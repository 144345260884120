import {
  Box,
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { PatternFormat } from 'react-number-format';

import AssociarCartao from '../../../assets/associar-cartao.svg';
import AssociarError from '../../../assets/associar-error.svg';
import { DesassociarCartao } from '../../../models/AssociarCartao.model';
import CartaoService from '../../../services/Cartao.service';
import { Colors } from '../../../shared/colors';
import FeedbackDrawer from '../../components/FeedbackDrawer';
import Loading from '../../components/Loading';

interface Props {
  onClose: () => void;
  desassociacaoCartao: DesassociarCartao;
}

const FormDesassociarCartao = ({ desassociacaoCartao, onClose }: Props) => {
  const [errorMSg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const desassociarCartao = () => {
    CartaoService.desassociar(desassociacaoCartao)
      .then(() => setCompleted(true))
      .catch((err) => {
        setErrorMsg(err.message);
      })
      .finally(() => setLoading(false));
  };

  if (completed)
    return (
      <FeedbackDrawer
        titulo="Cartão desassociado!"
        subtitulo="Cartão desassociado com sucesso!"
        textoBotao="Fechar"
        onClick={onClose}
        imagemModal={AssociarCartao}
      />
    );

  if (errorMSg)
    return (
      <FeedbackDrawer
        titulo="Erro ao associar!"
        subtitulo={errorMSg}
        textoBotao="Fechar"
        onClick={onClose}
        imagemModal={AssociarError}
      />
    );

  return (
    <>
      <Loading show={loading} />
      <Box
        width={1}
        display="flex"
        flexDirection="column"
        alignItems="space-between"
      >
        <Box p={2}>
          <Typography color={Colors.secondary} variant="h6" mb={2}>
            Desassociar um cartão?
          </Typography>
        </Box>
        <Grid item container spacing={2} px={2} alignContent="flex-start">
          <Grid item xs={12}>
            <InputLabel>Número do cartão</InputLabel>
            <PatternFormat
              disabled
              fullWidth
              sx={{ mt: 1 }}
              name="numeroCartao"
              format="#############"
              placeholder="*Número do cartão"
              value={desassociacaoCartao.numeroCartao}
              customInput={TextField}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} p={3} alignContent="flex-end">
          <Button fullWidth variant="contained" onClick={desassociarCartao}>
            <Typography variant="body1" textTransform="none">
              Confirmar desassociação
            </Typography>
          </Button>
        </Grid>
      </Box>
    </>
  );
};

export default FormDesassociarCartao;
