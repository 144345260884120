import { mdiFilterVariant } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { Colors } from '@vabembarqueja/embarque-ja-shared-components';
import { useEffect, useState } from 'react';

import { Comissao } from '../../models/Comissao.model';
import { FiltroComissoes } from '../../models/FiltroComissoes.model';
import ComissaoService from '../../services/Comissao.service';
import useInfiniteScroll from '../../shared/hooks/infiniteScroll';
import CardComissao from '../components/CardComissao';
import DrawerFiltrarComissoes from '../features/filtrar-comissoes/DrawerFiltrarComissoes';

function GestaoComissoes() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [filtros, setFiltros] = useState<FiltroComissoes>();

  useEffect(() => {
    setOpenDrawer(false);
  }, []);

  const { loading, items, lastElementRef, hasNext } = useInfiniteScroll<
    Comissao,
    FiltroComissoes
  >({
    load: ComissaoService.listar,
    search: filtros
  });

  const closeDrawerFiltro = (filtro: FiltroComissoes) => {
    setOpenDrawer(false);
    setFiltros(filtro);
  };

  return (
    <Grid container spacing={2} paddingY={2}>
      <Grid
        item
        container
        xs={12}
        spacing={2}
        alignItems="center"
        display="flex"
      >
        <Grid item xs={8} sm={6}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" color={Colors.secondary} fontWeight={700}>
              Gestão de comissões
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
          sm={6}
          gap={2}
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          <Box
            display="flex"
            onClick={() => setOpenDrawer(true)}
            sx={{ cursor: 'pointer' }}
            gap={1}
          >
            <Typography
              fontWeight="bold"
              color={Colors.primary.main}
              display={{ xs: 'none', sm: 'block' }}
            >
              Filtrar
            </Typography>
            <Icon
              path={mdiFilterVariant}
              size={1}
              color={Colors.primary.main}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {items.map((item, index, arr) => {
          const lastElement = arr.length === index + 1;
          const ref = lastElement && hasNext ? lastElementRef : null;
          return (
            <div ref={ref} key={item.idTransacao}>
              <CardComissao comissao={item} />
            </div>
          );
        })}
      </Grid>

      {loading && (
        <Grid container gap={2} ml={2}>
          {Array(5)
            .fill(1)
            .map((_item, index) => index)
            .map((value) => (
              <Skeleton
                key={value}
                variant="rounded"
                width="100%"
                height={70}
              />
            ))}
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography
          mt={3}
          variant="body1"
          fontWeight={500}
          color={Colors.gray1}
          textAlign="center"
        >
          {!hasNext && !loading && 'Não há dados a serem exibidos'}
        </Typography>
      </Grid>
      <DrawerFiltrarComissoes open={openDrawer} onClose={closeDrawerFiltro} />
    </Grid>
  );
}

export default GestaoComissoes;
