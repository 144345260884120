import { Box, TextField, Typography } from '@mui/material';
import { Colors, Loading } from '@vabembarqueja/embarque-ja-shared-components';

import AssociarError from '../../../assets/associar-error.svg';
import Success from '../../../assets/success.svg';
import CpfCnpjFormatter from '../../components/CpfCnpjFormatter';
import FeedbackDrawer from '../../components/FeedbackDrawer';

interface BlockUserContentProps {
  loading: boolean;
  errorMsg: string;
  completed: boolean;

  usuario: Usuario;
  justificativa: string;
  setJustificativa: (value: string) => void;
  handleClose: () => void;
}
interface Usuario {
  id: string;
  nome: string;
  cpfCnpj: string;
}
const ConteudoBloqueio = ({
  loading,
  errorMsg,
  completed,
  usuario,
  justificativa,
  setJustificativa,
  handleClose
}: BlockUserContentProps) => {
  if (loading) {
    return <Loading bgColor={Colors.white} show />;
  }

  if (errorMsg) {
    return (
      <FeedbackDrawer
        titulo="Algo deu errado!"
        subtitulo={errorMsg}
        textoBotao="Fechar"
        onClick={handleClose}
        imagemModal={AssociarError}
      />
    );
  }

  if (completed) {
    return (
      <FeedbackDrawer
        titulo="Usuário bloqueado!"
        subtitulo="O usuário foi bloqueado com sucesso."
        textoBotao="Fechar"
        onClick={handleClose}
        imagemModal={Success}
      />
    );
  }

  return (
    <>
      {/* <Loading show /> */}
      <Typography>Dados do Cliente:</Typography>
      <Box
        mt={1}
        padding={2}
        bgcolor="#E5EFFA"
        sx={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;' }}
      >
        <Box display="flex" alignContent="center">
          <Typography fontStyle="inherit">Nome/Razão Social: </Typography>
          <Typography ml={1}>{usuario.nome}</Typography>
        </Box>
        <Box display="flex" alignContent="center">
          <Typography>CPF/CNPJ: </Typography>
          <Typography ml={1}>
            <CpfCnpjFormatter value={usuario.cpfCnpj} />
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography sx={{ mt: 2 }}>Justificativa:</Typography>
        <TextField
          sx={{ mt: 1 }}
          onChange={(e) => setJustificativa(e.target.value)}
          value={justificativa}
          placeholder="Por favor, insira a justificativa para o bloqueio..."
          fullWidth
          multiline
          rows={5}
        />
      </Box>
    </>
  );
};

export default ConteudoBloqueio;
