import { Box } from '@mui/material';

import { BASE_CONFIG } from '../../shared/baseConfig';
import Instruction from '../components/Instruction.doc';
import Step from '../components/Step.doc';
import Tip from '../components/Tip.doc';

export const FiltrarTransacoesPJ = () => (
  <Instruction titulo="Filtrar Transações de conta PJ no EmbarqueJá">
    <Box display="flex" flexDirection="column" alignItems="center">
      <Step descricao={`1. Navegue para ${BASE_CONFIG.App.BaseUrl}`} />
      <Step
        descricao='2. Clique na aba "Transações".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-27/e9da237e-633c-4aa5-9229-63ab61d09eee/ascreenshot.jpeg?tl_px=91,0&br_px=837,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,58"
      />
      <Step
        descricao='3. Clique em "Filtrar"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-27/335d21c5-ee4f-4a90-8cb8-d3a861afd5cb/ascreenshot.jpeg?tl_px=1112,0&br_px=1858,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,112"
      />
      <Tip descricao="Tip: Preencha os campos de acordo com sua necessidade. Como exemplo, será realizada a busca pela empresa utilizando o CNPJ." />
      <Step
        descricao='4. Clique no campo "CPF/CNPJ" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-27/fbfedb75-fa15-46d7-b99a-998f0a13adf5/ascreenshot.jpeg?tl_px=1035,147&br_px=1781,567&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='5. Clique em "Filtrar"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-27/ff1ede83-6879-4752-8771-4bf7e895997e/ascreenshot.jpeg?tl_px=1173,509&br_px=1919,929&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=289,268"
      />
    </Box>
  </Instruction>
);
