import * as yup from 'yup';
export type AssociarCartao = {
  idCarteira: string;
  usuarioId: string;
  numeroCartao: string;
};

export const ASSOCIAR_CARTAO_DEFAULT: AssociarCartao = {
  numeroCartao: '',
  idCarteira: '',
  usuarioId: ''
};

export type DesassociarCartao = AssociarCartao;

export const AssociarCartaoFormModelSchema: yup.ObjectSchema<AssociarCartao> =
  yup.object().shape({
    idCarteira: yup
      .string()
      .required('Campo obrigatório')
      .typeError('Campo obrigatório'),
    usuarioId: yup
      .string()
      .required('Campo obrigatório')
      .typeError('Campo obrigatório'),
    numeroCartao: yup
      .string()
      .required('Campo obrigatório')
      .typeError('Campo obrigatório')
  });
