import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import AssociarError from '../../../assets/associar-error.svg';
import Success from '../../../assets/success.svg';
import { Agencia } from '../../../models/Agencia';
import {
  cadastroPFFormModelSchema,
  TIPO_USUARIO_GESTOR,
  Usuario,
  USUARIO_POLICY
} from '../../../models/Usuario.model';
import TotalBusService from '../../../services/TotalBus.service';
import UsuarioService from '../../../services/Usuario.service';
import FeedbackDrawer from '../../components/FeedbackDrawer';
import { AutoComplete } from '../../components/Form/AutoComplete';
import AutocompleteAsync from '../../components/Form/AutocompleteAsync';
import { Input } from '../../components/Form/Input';
import { PatternInput } from '../../components/Form/PatternInput';
import Loading from '../../components/Loading';
import ModalError from '../../components/ModalError';

interface Props {
  onClose: () => void;
  usuarioDefault: Usuario;
}

const FormCadastrarUsuario = ({ onClose, usuarioDefault }: Props) => {
  const form = useForm<Usuario>({
    defaultValues: usuarioDefault,
    resolver: yupResolver(cadastroPFFormModelSchema)
  });

  const [completed, setCompleted] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const idUsuario = form.getValues('id');
  const telefone = form.watch('telefone') ?? '';
  const telefoneLenght = telefone.replace(/[^\d]+/g, '').length;

  const nomeAgencia = form.watch('nomeAgencia') ?? '';

  const policy = form.watch('policy');
  const usuarioAgente = policy === TIPO_USUARIO_GESTOR.AGENTE;

  const autocompleteAgencia = nomeAgencia ? { nomeAgencia } : null;

  const valoresTipoUsuario = Object.entries(USUARIO_POLICY).map(
    ([key, value]) => ({
      value: value,
      label: key
    })
  );

  const onValid = (data: Usuario) => {
    setLoading(true);
    UsuarioService.criar(data)
      .then(() => {
        setCompleted(true);
      })
      .catch((err) => {
        setErrorMsg(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onEdit = (data: Usuario) => {
    setLoading(true);
    UsuarioService.alterar(idUsuario ?? '', data)
      .then(() => {
        setCompleted(true);
      })
      .catch((err) => {
        setErrorMsg(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCloseModalError = () => {
    setError(false);
  };

  const onInvalid = (err: any) => {
    console.log(err);
  };

  if (completed)
    return (
      <FeedbackDrawer
        titulo={idUsuario ? 'Usuário atualizado!' : 'Usuário adicionado!'}
        subtitulo={`Usuário ${
          idUsuario ? 'atualizado' : 'adicionado'
        } com sucesso!`}
        textoBotao="Fechar"
        onClick={onClose}
        imagemModal={Success}
      />
    );

  if (errorMsg)
    return (
      <FeedbackDrawer
        titulo="Algo deu errado!"
        subtitulo={errorMsg}
        textoBotao="Fechar"
        onClick={onClose}
        imagemModal={AssociarError}
      />
    );

  if (loading) return <Loading show />;

  return (
    <FormProvider {...form}>
      <Box display="flex" flexDirection="column" alignItems="space-between">
        <Grid container spacing={2} padding={3}>
          <Grid item xs={12}>
            <AutoComplete
              control={form.control}
              name="policy"
              label="Tipo de usuário"
              placeholder="*Tipo de usuário"
              options={valoresTipoUsuario}
            />
          </Grid>

          {usuarioAgente && (
            <>
              <Grid item xs={12}>
                <AutocompleteAsync<Agencia, Usuario>
                  control={form.control}
                  name="nomeAgencia"
                  label="Nome da agência"
                  placeholder="*Pesquise pelo nome da agência"
                  isOptionEqualToValue={(o, v) =>
                    o.nomeAgencia === v.nomeAgencia
                  }
                  getOptionLabel={(option) => option.nomeAgencia}
                  onChange={(_e, value) => {
                    form.setValue('nomeAgencia', value?.nomeAgencia);
                  }}
                  load={TotalBusService.buscarAgencia}
                  value={autocompleteAgencia}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  name="matriculaTotalBus"
                  control={form.control}
                  label="Matrícula TotalBus"
                  placeholder="*Matrícula TotalBus"
                  inputProps={{ maxLength: 150 }}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Input
              name="nome"
              control={form.control}
              label="Nome"
              placeholder="*Nome"
              inputProps={{ maxLength: 150 }}
            />
          </Grid>
          <Grid item xs={12}>
            <PatternInput
              label="CPF"
              name="cpf"
              disabled={!!idUsuario}
              control={form.control}
              placeholder="*CPF"
              format="###.###.###-##"
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label="E-mail"
              name="email"
              control={form.control}
              placeholder="*E-mail do usuário"
            />
          </Grid>
          <Grid item xs={12}>
            <PatternInput
              label="Telefone"
              name="telefone"
              control={form.control}
              placeholder="Telefone"
              format={
                telefoneLenght > 10 ? '(##) #####-####' : '(##) ####-#####'
              }
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          spacing={2}
          padding={3}
          alignContent="flex-end"
        >
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              onClick={form.handleSubmit(
                idUsuario ? onEdit : onValid,
                onInvalid
              )}
            >
              <Typography textTransform="none">
                {idUsuario ? 'Atualizar' : 'Cadastrar'}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
      <ModalError
        open={error}
        setOpen={setError}
        message={errorMsg}
        onClose={handleCloseModalError}
      />
    </FormProvider>
  );
};

export default FormCadastrarUsuario;
