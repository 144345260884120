import {
  UsuarioPF,
  UsuarioPJ
} from '@vabembarqueja/embarque-ja-shared-components';
import { addDays } from 'date-fns';

import { APIPaginatedRequest } from '../models/APIPaginatedRequest';
import { APIPaginatedResponse } from '../models/APIPaginatedResponse';
import { APIResponse } from '../models/ApiResponse.model';
import { Cliente } from '../models/Cliente.model';
import { FiltroClientes } from '../models/FiltroClientes.model';
import { FiltroTransacoes } from '../models/FiltroTransacoes.model';
import { LinkPagamento } from '../models/LinkPagamento.model';
import { Transacao } from '../models/Transacao.model';

import Service from './Service';

class ClienteService extends Service {
  static async listar(
    data: APIPaginatedRequest<FiltroClientes>,
    signal?: AbortSignal
  ) {
    return this.Http.post<APIResponse<APIPaginatedResponse<Cliente>>>(
      '/administrativo/clientes/listar',
      data,
      { signal }
    )
      .then(this.getData)
      .then((resp) => resp.data);
  }

  static async cadastrarPF(data: UsuarioPF, signal?: AbortSignal) {
    return this.Http.post('/administrativo/cliente/pessoa-fisica', data, {
      signal
    })
      .then(this.getData)
      .then((resp) => resp.data);
  }

  static async alterarPF(id: string, data: UsuarioPF, signal?: AbortSignal) {
    return this.Http.put(`/administrativo/cliente/pessoa-fisica/${id}`, data, {
      signal
    })
      .then(this.getData)
      .then((resp) => resp.data);
  }

  static async buscarPF(id: string, signal?: AbortSignal) {
    return this.Http.get<APIResponse<UsuarioPF>>(
      `/administrativo/cliente/pessoa-fisica/${id}`,
      {
        signal
      }
    )
      .then(this.getData)
      .then((resp) => resp.data);
  }

  static async buscarPJ(id: string, signal?: AbortSignal) {
    return this.Http.get<APIResponse<UsuarioPJ>>(
      `/administrativo/cliente/pessoa-juridica/${id}`,
      {
        signal
      }
    )
      .then(this.getData)
      .then((resp) => resp.data);
  }

  static async cadastrarPJ(data: UsuarioPJ, signal?: AbortSignal) {
    return this.Http.post('/administrativo/cliente/pessoa-juridica', data, {
      signal
    })
      .then(this.getData)
      .then((resp) => resp.data);
  }

  static async alterarPJ(id: string, data: UsuarioPJ, signal?: AbortSignal) {
    return this.Http.put(
      `/administrativo/cliente/pessoa-juridica/${id}`,
      data,
      {
        signal
      }
    )
      .then(this.getData)
      .then((resp) => resp.data);
  }

  static async listarTransacoes(
    data: APIPaginatedRequest<FiltroTransacoes>,
    signal?: AbortSignal
  ) {
    if ('dataFinal' in data.search && data.search.dataFinal)
      data.search = {
        ...data.search,
        dataFinal: addDays(data.search.dataFinal, 1)
      };

    return this.Http.post<APIResponse<APIPaginatedResponse<Transacao>>>(
      '/administrativo/clientes/transacoes',
      data,
      { signal }
    )
      .then(this.getData)
      .then((resp) => resp.data);
  }

  static async criarLinkPagamento(data: LinkPagamento, signal?: AbortSignal) {
    return this.Http.post<
      APIResponse<{
        autorizado: boolean;
        idLinkPagamento: string;
        mensagem: string;
      }>
    >('/administrativo/clientes/linkpagamento', data, { signal })
      .then(this.getData)
      .then((resp) => resp.data);
  }

  static async downloadTransacoes(
    data: FiltroTransacoes,
    signal?: AbortSignal
  ) {
    if ('dataFinal' in data && data.dataFinal)
      data.dataFinal = addDays(data.dataFinal, 1);

    return this.Http.post<string>(
      '/administrativo/clientes/transacoes/download',
      data,
      { signal }
    ).then(this.getData);
  }
}

export default ClienteService;
