import styled from 'styled-components';

import { Colors } from '../../../shared/colors';

interface Props {
  $bgColor?: string;
  $fullscreen?: boolean;
}

export const LoadingWrapper = styled.div<Props>`
  position: ${({ $fullscreen = false }) =>
    $fullscreen ? 'fixed' : 'relative'};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ $bgColor = 'whitesmoke' }) => $bgColor};
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  animation: 0.25s pop ease;
  @keyframes pop {
    from {
      background-color: ${Colors.white};
      opacity: 0;
    }
    to {
      background-color: ${({ $bgColor = 'whitesmoke' }) => $bgColor};
      opacity: 1;
    }
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: ${`8px solid ${Colors.primary.main}`};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${`${Colors.primary.main} transparent transparent transparent`};
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
