import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import {
  FiltroAssociarCartao,
  FiltroAssociarCartaoFormModelSchema,
  FILTRO_ASSOCIAR_CARTAO_DEFAULT
} from '../../../models/FiltroAssociarCartao';
import { Input } from '../../components/Form/Input';
import { NumericInput } from '../../components/Form/NumericInput';
import { PatternInput } from '../../components/Form/PatternInput';
interface Props {
  onClose: (filtroTransacoes: FiltroAssociarCartao) => void;
  onChangeFilter: (filtroTransacoes: FiltroAssociarCartao) => void;
  filtroAssociarCartao: FiltroAssociarCartao;
  permitirFiltrarUsuarios?: boolean;
}

const FiltrarAssociarCartoes = ({
  filtroAssociarCartao,
  onClose,
  onChangeFilter
}: Props) => {
  const form = useForm<FiltroAssociarCartao>({
    defaultValues: filtroAssociarCartao ?? FILTRO_ASSOCIAR_CARTAO_DEFAULT,
    resolver: yupResolver(FiltroAssociarCartaoFormModelSchema)
  });

  const filtrarTransacoes = (filtros: FiltroAssociarCartao) => {
    const filtroSemValoresVazios = Object.fromEntries(
      Object.entries(filtros).filter(([_, v]) => v)
    );
    onClose(filtroSemValoresVazios);
  };

  const limparFiltros = () => {
    onChangeFilter(FILTRO_ASSOCIAR_CARTAO_DEFAULT);
    form.reset(FILTRO_ASSOCIAR_CARTAO_DEFAULT);
  };

  return (
    <FormProvider {...form}>
      <Box display="flex" flexDirection="column" alignItems="space-between">
        <Grid container spacing={2} padding={3}>
          <Grid item xs={12}>
            <Input
              name="nome"
              control={form.control}
              label="Cliente PF"
              placeholder="Nome do cliente"
            />
          </Grid>
          <Grid item xs={12}>
            <PatternInput
              name="cpf"
              control={form.control}
              label="CPF"
              placeholder="CPF"
              format="###.###.###-##"
            />
          </Grid>

          <Grid item xs={12}>
            <NumericInput
              name="numeroCartao"
              control={form.control}
              label="Número de cartão"
              placeholder="Número de cartão"
              inputProps={{ maxLength: 12 }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          spacing={2}
          padding={3}
          alignContent="flex-end"
        >
          <Grid item xs={12}>
            <Button fullWidth variant="outlined" onClick={limparFiltros}>
              <Typography textTransform="none">Limpar filtros</Typography>
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              onClick={form.handleSubmit(filtrarTransacoes, console.log)}
            >
              <Typography textTransform="none">Filtrar</Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
};

export default FiltrarAssociarCartoes;
