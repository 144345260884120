import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import {
  FILTRO_CLIENTE,
  FILTRO_CLIENTE_DEFAULT,
  FILTRO_SITUACAO,
  FiltroClientes,
  FiltroClientesFormModelSchema
} from '../../../models/FiltroClientes.model';
import { FormSwitch } from '../../components/Form/FormSwitch';
import { Input } from '../../components/Form/Input';
import { NumericInput } from '../../components/Form/NumericInput';
import { PatternInput } from '../../components/Form/PatternInput';
import { RadioInput } from '../../components/Form/RadioInput';

interface Props {
  onClose: (filtroClientes: FiltroClientes) => void;
  onChangeFilter: (filtroClientes: FiltroClientes) => void;
  filtroClientes: FiltroClientes;
}

const FiltrarClientes = ({
  filtroClientes,
  onClose,
  onChangeFilter
}: Props) => {
  const form = useForm<FiltroClientes>({
    defaultValues: filtroClientes ?? FILTRO_CLIENTE_DEFAULT,
    resolver: yupResolver(FiltroClientesFormModelSchema)
  });

  const cpfCnpj = form.watch('cpfCnpj') ?? '';
  const documentoLenght = cpfCnpj.replace(/[^\d]+/g, '').length;

  const valoresSelectTipo = Object.entries(FILTRO_CLIENTE).map(
    ([key, value]) => ({
      value: value,
      label: key
    })
  );

  const valoresSelectSituacao = Object.entries(FILTRO_SITUACAO).map(
    ([key, value]) => ({
      value: value,
      label: key
    })
  );

  const filtrarClientes = (filtros: FiltroClientes) => {
    const filtroSemValoresVazios = Object.fromEntries(
      Object.entries(filtros).filter(([_, v]) => v)
    );
    onClose(filtroSemValoresVazios);
  };

  const limparFiltros = () => {
    onChangeFilter(FILTRO_CLIENTE_DEFAULT);
    form.reset(FILTRO_CLIENTE_DEFAULT);
  };
  return (
    <FormProvider {...form}>
      <Box display="flex" flexDirection="column" alignItems="space-between">
        <Grid container spacing={2} padding={3}>
          <Grid item xs={12}>
            <NumericInput
              name="idClienteTotalBus"
              control={form.control}
              label="Codigo"
              placeholder="Codigo"
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name="nome"
              control={form.control}
              label="Cliente"
              placeholder="Nome do cliente"
            />
          </Grid>

          <Grid item xs={12}>
            <PatternInput
              name="cpfCnpj"
              control={form.control}
              label="CPF/CNPJ"
              placeholder="CPF/CNPJ"
              format={
                documentoLenght > 11 ? '##.###.###/####-##' : '###.###.###-###'
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormSwitch
              control={form.control}
              name="apenasSaldoNegativo"
              label="Apenas saldo negativo"
            />
          </Grid>
          <Grid item xs={6}>
            <RadioInput
              name="tipoUsuario"
              control={form.control}
              label="Tipo"
              placeholder="Tipo"
              inputProps={{ maxLength: 150 }}
              values={valoresSelectTipo}
            />
          </Grid>
          <Grid item xs={6}>
            <RadioInput
              name="situacao"
              control={form.control}
              label="Situação"
              placeholder="Situação"
              inputProps={{ maxLength: 150 }}
              values={valoresSelectSituacao}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          spacing={2}
          padding={3}
          alignContent="flex-end"
        >
          <Grid item xs={12}>
            <Button fullWidth variant="outlined" onClick={limparFiltros}>
              <Typography textTransform="none">Limpar filtros</Typography>
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              onClick={form.handleSubmit(filtrarClientes, console.log)}
            >
              <Typography textTransform="none">Filtrar</Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
};

export default FiltrarClientes;
