import { Grid, Typography } from '@mui/material';

import PageNotFound from '../../assets/page-not-found.svg';
import { Colors } from '../../shared/colors';

const NotFound = () => {
  return (
    <Grid container p={{ xs: 2, md: 5 }} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h6" color={Colors.gray1} textAlign="center">
          Página não encontrada
        </Typography>
      </Grid>
      <Grid item mt={8} maxWidth="sm" xs={12} md={8}>
        <img src={PageNotFound} alt="not-found" />
      </Grid>
    </Grid>
  );
};

export default NotFound;
