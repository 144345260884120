import { Box, Typography } from '@mui/material';

interface Props {
  descricao: string;
  imagem?: string;
}

const Step = ({ descricao, imagem }: Props) => {
  return (
    <Box px={{ sx: 1, md: 2, lg: 3 }} mb={2} width={1}>
      <Typography my={2} variant="h6">
        {descricao}
      </Typography>

      {Boolean(imagem) && <img width="100%" alt="doc scribe" src={imagem} />}
    </Box>
  );
};

export default Step;
