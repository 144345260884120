import { Box } from '@mui/material';

import { BASE_CONFIG } from '../../shared/baseConfig';
import Instruction from '../components/Instruction.doc';
import Step from '../components/Step.doc';

export const ImportarCartoes = () => (
  <Instruction titulo="Importar arquivo com cartões gerados no EmbarqueJá">
    <Box display="flex" flexDirection="column" alignItems="center">
      <Step descricao={`1. Navegue para ${BASE_CONFIG.App.BaseUrl}`} />
      <Step
        descricao='2. Clique em "Associação de cartões"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-30/8c065b38-bae3-4388-9848-59173486435f/ascreenshot.jpeg?tl_px=246,0&br_px=992,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,65"
      />
      <Step
        descricao='3. Clique em "Gerar / Importar"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-30/d53beaa9-efa2-43fb-9dbc-58ac99287263/ascreenshot.jpeg?tl_px=980,0&br_px=1726,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,102"
      />
      <Step
        descricao="4. Clique no ícone para importar."
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-30/5e410dff-ff89-41ca-89ca-c5e346cb5a13/ascreenshot.jpeg?tl_px=1173,214&br_px=1919,634&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=286,139"
      />
      <Step
        descricao='5. Clique em "Importar"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-30/59b4e27e-87b4-411e-a296-6b86a7f4eb82/ascreenshot.jpeg?tl_px=1173,318&br_px=1919,738&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=342,139"
      />
      <Step
        descricao='6. Clique em "Fechar"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-30/ed755b89-447f-4a91-9a07-73cdb6b700ac/ascreenshot.jpeg?tl_px=1173,509&br_px=1919,929&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=274,262"
      />
    </Box>
  </Instruction>
);
