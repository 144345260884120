import { Box } from '@mui/material';

import { BASE_CONFIG } from '../../shared/baseConfig';
import Alert from '../components/Alert.doc';
import Instruction from '../components/Instruction.doc';
import Step from '../components/Step.doc';
import Tip from '../components/Tip.doc';

export const CriarUsuarioPF = () => (
  <Instruction titulo="Cadastro Usuário Pessoa Física no EmbarqueJá">
    <Box display="flex" flexDirection="column" alignItems="center">
      <Step descricao={`1. Navegue para ${BASE_CONFIG.App.ClienteUrl}`} />
      <Step
        descricao='2. Clique em "Cadastrar"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-19/e81d8b31-b1de-4275-a616-148d79dc7573/ascreenshot.jpeg?tl_px=502,448&br_px=1362,929&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,234"
      />
      <Step
        descricao='3. Clique em "Selecionar" na caixa de Pessoa Física'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-19/dd687618-8eb6-47ed-b535-c39e6524bcda/ascreenshot.jpeg?tl_px=357,366&br_px=1217,847&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Tip descricao="Tip: Preencha suas informações de acordo com o nome do campo." />
      <Step
        descricao='4. Clique no campo "*Nome completo" e preencha-o'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-19/fb4db6c7-c648-4f57-b90a-ed67e3e8cfac/ascreenshot.jpeg?tl_px=35,0&br_px=895,480&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,181"
      />
      <Step
        descricao='5. Clique no campo "*CPF" e preencha-o'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-19/83a81a47-8e75-4cab-88d0-0fb2739e7721/ascreenshot.jpeg?tl_px=381,0&br_px=1241,480&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,162"
      />
      <Step
        descricao='6. Clique no campo "*RG" e preencha-o'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-19/45ff9e6f-57e8-49b9-baf4-f68b531f309f/ascreenshot.jpeg?tl_px=814,0&br_px=1674,480&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,184"
      />
      <Step
        descricao='7. Clique no campo "Data de nascimento" e preencha-o'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-19/25f26160-548d-40e8-a90b-2a87ce5cd9c6/ascreenshot.jpeg?tl_px=90,45&br_px=949,526&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Step
        descricao='8. Clique no campo "*E-mail" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-19/9a9ecfdb-6a19-44b9-865d-944e1dc2a252/ascreenshot.jpeg?tl_px=415,42&br_px=1275,523&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Alert descricao="Alert: Atenção, este e-mail será responsável por toda a comunicação referente ao seu acesso a plataforma (primeira senha, recuperação de senha, etc.)" />
      <Step
        descricao='9. Clique no campo "*Celular (Recebe SMS)" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-19/70753378-41a7-4e30-b458-65bb73f884ce/ascreenshot.jpeg?tl_px=791,47&br_px=1651,528&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Alert descricao="Alert: Atenção, cadastre um celular ativo que receba SMS, pois será usado para envio de token para utilização de certas funcionalidades no EmbarqueJá." />
      <Step
        descricao='10. Clique no campo "CEP" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-19/569ce2f4-4959-46e4-9e0f-00e6019406a0/ascreenshot.jpeg?tl_px=57,198&br_px=916,679&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Tip descricao='Tip: Caso o "CEP" seja encontrado, os campo "Estado", "Cidade", "Bairro" e "Rua" serão preenchidos automaticamente.' />
      <Step
        descricao='11. Caso preenchido automaticamente, verifique o campo "Estado".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/5f42c76f-3c68-4cb0-9e33-072b722a5367/ascreenshot.jpeg?tl_px=580,184&br_px=1440,665&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Step
        descricao='12. Caso preenchido automaticamente, verifique o campo "Cidade".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/ebdd5638-2e36-4a3b-baf4-f0383334e5fa/ascreenshot.jpeg?tl_px=851,194&br_px=1711,675&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Step
        descricao='13. Caso preenchido automaticamente, verifique o campo "Bairro".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/c7755902-c1c6-473e-a3d9-81571b5a7abf/ascreenshot.jpeg?tl_px=278,294&br_px=1138,775&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Step
        descricao='14. Caso preenchido automaticamente, verifique o campo "Rua".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/78284179-90df-46ad-89e5-42c9f3b889bb/ascreenshot.jpeg?tl_px=566,300&br_px=1426,781&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Step
        descricao='15. Clique o preencha o campo "Número".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/0754d92c-4be3-4a4e-ba6a-5f5e59d130db/ascreenshot.jpeg?tl_px=1015,306&br_px=1875,787&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Step
        descricao='16. Clique e preencha o campo "Complemento".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/2e58ec38-bda4-4ef1-8bf5-abc1c17d7039/ascreenshot.jpeg?tl_px=228,415&br_px=1088,896&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Step
        descricao='17. Clique em "Realizar cadastro" para finalizar.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-19/f35b433b-af4b-4e74-9803-0cf68651cf18/ascreenshot.jpeg?tl_px=903,448&br_px=1763,929&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,225"
      />

      <Tip descricao='Tip: Ao final do cadastro, um e-mail será enviado com os dados de acesso a serem utilizados para acessar a plataforma. Utilize-os para realizar o login, os passos estão disponibilizados na seção "Acessando a plataforma Embarque Já".' />
    </Box>
  </Instruction>
);
