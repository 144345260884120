import {
  mdiCashClock,
  mdiCashMinus,
  mdiCashRefund,
  mdiCurrencyUsd
} from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Fab, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';

import { ReactComponent as CreditCard } from '../../assets/credit-card.svg';
import { ReactComponent as DebitCard } from '../../assets/debit-card.svg';
import { ReactComponent as PicPay } from '../../assets/picpay-logo.svg';
import { ReactComponent as Pix } from '../../assets/pix-logo.svg';
import { TipoPagamento } from '../../models/TipoPagamento.model';
import {
  TIPO_TRANSACAO,
  TipoTransacao,
  Transacao
} from '../../models/Transacao.model';
import { Colors } from '../../shared/colors';

import Currency from './Currency';

interface Props {
  transacao: Transacao;
  onClick?: () => void;
}

interface IconeProps {
  tipo: TipoTransacao;
  tipoPagamento: TipoPagamento;
}

const Icone = ({ tipo, tipoPagamento }: IconeProps) => {
  const getIcon = (tipo: TipoTransacao, _tipoPagamento: TipoPagamento) => {
    if ('Compra de passagem' === TIPO_TRANSACAO[tipo]) return mdiCashMinus;

    if ('Transferência enviada' === TIPO_TRANSACAO[tipo]) return mdiCashMinus;

    if ('Transferência estornada' === TIPO_TRANSACAO[tipo])
      return mdiCashRefund;

    return mdiCurrencyUsd;
  };

  switch (tipoPagamento) {
    case TipoPagamento.CartaoCredito:
      return <CreditCard />;
    case TipoPagamento.CartaoDebito:
      return <DebitCard />;
    case TipoPagamento.Pix:
      return <Pix />;
    case TipoPagamento.PicPay:
      return <PicPay />;
    case TipoPagamento.PJPosPago:
      return <Icon size={1} color={Colors.primary.main} path={mdiCashClock} />;

    default:
      return (
        <Icon
          size={1}
          color={Colors.primary.main}
          path={getIcon(tipo, tipoPagamento)}
        />
      );
  }
};

const CardTransacao = ({ transacao, onClick }: Props) => {
  const documentoCpf =
    transacao.origemDestinoCpfCnpj &&
    transacao.origemDestinoCpfCnpj.length === 11;

  const getCurrencyColor = (tipo: TipoTransacao) => {
    if ('Compra de passagem' === TIPO_TRANSACAO[tipo]) return Colors.error.red;

    if ('Transferência enviada' === TIPO_TRANSACAO[tipo])
      return Colors.error.red;

    if ('Transferência estornada' === TIPO_TRANSACAO[tipo] && documentoCpf)
      return Colors.error.red;

    return Colors.primary.dark;
  };

  return (
    <Grid
      container
      border={1}
      paddingX={2}
      marginBottom={2}
      paddingY={1.5}
      borderRadius={1}
      display="flex"
      sx={{ borderColor: Colors.primary.main }}
    >
      <Grid item container xs={12}>
        <Grid item>
          <Fab
            size="small"
            sx={{
              border: 1,
              borderColor: Colors.primary.main,
              bgcolor: Colors.primary.light
            }}
          >
            <Box display="flex" alignItems="center" p={1}>
              <Icone
                tipo={transacao.tipoTransacao}
                tipoPagamento={transacao.tipoPagamento}
              />
            </Box>
          </Fab>
        </Grid>
        <Grid ml={2} item container flex={1} display="flex">
          <Grid item container xs={10} sm={5} display="flex" gap={2}>
            <Grid item display="flex" flexDirection="column">
              <Box display="flex">
                <Typography
                  variant="body1"
                  color={Colors.gray1}
                  lineHeight={1.5}
                  whiteSpace="nowrap"
                >
                  {transacao.tipoTransacaoDescricao}
                </Typography>
              </Box>
              <Typography variant="caption" color={Colors.gray1}>
                Autorização: {transacao.idAutorizacao}
              </Typography>
              {transacao.bilhetes && transacao.bilhetes.length > 0 && (
                <Box sx={{ cursor: 'pointer' }} onClick={onClick}>
                  <Typography variant="caption" color={Colors.primary.dark}>
                    Ver detalhes
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>

          <Grid item container xs={12} sm={5} mt={{ xs: 1, md: 0 }}>
            <Grid item xs={9} container display="flex" flexDirection="column">
              <Typography variant="body1" color={Colors.gray1} lineHeight={1.5}>
                {transacao.origemDestinoNome}
              </Typography>
              {transacao.origemDestinoCpfCnpj && (
                <Typography variant="caption" color={Colors.gray1}>
                  {transacao.origemDestinoCpfCnpj.length > 15
                    ? 'CNPJ: '
                    : 'CPF: '}
                  {transacao.origemDestinoCpfCnpj}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={2}
            mt={{ xs: 1, md: 0 }}
            flexShrink={1}
            display="flex"
            flexDirection="column"
            alignItems={{ md: 'flex-end' }}
          >
            <Typography
              fontWeight="bold"
              color={getCurrencyColor(transacao.tipoTransacao)}
              sx={{ fontSize: { xs: '1rem', sm: '1.1rem' } }}
            >
              <Currency value={transacao.valor} />
            </Typography>
            <Typography
              variant="caption"
              color={Colors.gray1}
              textAlign={{ md: 'right' }}
              variantMapping={{ caption: 'p' }}
              sx={{ textAlignLast: { md: 'right' } }}
            >
              {format(new Date(transacao.dataHora ?? ''), 'dd/MM/yyyy HH:mm')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CardTransacao;
