import { APIPaginatedRequest } from '../models/APIPaginatedRequest';
import { APIPaginatedResponse } from '../models/APIPaginatedResponse';
import { APIResponse } from '../models/ApiResponse.model';
import { ClienteDetalhe } from '../models/Cliente.model';
import { FiltroUsuario } from '../models/FiltroUsuario.model';
import { Usuario } from '../models/Usuario.model';

import Service from './Service';

class UsuarioService extends Service {
  static async listar(
    data: APIPaginatedRequest<FiltroUsuario>,
    signal?: AbortSignal
  ) {
    return this.Http.post<APIResponse<APIPaginatedResponse<Usuario>>>(
      '/administrativo/usuarios/listar',
      data,
      { signal }
    )
      .then(this.getData)
      .then((resp) => resp.data);
  }

  static async isUsuarioAtivo() {
    return this.Http.get<APIResponse<Usuario>>('/administrativo/ativo')
      .then(this.getData)
      .then((resp) => resp.data);
  }

  static async criar(data: Usuario) {
    return this.Http.post('/administrativo/usuario', data);
  }

  static async alterar(id: string, data: Usuario) {
    return this.Http.put(`/administrativo/usuario/${id}`, data);
  }

  static async alterarStatus(data: { id: string; ativo: boolean }) {
    return this.Http.patch(`/administrativo/usuario/status`, data);
  }

  static async aprovarMigracaoPosPago(
    usuarioPjId: string,
    idClienteTotalBus: string
  ) {
    return this.Http.put('/administrativo/usuario/avaliar-migracao-pos-pago', {
      aprovado: true,
      usuarioPjId,
      idClienteTotalBus
    });
  }

  static async reprovarMigracaoPosPago(
    usuarioPjId: string,
    idClienteTotalBus: string
  ) {
    return this.Http.put('/administrativo/usuario/avaliar-migracao-pos-pago', {
      aprovado: false,
      usuarioPjId,
      idClienteTotalBus
    });
  }

  static async alterarMigracaoPosPago(
    usuarioId: string,
    posPagamentoAtivo: boolean
  ) {
    return this.Http.put('/administrativo/usuario/alterar-status', {
      usuarioId,
      posPagamentoAtivo
    });
  }

  static async alterarInadiplencia(
    usuarioId: string,
    InadimplenteAtivo: boolean
  ) {
    return this.Http.put('/administrativo/usuario/alterar-inadimplente', {
      usuarioId,
      InadimplenteAtivo
    });
  }

  static async alterarBloqueio(
    usuarioId: string,
    alterarStatusBloqueio: boolean,
    justificativaBloqueio?: string
  ) {
    return this.Http.put('/administrativo/usuario/alterar-bloqueio', {
      usuarioId,
      alterarStatusBloqueio,
      justificativaBloqueio
    });
  }

  static async getDetalhesPJ(usuarioPjId: string) {
    return this.Http.get<APIResponse<ClienteDetalhe>>(
      `/usuario/pessoa-juridica/${usuarioPjId}`
    )
      .then(this.getData)
      .then((resp) => resp.data);
  }
}

export default UsuarioService;
