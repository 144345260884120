import { mdiFilterVariant, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Skeleton,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUserContext } from '../../contexts/User.context';
import { Cliente } from '../../models/Cliente.model';
import { FiltroClientes } from '../../models/FiltroClientes.model';
import { Routes } from '../../routes/index.routes';
import ClienteService from '../../services/Cliente.service';
import { Colors } from '../../shared/colors';
import useInfiniteScroll from '../../shared/hooks/infiniteScroll';
import CardCliente from '../components/CardCliente';
import DrawerAprovarMigracao from '../features/aprovar-migracao/DrawerAprovarMigracao';
import DrawerFiltrarCliente from '../features/filtrar-clientes/DrawerFiltrarClientes';

const GestaoClientes = () => {
  const [cliente, setCliente] = useState<Cliente | null>(null);
  const [openDrawerFiltroUsuario, setOpenDrawerFiltroUsuario] = useState(false);
  const [filtroUsuarios, setFiltroUsuarios] = useState<FiltroClientes>();

  const { userClaim } = useUserContext();
  const admin = userClaim === 'Administrativo';

  const navigate = useNavigate();

  const closeDrawerFiltro = (Filtro: FiltroClientes) => {
    setOpenDrawerFiltroUsuario(false);
    setFiltroUsuarios(Filtro);
  };

  const onClickEdit = (cliente: Cliente) => {
    if (cliente.cpfCnpj.length === 14) {
      navigate(`${Routes.Cadastro.Pf}/${cliente.id} `);
    } else if (cliente.cpfCnpj.length === 18) {
      navigate(`${Routes.Cadastro.Pj}/${cliente.id} `);
    }
  };

  const { loading, items, lastElementRef, hasNext } = useInfiniteScroll<
    Cliente,
    FiltroClientes
  >({
    load: ClienteService.listar,
    search: filtroUsuarios
  });

  const carregandoItens = (loading && hasNext) || loading;

  return (
    <Grid container spacing={2} paddingY={2}>
      <Grid
        item
        container
        xs={12}
        spacing={2}
        alignItems="center"
        display="flex"
      >
        <Grid item xs={8} sm={6}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" color={Colors.secondary} fontWeight={700}>
              Gestão de clientes
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
          sm={6}
          gap={2}
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          <Box display={{ xs: 'none', sm: 'block' }}>
            <Button
              fullWidth
              variant="outlined"
              sx={{ height: 35 }}
              onClick={() => navigate(Routes.Cadastro.Principal)}
            >
              <Typography textTransform="none">Cadastrar usuário</Typography>
            </Button>
          </Box>
          <Box
            display="flex"
            onClick={() => setOpenDrawerFiltroUsuario(true)}
            sx={{ cursor: 'pointer' }}
            gap={1}
          >
            <Typography
              fontWeight="bold"
              color={Colors.primary.main}
              display={{ xs: 'none', sm: 'block' }}
            >
              Filtrar
            </Typography>
            <Icon
              path={mdiFilterVariant}
              size={1}
              color={Colors.primary.main}
            />
          </Box>
          <Box
            display={{ sm: 'none' }}
            onClick={() => navigate(Routes.Cadastro.Principal)}
          >
            <IconButton color="secondary">
              <Icon path={mdiPlus} size={1} />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {items.map((cliente, index, arr) => {
          const lastElement = arr.length === index + 1;
          const ref = lastElement && hasNext ? lastElementRef : null;
          return (
            <div ref={ref} key={cliente.id}>
              <CardCliente
                infoContaCliente={
                  <CardCliente.DetalheCliente cliente={cliente} />
                }
                infoContaBloqueada={
                  <CardCliente.BloqueioCliente cliente={cliente} />
                }
                infoSaldoCliente={
                  <CardCliente.SaldoCliente cliente={cliente} />
                }
                onClickEdit={() => onClickEdit(cliente)}
              >
                <CardCliente.MigracaoClientePos
                  onClick={() => setCliente(cliente)}
                  cliente={cliente}
                />
                <CardCliente.ClientePosPago
                  cliente={cliente}
                  disabled={!admin}
                />
              </CardCliente>
            </div>
          );
        })}
      </Grid>

      {carregandoItens && (
        <Grid container gap={2} ml={2}>
          {Array(5)
            .fill(1)
            .map((_item, index) => index)
            .map((value) => (
              <Skeleton
                key={value}
                variant="rounded"
                width="100%"
                height={70}
              />
            ))}
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography
          mt={3}
          variant="body1"
          fontWeight={500}
          color={Colors.gray1}
          textAlign="center"
        >
          {!hasNext && !loading && 'Não há dados a serem exibidos'}
        </Typography>
      </Grid>

      <DrawerAprovarMigracao
        cliente={cliente}
        open={Boolean(cliente)}
        onClose={() => setCliente(null)}
      />

      <DrawerFiltrarCliente
        open={openDrawerFiltroUsuario}
        onClose={closeDrawerFiltro}
      />
    </Grid>
  );
};

export default GestaoClientes;
