import { Box, Typography } from '@mui/material';
import {
  CadastroPJ,
  Loading,
  ModalEditSuccess,
  ModalError,
  ModalSuccess,
  UsuarioPJ
} from '@vabembarqueja/embarque-ja-shared-components';
import { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useUserContext } from '../../../contexts/User.context';
import { Routes } from '../../../routes/index.routes';
import ClienteService from '../../../services/Cliente.service';
import { Colors } from '../../../shared/colors';
import { BloquearUsuario } from '../../components/BloquearUsuario';

const CadastroPJController = () => {
  const { id } = useParams<{ id?: string }>();
  const [usuario, setUsuario] = useState<UsuarioPJ>();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const { userClaim } = useUserContext();
  const admin = userClaim === 'Administrativo';

  const navigate = useNavigate();

  const onValid: SubmitHandler<UsuarioPJ> = (data) => {
    setLoading(true);
    setErrorMessage('');
    if (id)
      ClienteService.alterarPJ(id, data)
        .then(() => setOpen(true))
        .catch((err: Error) => {
          setError(true);
          setErrorMessage(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    else
      ClienteService.cadastrarPJ(data)
        .then(() => setOpen(true))
        .catch((err: Error) => {
          setError(true);
          setErrorMessage(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
  };

  const handleCloseModalSuccess = () => {
    setOpen(false);
    navigateToHome();
  };

  const handleCloseModalError = () => {
    setError(false);
  };

  const navigateToHome = () => {
    navigate(Routes.gestaoClientes.Principal, { replace: true });
  };

  useEffect(() => {
    if (!id) {
      setLoading(false);
      return;
    }

    setLoading(true);
    ClienteService.buscarPJ(id)
      .then(setUsuario)
      .catch(() => setUsuario(undefined))
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) return <Loading show fullscreen />;
  if (id && !usuario) throw new Error('Usuário não encontrado');

  return (
    <>
      {usuario?.justificativaBloqueio && usuario.statusBloqueio ? (
        <Box
          mt={2}
          display="flex"
          justifyContent="center"
          bgcolor={Colors.error.red10}
          paddingY={1.5}
        >
          <Typography
            color={Colors.error.dark}
            fontStyle="inherit"
            fontWeight={400}
            fontSize={16}
          >
            Usuário bloqueado:
          </Typography>
          <Typography color={Colors.error.dark} ml={1}>
            &quot;{usuario.justificativaBloqueio}&quot;
          </Typography>
        </Box>
      ) : null}

      <CadastroPJ
        actions={
          id && usuario ? (
            <BloquearUsuario
              usuario={{
                id: id,
                nome: usuario.razaoSocial,
                cpfCnpj: usuario.cnpj,
                bloqueado: usuario.statusBloqueio
              }}
              disabled={!admin}
              onChangeStatus={handleCloseModalSuccess}
            />
          ) : null
        }
        admin
        usuario={usuario}
        loading={loading}
        cadastrarUsuario={onValid}
        onClickVoltar={navigateToHome}
        disabled={!admin}
      />

      <ModalSuccess
        open={!usuario && open}
        setOpen={setOpen}
        onClose={handleCloseModalSuccess}
      />
      <ModalEditSuccess
        open={!!usuario && open}
        setOpen={setOpen}
        onClose={handleCloseModalSuccess}
      />
      <ModalError
        open={error}
        setOpen={setError}
        message={errorMessage}
        onClose={handleCloseModalError}
      />
    </>
  );
};

export default CadastroPJController;
