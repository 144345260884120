import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import Associar from '../../../assets/associar-cartao.svg';
import AssociarError from '../../../assets/associar-error.svg';
import {
  AssociarCartao,
  AssociarCartaoFormModelSchema,
  ASSOCIAR_CARTAO_DEFAULT
} from '../../../models/AssociarCartao.model';
import CartaoService from '../../../services/Cartao.service';
import { Colors } from '../../../shared/colors';
import FeedbackDrawer from '../../components/FeedbackDrawer';
import { PatternInput } from '../../components/Form/PatternInput';
import Loading from '../../components/Loading';

interface Props {
  associarCartao: AssociarCartao;
  onClose: () => void;
}

const FormAssociarCartao = ({ associarCartao, onClose }: Props) => {
  const [errorMSg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const form = useForm<AssociarCartao>({
    defaultValues: associarCartao ?? ASSOCIAR_CARTAO_DEFAULT,
    resolver: yupResolver(AssociarCartaoFormModelSchema)
  });

  const submitForm: SubmitHandler<AssociarCartao> = (data) => {
    CartaoService.associar(data)
      .then(() => setCompleted(true))
      .catch((err) => {
        setErrorMsg(err.message);
      })
      .finally(() => setLoading(false));
  };

  if (completed)
    return (
      <FeedbackDrawer
        titulo="Cartão associado!"
        subtitulo="Cartão associado com sucesso!"
        textoBotao="Fechar"
        onClick={onClose}
        imagemModal={Associar}
      />
    );

  if (errorMSg)
    return (
      <FeedbackDrawer
        titulo="Erro ao associar!"
        subtitulo={errorMSg}
        textoBotao="Fechar"
        onClick={onClose}
        imagemModal={AssociarError}
      />
    );

  if (loading) return <Loading show={loading} />;

  return (
    <FormProvider {...form}>
      <Box
        width={1}
        display="flex"
        flexDirection="column"
        alignItems="space-between"
      >
        <Grid container spacing={2} padding={3}>
          <Grid item xs={12}>
            <Typography variant="h6" color={Colors.secondary}>
              Deseja associar esse cartão?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <PatternInput
              name="numeroCartao"
              control={form.control}
              label="Número do cartão"
              placeholder="Número do cartão"
              format="############"
            />
          </Grid>

          {/* <Grid item xs={12}>
            <Input
              name="serieCartao"
              control={form.control}
              label="Série do cartão"
              inputProps={{ maxLength: 10 }}
              placeholder="Série do cartão"
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name="subserieCartao"
              control={form.control}
              inputProps={{ maxLength: 10 }}
              label="Subsérie do cartão"
              placeholder="Subsérie do cartão"
            />
          </Grid> */}
        </Grid>
        <Grid
          item
          xs={12}
          container
          spacing={2}
          padding={3}
          alignContent="flex-end"
        >
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              onClick={form.handleSubmit(submitForm)}
            >
              <Typography textTransform="none">Confirmar associação</Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
};
export default FormAssociarCartao;
