import { mdiCash, mdiCashOff } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Grid, Typography } from '@mui/material';
import { MouseEventHandler } from 'react';

import { Cliente } from '../../../models/Cliente.model';
import { Colors } from '../../../shared/colors';

interface Props {
  onClick: MouseEventHandler<HTMLDivElement>;

  cliente: Cliente;
}

export const CargaDistancia = ({ onClick, cliente }: Props) => {
  return (
    <Grid item>
      {cliente.saldoBloqueado ? (
        <Box
          display="flex"
          alignItems="center"
          borderColor={Colors.primary.main}
          gap={1}
        >
          <Typography
            fontWeight="bold"
            color={Colors.error.dark}
            sx={{ fontSize: { xs: '1rem', sm: '1.1rem' } }}
          >
            Saldo Bloqueado
          </Typography>
          <Icon path={mdiCashOff} size={1} color={Colors.error.dark} />
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          borderColor={Colors.primary.main}
          onClick={onClick}
          sx={{ cursor: 'pointer' }}
          gap={1}
        >
          <Typography
            fontWeight="bold"
            color="primary"
            sx={{ fontSize: { xs: '1rem', sm: '1.1rem' } }}
          >
            Adicionar saldo
          </Typography>
          <Icon path={mdiCash} size={1} color={Colors.primary.main} />
        </Box>
      )}
    </Grid>
  );
};
