import {
  Box,
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { PatternFormat } from 'react-number-format';

import AssociarError from '../../../assets/associar-error.svg';
import Success from '../../../assets/success.svg';
import { ClienteDetalhe } from '../../../models/Cliente.model';
import UsuarioService from '../../../services/Usuario.service';
import { Colors } from '../../../shared/colors';
import FeedbackDrawer from '../../components/FeedbackDrawer';
import Loading from '../../components/Loading';

interface Props {
  idCliente: string;
  onClose: () => void;
}

const FormAprovarMigracao = ({ idCliente, onClose }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [cliente, setCliente] = useState<ClienteDetalhe>();
  const [codigoEmpresa, setCodigoEmpresa] = useState('');
  const [errorMSg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const aprovar = () => {
    setLoading(true);
    if (inputRef.current)
      UsuarioService.aprovarMigracaoPosPago(idCliente, inputRef.current.value)
        .then(() => setCompleted(true))
        .catch((err) => {
          setErrorMsg(err.message);
        })
        .finally(() => setLoading(false));
  };

  const reprovar = () => {
    setLoading(true);
    if (inputRef.current)
      UsuarioService.reprovarMigracaoPosPago(idCliente, inputRef.current.value)
        .then(() => setCompleted(true))
        .catch((err) => {
          setErrorMsg(err.message);
        })
        .finally(() => setLoading(false));
  };

  useEffect(() => {
    UsuarioService.getDetalhesPJ(idCliente).then(setCliente);
  }, [idCliente]);

  if (!cliente || loading) return <Loading show />;

  if (completed)
    return (
      <FeedbackDrawer
        titulo="Plano atualizado!"
        subtitulo="Migração de plano realizada com sucesso!"
        textoBotao="Fechar"
        onClick={onClose}
        imagemModal={Success}
      />
    );

  if (errorMSg)
    return (
      <FeedbackDrawer
        titulo="Erro ao realizar migração!"
        subtitulo={errorMSg}
        textoBotao="Fechar"
        onClick={onClose}
        imagemModal={AssociarError}
      />
    );

  return (
    <Box display="flex" flexDirection="column" alignItems="space-between">
      <Grid container spacing={1} padding={4}>
        <Grid item xs={12}>
          <Typography variant="h6" color={Colors.primary.main}>
            Dados da empresa
          </Typography>
        </Grid>
        <Grid item container xs={12}>
          <Typography fontWeight="bold">Nome:</Typography>
          <Typography ml={2}>{cliente.nomeFantasia}</Typography>
        </Grid>
        <Grid item container xs={12}>
          <Typography fontWeight="bold">Razão social:</Typography>
          <Typography ml={2}>{cliente.razaoSocial}</Typography>
        </Grid>
        <Grid item container xs={12}>
          <Typography fontWeight="bold">CNPJ:</Typography>
          <Typography ml={2}>{cliente.cnpj}</Typography>
        </Grid>
        <Grid item container xs={12}>
          <Typography fontWeight="bold">Email:</Typography>
          <Typography ml={2}>{cliente.email}</Typography>
        </Grid>
        <Grid item container xs={12}>
          <Typography fontWeight="bold">Telefone:</Typography>
          <Typography ml={2}>{cliente.telefone}</Typography>
        </Grid>
        <Grid item container xs={12}>
          <Typography fontWeight="bold">Inscrição estadual:</Typography>
          <Typography ml={2}>{cliente.inscricaoEstadual}</Typography>
        </Grid>
        <Grid item container xs={12}>
          <Typography fontWeight="bold">Número de funcionários:</Typography>
          <Typography ml={2}>{cliente.numeroFuncionarios}</Typography>
        </Grid>

        <Grid item xs={12} mt={2}>
          <Typography variant="h6" color={Colors.primary.main}>
            Representante
          </Typography>
        </Grid>

        <Grid item container xs={12}>
          <Typography fontWeight="bold">Nome:</Typography>
          <Typography ml={2}>{cliente.representante.nome}</Typography>
        </Grid>
        <Grid item container xs={12}>
          <Typography fontWeight="bold">Cpf:</Typography>
          <Typography ml={2}>{cliente.representante.cpf}</Typography>
        </Grid>
        <Grid item container xs={12}>
          <Typography fontWeight="bold">RG:</Typography>
          <Typography ml={2}>{cliente.representante.rg}</Typography>
        </Grid>

        <Grid item xs={12} mt={2}>
          <Typography variant="h6" color={Colors.primary.main}>
            Endereço
          </Typography>
        </Grid>

        <Grid item container xs={12}>
          <Typography fontWeight="bold">CEP:</Typography>
          <Typography ml={2}>{cliente.endereco.cep}</Typography>
        </Grid>
        <Grid item container xs={12}>
          <Typography fontWeight="bold">Rua:</Typography>
          <Typography ml={2}>{cliente.endereco.rua}</Typography>
        </Grid>
        <Grid item container xs={12}>
          <Typography fontWeight="bold">Número:</Typography>
          <Typography ml={2}>{cliente.endereco.numero}</Typography>
        </Grid>
        <Grid item container xs={12}>
          <Typography fontWeight="bold">Complemento:</Typography>
          <Typography ml={2}>{cliente.endereco.complemento}</Typography>
        </Grid>
        <Grid item container xs={12}>
          <Typography fontWeight="bold">Bairro:</Typography>
          <Typography ml={2}>{cliente.endereco.bairro}</Typography>
        </Grid>
        <Grid item container xs={12}>
          <Typography fontWeight="bold">Cidade:</Typography>
          <Typography ml={2}>{cliente.endereco.cidade}</Typography>
        </Grid>
        <Grid item container xs={12}>
          <Typography fontWeight="bold">Estado:</Typography>
          <Typography ml={2}>{cliente.endereco.estado}</Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <InputLabel>Código da empresa (totalBUS)</InputLabel>
          <PatternFormat
            fullWidth
            sx={{ mt: 1 }}
            inputRef={inputRef}
            format="#########"
            name="numeroCartao"
            customInput={TextField}
            placeholder="Código da empresa"
            onChange={(e) => {
              setCodigoEmpresa(e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        container
        spacing={2}
        padding={3}
        alignContent="flex-end"
      >
        <Grid item xs={12} display="flex" gap={1}>
          <Button fullWidth variant="outlined" onClick={reprovar}>
            <Typography textTransform="none">Reprovar</Typography>
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={aprovar}
            disabled={Boolean(!codigoEmpresa.length)}
          >
            <Typography textTransform="none">Aprovar </Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormAprovarMigracao;
