import { isAfter, isBefore, isDate } from 'date-fns';
import * as yup from 'yup';

export interface FiltroComissoes {
  primeiraCargaPF?: boolean;
  primeiraCargaPJ?: boolean;
  nomeAgencia?: string;
  agenteMatricula?: string;
  dataInicio?: Date | null;
  dataFinal?: Date | null;
}

export const FILTRO_DEFAULT: FiltroComissoes = {
  primeiraCargaPF: false,
  primeiraCargaPJ: false,
  nomeAgencia: '',
  agenteMatricula: '',
  dataInicio: null as any,
  dataFinal: null as any
};

export const filtroComissoesFormModelSchema: yup.ObjectSchema<FiltroComissoes> =
  yup.object().shape(
    {
      primeiraCargaPF: yup.boolean().optional(),
      primeiraCargaPJ: yup.boolean().optional(),
      agenteMatricula: yup.string().optional(),
      dataInicio: yup
        .date()
        .nullable()
        .when('dataFinal', ([dataFinal], schema) => {
          const REQUIRED = 'A data de início também deve ser preenchida!';
          if (!dataFinal) return schema.optional();
          if (!isDate(dataFinal)) return schema.required(REQUIRED);

          return schema
            .test(
              'test-endDate',
              'A data de início deve ser inferior à data final!',
              (dataInicio) => {
                return !!dataInicio && !isAfter(dataInicio, dataFinal);
              }
            )
            .required(REQUIRED);
        })
        .notRequired(),
      dataFinal: yup
        .date()
        .nullable()
        .when('dataInicio', ([dataInicio], schema) => {
          const REQUIRED = 'A data final também deve ser preenchida!';

          if (!dataInicio) return schema.optional();
          if (!isDate(dataInicio)) return schema.required(REQUIRED);
          return schema
            .test(
              'test-endDate',
              'A data final deve ser superior à data de início!',
              (dataFinal) => !!dataFinal && !isBefore(dataFinal, dataInicio)
            )
            .required(REQUIRED);
        })
        .notRequired(),
      nomeAgencia: yup.string().optional()
    },
    [['dataInicio', 'dataFinal']]
  );
