import { PropsWithChildren } from 'react';
import { NavLinkProps } from 'react-router-dom';

import { CustomLink } from './Link.style';

type Props = PropsWithChildren<NavLinkProps>;

const Link = ({ children, ...props }: Props) => {
  return (
    <CustomLink
      {...props}
      color="black"
      className={(isActive) => (isActive ? 'active' : '')}
    >
      {children}
    </CustomLink>
  );
};

export default Link;
