import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import * as Yup from 'yup';

import AssociarError from '../../../assets/associar-error.svg';
import { Cliente } from '../../../models/Cliente.model';
import { LinkPagamento } from '../../../models/LinkPagamento.model';
import { TipoPagamento } from '../../../models/TipoPagamento.model';
import ClienteService from '../../../services/Cliente.service';
import { Colors } from '../../../shared/colors';
import FeedbackDrawer from '../../components/FeedbackDrawer';
import InputError from '../../components/Form/InputError';
import Loading from '../../components/Loading';

import { CardTipoPagamento } from './CardTipoPagamento';
import LinkPagamentoGerado from './LinkPagamentoGerado';
interface Props {
  onClose: () => void;
  cliente: Cliente;
}

const initialState = {
  error: '',
  errorMsg: '',
  loading: false,
  completed: false
};

const schema: Yup.ObjectSchema<LinkPagamento> = Yup.object().shape({
  valor: Yup.number()
    .min(0, 'Informe um valor positivo')
    .required('Informe o valor que deseja adicionar'),
  tipoPagamento: Yup.number().required('Selecione uma forma de pagamento'),
  id: Yup.string().required('Informe o ID'),
  cpfCnpj: Yup.string().required('Informe o CPF/CNPJ')
});

export const SelecionarTipoPagamento = ({ onClose, cliente }: Props) => {
  const inputRef = useRef<number | null>(null);
  const [pageController, setPageController] = useState(initialState);
  const [idPagamento, setIdPagamento] = useState('');
  const [tipoPagamento, setTipoPagamento] = useState<TipoPagamento | null>(
    null
  );

  const tipoPagamentoChecked = useCallback(
    (tipo: TipoPagamento | null) => tipoPagamento === tipo,
    [tipoPagamento]
  );

  const criarLinkPagamento = (data: LinkPagamento) => {
    setPageController((prev) => ({ ...prev, loading: true, error: '' }));
    ClienteService.criarLinkPagamento(data)
      .then((resp) => {
        setIdPagamento(resp.idLinkPagamento);
        setPageController((prev) => ({ ...prev, completed: true }));
      })
      .catch((err: Yup.ValidationError) => {
        setPageController((prev) => ({ ...prev, errorMsg: err.message }));
      })
      .finally(() =>
        setPageController((prev) => ({ ...prev, loading: false }))
      );
  };

  const handleClick = () => {
    const dataLinkPagamneto = {
      tipoPagamento,
      valor: inputRef.current,
      cpfCnpj: cliente.cpfCnpj,
      id: cliente.id
    };

    schema
      .validate(dataLinkPagamneto)
      .then(criarLinkPagamento)
      .catch((err: Yup.ValidationError) => {
        setPageController((prev) => ({ ...prev, error: err.message }));
      });
  };

  if (pageController.completed)
    return <LinkPagamentoGerado onClose={onClose} idPagamento={idPagamento} />;

  if (pageController.errorMsg)
    return (
      <FeedbackDrawer
        titulo="Erro ao solicitar carga!"
        subtitulo={pageController.errorMsg}
        textoBotao="Fechar"
        onClick={onClose}
        imagemModal={AssociarError}
      />
    );

  if (pageController.loading)
    return <Loading show={pageController.loading} bgColor={Colors.white} />;

  return (
    <Box
      p={3}
      flex={1}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Grid container spacing={2} px={1}>
        <Grid item xs={12}>
          <Typography color="secondary" fontWeight="bold">
            Selecione a forma de pagamento
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box
            gap={2}
            display="grid"
            justifyContent="center"
            gridTemplateColumns="repeat(auto-fit, 170px)"
          >
            <CardTipoPagamento
              tipo={TipoPagamento.PicPay}
              onChange={setTipoPagamento}
              checked={tipoPagamentoChecked}
            />
            <CardTipoPagamento
              tipo={TipoPagamento.Pix}
              onChange={setTipoPagamento}
              checked={tipoPagamentoChecked}
            />
            <CardTipoPagamento
              onChange={setTipoPagamento}
              checked={tipoPagamentoChecked}
              tipo={TipoPagamento.CartaoCredito}
            />
            <CardTipoPagamento
              onChange={setTipoPagamento}
              checked={tipoPagamentoChecked}
              tipo={TipoPagamento.CartaoDebito}
            />
            {cliente.clientePosPago && (
              <CardTipoPagamento
                onChange={setTipoPagamento}
                checked={tipoPagamentoChecked}
                tipo={TipoPagamento.PJPosPago}
              />
            )}
          </Box>
        </Grid>

        <Grid item container p={2} gap={2}>
          <Grid item xs={12} mt={2}>
            <Divider />
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          display="flex"
          justifyContent="space-between"
        >
          <Grid item xs={12}>
            <Typography color="primary">Informe o valor:</Typography>
          </Grid>
          <Grid item xs={12}>
            <NumericFormat
              fullWidth
              min={0}
              prefix="R$ "
              color="primary"
              sx={{ mt: 1 }}
              decimalScale={2}
              fixedDecimalScale
              decimalSeparator=","
              thousandSeparator="."
              customInput={TextField}
              onValueChange={(data) => {
                inputRef.current = data.floatValue ?? 0;
              }}
              placeholder="Informe o valor que deseja adicionar"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} pl={1}>
        <InputError error={pageController.error} />
      </Grid>
      <Grid
        item
        xs={12}
        container
        spacing={2}
        padding={2}
        justifyContent="end"
        alignContent="flex-end"
      >
        <Button variant="contained" onClick={handleClick}>
          <Typography textTransform="none">Adicionar saldo</Typography>
        </Button>
      </Grid>
    </Box>
  );
};
