import { mdiCalendar, mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Fab,
  Grid,
  Skeleton,
  Typography
} from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';

import { ConsumoPosPago } from '../../models/ConsumoPosPago.model';
import { DetalheExtrato } from '../../models/DetalheExtrato.model';
import ConsumoPosPagoService from '../../services/ConsumoPosPago.service';
import { Colors } from '../../shared/colors';

import CpfCnpjFormatter from './CpfCnpjFormatter';
import Currency from './Currency';

interface Props {
  consumoPosPago: ConsumoPosPago;
}

const CardPosPago = ({ consumoPosPago }: Props) => {
  const [detalhe, setDetalhe] = useState<DetalheExtrato[]>([]);
  const [loading, setLoading] = useState(false);

  const getDetalhes = () => {
    const [mes, ano] = consumoPosPago.mesAno.split('-');
    setLoading(true);
    ConsumoPosPagoService.getDetalhesConsumo(
      consumoPosPago.usuarioPjId,
      parseInt(mes, 10),
      parseInt(ano, 10)
    )
      .then(setDetalhe)
      .finally(() => setLoading(false));
  };

  return (
    <Box pb={2}>
      <Accordion
        sx={{ width: '100%', border: `1px solid ${Colors.primary.main}` }}
      >
        <AccordionSummary
          id="panel1a-header"
          onClick={getDetalhes}
          aria-controls="panel1a-content"
          expandIcon={
            <Icon
              size={1.3}
              path={mdiChevronDown}
              color={Colors.primary.main}
            />
          }
        >
          <Grid
            mr={2}
            container
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ borderColor: Colors.primary.main }}
          >
            <Grid
              item
              xs={8}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Box mr={2} display={{ xs: 'none', sm: 'block' }}>
                <Fab
                  size="small"
                  sx={{
                    border: 1,
                    bgcolor: Colors.primary.light,
                    borderColor: Colors.primary.main
                  }}
                >
                  <Icon
                    size={0.75}
                    path={mdiCalendar}
                    color={Colors.primary.main}
                  />
                </Fab>
              </Box>
              <Box>
                <Typography
                  fontWeight="bold"
                  color={Colors.primary.main}
                  sx={{ fontSize: { xs: '1rem', sm: '1.1rem' } }}
                >
                  {consumoPosPago.idClienteTotalBus} - {consumoPosPago.cliente}
                </Typography>
                <Typography
                  lineHeight={1.5}
                  variant="caption"
                  color={Colors.gray1}
                >
                  <CpfCnpjFormatter value={consumoPosPago.cnpj} />
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={4}
              container
              flexShrink={1}
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
            >
              <Typography
                variant="caption"
                color={Colors.gray1}
                textAlign="right"
                sx={{ textAlignLast: 'right' }}
                variantMapping={{ caption: 'p' }}
              >
                Total
              </Typography>
              <Box>
                <Typography
                  fontWeight="bold"
                  color={Colors.primary.main}
                  sx={{ fontSize: { xs: '1rem', sm: '1.1rem' } }}
                >
                  <Currency value={consumoPosPago.valorPeriodo} />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          {loading && (
            <Grid container gap={2}>
              <Grid item xs={12} mt={1}>
                <Divider />
              </Grid>
              <Grid
                item
                container
                xs={12}
                px={9}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={2} display="flex" flexDirection="column" gap={1}>
                  <Skeleton variant="rounded" width="100%" height={18} />
                  <Skeleton variant="rounded" width="90%" height={10} />
                </Grid>
                <Grid item xs={1} display="flex" justifyContent="center">
                  <Skeleton variant="rounded" width="100%" height={15} />
                </Grid>
              </Grid>
            </Grid>
          )}
          {detalhe.map((d) => (
            <Grid
              key={new Date(d.data).getTime()}
              container
              gap={1}
              justifyContent="center"
            >
              <Grid item xs={12} mt={1}>
                <Divider />
              </Grid>
              <Grid
                item
                xs={10}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <Typography
                    variant="body1"
                    fontWeight={600}
                    color={Colors.gray1}
                  >
                    Saldo Adicionado
                  </Typography>
                  <Typography variant="body2" color={Colors.gray1}>
                    {format(new Date(d.data), 'dd/MM/yyyy HH:mm')}
                  </Typography>
                </Box>
                <Typography fontWeight="bold" color={Colors.gray1}>
                  <Currency value={d.valor}></Currency>
                </Typography>
              </Grid>
            </Grid>
          ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default CardPosPago;
