import { mdiBellAlert } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Grid, Typography } from '@mui/material';
import { MouseEventHandler } from 'react';

import { Cliente } from '../../../models/Cliente.model';
import { Colors } from '../../../shared/colors';

interface Props {
  cliente: Cliente;
  onClick: MouseEventHandler<HTMLDivElement>;
}

export const MigracaoClientePos = ({ onClick, cliente }: Props) => {
  const solicitacaoPendente =
    cliente.solicitadoTrocaDePlanoPos && !cliente.clientePosPago;

  return (
    <Grid item>
      {solicitacaoPendente && (
        <Box
          display="flex"
          borderColor={Colors.primary.main}
          onClick={onClick}
          sx={{ cursor: 'pointer' }}
          gap={1}
        >
          <Typography
            fontWeight="bold"
            color="primary"
            sx={{ fontSize: { xs: '1rem', sm: '1.1rem' } }}
          >
            Pré → Pós
          </Typography>
          <Icon path={mdiBellAlert} size={1} color={Colors.primary.main} />
        </Box>
      )}
      {/*       <DrawerAprovarMigracao
              cliente={cliente}
              open={openDrawerFiltroUsuario}
              onClose={handleCloseDrawer}
            /> */}
    </Grid>
  );
};
