import { Box } from '@mui/material';

import { BASE_CONFIG } from '../../shared/baseConfig';
import Alert from '../components/Alert.doc';
import Instruction from '../components/Instruction.doc';
import Step from '../components/Step.doc';
import Tip from '../components/Tip.doc';

export const FiltrarAprovarCliente = () => (
  <Instruction titulo="Como filtrar e aprovar um cliente PJ no EmbarqueJá">
    <Box display="flex" flexDirection="column" alignItems="center">
      <Step descricao={`1. Navegue para ${BASE_CONFIG.App.BaseUrl}`} />
      <Step
        descricao='2. Clique na aba "Gestão de clientes"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/320d9fbb-5919-4642-a7c5-00c10fea16ff/ascreenshot.jpeg?tl_px=571,0&br_px=1317,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,62"
      />
      <Step
        descricao='3. Clique em "Filtrar"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/0dc9e4bc-f4d3-4f76-83df-a5530f2eb906/ascreenshot.jpeg?tl_px=1139,0&br_px=1885,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,107"
      />
      <Tip descricao="Tip: Escolha o campo desejado e preencha-o." />
      <Step
        descricao='4. Clique no campo "Codigo" e preencha-o caso necessário.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/050d4684-ddc2-44d5-a400-26e8f75eeee5/ascreenshot.jpeg?tl_px=1064,0&br_px=1810,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,94"
      />
      <Step
        descricao='5. Clique no campo "Nome do cliente" e preencha-o caso necessário.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/b73753fd-63fc-4a96-9195-d9c827cf76c0/ascreenshot.jpeg?tl_px=998,47&br_px=1744,467&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='6. Clique no campo "CPF/CNPJ" e preencha-o caso necessário.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/5352f056-1d4a-4c92-90c3-46bf5fe23a31/ascreenshot.jpeg?tl_px=951,123&br_px=1697,543&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='7. Clique em "Filtrar".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/40ae48be-ca3c-405a-9b7f-fbb398541b49/ascreenshot.jpeg?tl_px=1043,509&br_px=1789,929&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,269"
      />
      <Step
        descricao='8. Selecione o cliente que deseja realizar a migração e clique em "Pré → Pós".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/4e13d125-126c-4b52-ae53-dd4fb2f8f1a8/ascreenshot.jpeg?tl_px=1047,39&br_px=1793,459&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Alert descricao="Alert: Confira os dados exibidos na página de informações, e se todas as informações necessárias para a aprovação estão presentes." />
      <Step
        descricao='9. Clique no campo "Código da empresa" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/22f07c12-cddc-4461-8ce1-221defdc75bd/ascreenshot.jpeg?tl_px=1128,509&br_px=1874,929&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,184"
      />
      <Step
        descricao='10. Clique em "Aprovar"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-26/dc5947af-2ef3-4e32-930f-a978ccbeaad9/ascreenshot.jpeg?tl_px=1173,509&br_px=1919,929&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=378,265"
      />
    </Box>
  </Instruction>
);
