import { Cliente } from '../../../models/Cliente.model';
import Drawer from '../../components/Drawer';

import { SelecionarTipoPagamento } from './SelecionarTipoPagamento';

interface Props {
  cliente: Cliente | null;
  open: boolean;
  onClose: () => void;
}

const DrawerCargaDistancia = ({ cliente, open, onClose }: Props) => {
  if (!cliente) return null;

  return (
    <Drawer open={open} title="Carga a distância" onClose={onClose}>
      <SelecionarTipoPagamento cliente={cliente} onClose={onClose} />
    </Drawer>
  );
};

export default DrawerCargaDistancia;
