import { mdiFilterVariant } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { useState } from 'react';

import { Cliente } from '../../models/Cliente.model';
import { FiltroClientes } from '../../models/FiltroClientes.model';
import ClienteService from '../../services/Cliente.service';
import { Colors } from '../../shared/colors';
import useInfiniteScroll from '../../shared/hooks/infiniteScroll';
import CardCliente from '../components/CardCliente';
import DrawerCargaDistancia from '../features/carga-distancia/DrawerCargaDistancia';
import DrawerFiltrarCliente from '../features/filtrar-clientes/DrawerFiltrarClientes';

const CargaDistancia = () => {
  const [cliente, setCliente] = useState<Cliente | null>(null);
  const [filtroUsuarios, setFiltroUsuarios] = useState<FiltroClientes>();
  const [openDrawerFiltroUsuario, setOpenDrawerFiltroUsuario] = useState(false);

  const { refresh, loading, items, lastElementRef, hasNext } =
    useInfiniteScroll<Cliente, FiltroClientes>({
      load: ClienteService.listar,
      search: filtroUsuarios
    });

  const closeDrawerFiltro = (Filtro: FiltroClientes) => {
    setOpenDrawerFiltroUsuario(false);
    setFiltroUsuarios(Filtro);
  };

  const onCloseCargaDistancia = () => {
    setCliente(null);
    refresh();
  };

  const carregandoItens = (loading && hasNext) || loading;

  return (
    <Grid container spacing={2} paddingY={2}>
      <Grid
        item
        xs={12}
        container
        spacing={2}
        alignItems="center"
        display="flex"
      >
        <Grid item xs={12} justifyContent="flex-end">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" color={Colors.secondary} fontWeight={700}>
              Carga a distância
            </Typography>
            <Box
              display="flex"
              onClick={() => setOpenDrawerFiltroUsuario(true)}
              sx={{ cursor: 'pointer' }}
              gap={1}
            >
              <Typography fontWeight="bold" color={Colors.primary.main}>
                Filtrar
              </Typography>
              <Icon
                path={mdiFilterVariant}
                size={1}
                color={Colors.primary.main}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {items.map((cliente, index, arr) => {
          const lastElement = arr.length === index + 1;
          const ref = lastElement && hasNext ? lastElementRef : null;
          return (
            <div ref={ref} key={cliente.id}>
              <CardCliente
                infoContaCliente={
                  <CardCliente.DetalheCliente cliente={cliente} />
                }
              >
                <CardCliente.SaldoCliente cliente={cliente} />
                <CardCliente.CargaDistancia
                  cliente={cliente}
                  onClick={() => setCliente(cliente)}
                />
              </CardCliente>
            </div>
          );
        })}
      </Grid>

      {carregandoItens && (
        <Grid container gap={2} ml={2}>
          {Array(5)
            .fill(1)
            .map((_item, index) => index)
            .map((value) => (
              <Skeleton
                key={value}
                variant="rounded"
                width="100%"
                height={70}
              />
            ))}
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography
          mt={3}
          variant="body1"
          fontWeight={500}
          color={Colors.gray1}
          textAlign="center"
        >
          {!hasNext && !loading && 'Não há dados a serem exibidos'}
        </Typography>
      </Grid>

      <DrawerCargaDistancia
        cliente={cliente}
        open={Boolean(cliente)}
        onClose={onCloseCargaDistancia}
      />

      <DrawerFiltrarCliente
        open={openDrawerFiltroUsuario}
        onClose={closeDrawerFiltro}
      />
    </Grid>
  );
};

export default CargaDistancia;
