import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import {
  FiltroPosPago,
  FiltroPosPagoFormModelSchema,
  FILTRO_POS_PAGO_DEFAULT
} from '../../../models/FiltroPosPago.model';
import { Input } from '../../components/Form/Input';
import { PatternInput } from '../../components/Form/PatternInput';

interface Props {
  onClose: (filtroClientes: FiltroPosPago) => void;
  onChangeFilter: (filtroClientes: FiltroPosPago) => void;
  filtroPosPago: FiltroPosPago;
}

const FiltrarConsumoPosPago = ({
  filtroPosPago,
  onClose,
  onChangeFilter
}: Props) => {
  const form = useForm<FiltroPosPago>({
    defaultValues: filtroPosPago ?? FILTRO_POS_PAGO_DEFAULT,
    resolver: yupResolver(FiltroPosPagoFormModelSchema)
  });

  const filtrarClientes = (filtros: FiltroPosPago) => {
    const filtroSemValoresVazios = Object.fromEntries(
      Object.entries(filtros).filter(([_, v]) => v)
    );
    onClose(filtroSemValoresVazios);
  };

  const limparFiltros = () => {
    const { mes, ano } = filtroPosPago;

    onChangeFilter({ ...FILTRO_POS_PAGO_DEFAULT, mes, ano });
    form.reset({ ...FILTRO_POS_PAGO_DEFAULT, mes, ano });
  };
  return (
    <FormProvider {...form}>
      <Box
        width={1}
        display="flex"
        flexDirection="column"
        alignItems="space-between"
      >
        <Grid container spacing={2} padding={3}>
          <Grid item xs={12}>
            <PatternInput
              name="idClienteTotalBus"
              control={form.control}
              label="Código"
              placeholder="Código"
              format="#########"
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name="nome"
              control={form.control}
              label="Cliente"
              placeholder="Nome do cliente"
            />
          </Grid>
          <Grid item xs={12}>
            <PatternInput
              name="cnpj"
              control={form.control}
              label="CNPJ"
              placeholder="CNPJ"
              format="##.###.###/####-##"
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          spacing={2}
          padding={3}
          alignContent="flex-end"
        >
          <Grid item xs={12}>
            <Button fullWidth variant="outlined" onClick={limparFiltros}>
              <Typography textTransform="none">Limpar filtros</Typography>
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              onClick={form.handleSubmit(filtrarClientes, console.log)}
            >
              <Typography textTransform="none">Filtrar</Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
};

export default FiltrarConsumoPosPago;
