import { ConfiguracaoAdm } from '../models/ConfiguracaoAdm.model';

import Service from './Service';

class ConfiguracaoAdmService extends Service {
  static async configurar3DS2(data: ConfiguracaoAdm) {
    return this.Http.put('/configuracao-adm', data)
      .then(this.getData)
      .then((resp) => resp.data);
  }
  static async obter3DS2() {
    return this.Http.get('/configuracao-adm')
      .then(this.getData)
      .then((resp) => resp.data);
  }
}

export default ConfiguracaoAdmService;
