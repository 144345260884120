import { Box } from '@mui/material';

import { BASE_CONFIG } from '../../shared/baseConfig';
import Alert from '../components/Alert.doc';
import Instruction from '../components/Instruction.doc';
import Step from '../components/Step.doc';
import Tip from '../components/Tip.doc';

export const CriarUsuarioPJ = () => (
  <Instruction titulo="Cadastro Usuário Pessoa Jurídica no EmbarqueJá">
    <Box display="flex" flexDirection="column" alignItems="center">
      <Step descricao={`1. Navegue para ${BASE_CONFIG.App.ClienteUrl}`} />
      <Step
        descricao='2. Clique em "Cadastrar"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/c497bcf0-3b29-4e8b-8793-5f1bea97be6d/ascreenshot.jpeg?tl_px=586,429&br_px=1446,909&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,250"
      />
      <Step
        descricao='3. Clique "Selecionar" no card Pessoa Jurídica.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/5968eb24-e1e4-403a-8adc-e3ceac3e2422/ascreenshot.jpeg?tl_px=671,368&br_px=1531,849&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Tip descricao="Tip: Preencha suas informações de acordo com o nome do campo." />
      <Step
        descricao='4. Clique no campo "*CNPJ" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/edb089e8-18b8-46ed-aa20-46a073e2eeae/ascreenshot.jpeg?tl_px=111,0&br_px=971,480&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,186"
      />
      <Step
        descricao='5. Clique no campo "*Nome fantasia" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/32fef087-9a2c-45fe-9c27-751a2651ab50/ascreenshot.jpeg?tl_px=477,0&br_px=1337,480&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,176"
      />
      <Step
        descricao='6. Clique no campo "*Razão social" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/3e2b0a52-22df-46ac-bdff-e31c1f2bce93/ascreenshot.jpeg?tl_px=765,0&br_px=1625,480&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,188"
      />
      <Step
        descricao='7. Clique no campo "*Inscrição estadual" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/985e7d72-0907-4182-a0aa-9ae331d8e47f/ascreenshot.jpeg?tl_px=177,42&br_px=1037,523&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Step
        descricao='8. Clique no campo "*E-mail" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/699ae9b5-0702-41e1-b422-1aa0aada8d83/ascreenshot.jpeg?tl_px=368,37&br_px=1228,518&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Alert descricao="Alert: Atenção, este e-mail será responsável por toda a comunicação referente ao seu acesso a plataforma (primeira senha, recuperação de senha, etc.)" />
      <Step
        descricao='9. Clique no campo "*Celular" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/33e04bc9-2c7d-4694-a4a2-cfdfa7984602/ascreenshot.jpeg?tl_px=788,43&br_px=1648,524&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Alert descricao="Alert: Atenção, cadastre um celular ativo que receba SMS, pois será usado para envio de token para utilização de certas funcionalidades no EmbarqueJá." />
      <Step
        descricao='10. Clique no campo "Número de funcionários" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/2fc98887-e0ea-4fb4-99c4-87179d33b487/ascreenshot.jpeg?tl_px=176,157&br_px=1036,638&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Step
        descricao='11. Clique no campo "*Nome" na seção "Dados do representante".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/60a197a6-e7bd-42c5-8825-cac2548559ba/ascreenshot.jpeg?tl_px=136,297&br_px=996,778&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Step
        descricao='12. Clique no campo "CPF" na seção "Dados do representante" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/d76b7101-da91-46d7-821e-94443017d3a6/ascreenshot.jpeg?tl_px=391,273&br_px=1251,754&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Step
        descricao='13. Clique no campo "*RG" na seção "Dados do representante" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/40568dfb-aaf0-4aea-aa13-e09a85c3d213/ascreenshot.jpeg?tl_px=782,294&br_px=1642,775&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Step
        descricao='14. Clique no campo "CEP" na seção "Endereço".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/c5f03d84-f031-4ea6-ac53-be6447932094/ascreenshot.jpeg?tl_px=227,429&br_px=1087,909&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,228"
      />
      <Tip descricao='Tip: Caso o "CEP" seja encontrado, os campo "Estado", "Cidade", "Bairro" e "Rua" serão preenchidos automaticamente.' />
      <Step
        descricao='15. Caso preenchido automaticamente, verifique o campo "Estado".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/a9344621-2b44-48ac-a8db-eae533ab0194/ascreenshot.jpeg?tl_px=584,429&br_px=1444,909&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,237"
      />
      <Step
        descricao='16. Caso preenchido automaticamente, verifique o campo "Cidade".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/2d3943d2-b51b-46a8-973c-60a9cc916215/ascreenshot.jpeg?tl_px=863,429&br_px=1723,909&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,228"
      />
      <Step
        descricao='17. Caso preenchido automaticamente, verifique o campo "Bairro".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/124eb0fc-2baf-4c60-b504-0e28ac1f52fe/ascreenshot.jpeg?tl_px=108,429&br_px=968,909&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,322"
      />
      <Step
        descricao='18. Caso preenchido automaticamente, verifique o campo "Rua".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/a70e91ab-f2cd-4c37-8644-a8eee223eeb7/ascreenshot.jpeg?tl_px=551,429&br_px=1411,909&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,309"
      />
      <Step
        descricao='19. Preencha o campo "Número".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/1115315a-00b4-4beb-b43f-00bfd73bbc00/ascreenshot.jpeg?tl_px=848,429&br_px=1708,909&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,329"
      />
      <Step
        descricao='20. Clique no campo "Complemento" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/8df0fe4f-101c-433f-a179-eedb07916284/ascreenshot.jpeg?tl_px=146,448&br_px=1006,929&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,362"
      />
      <Step
        descricao='21. Clique em "Realizar cadastro".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/d54863dc-d61e-4f8e-9ebf-f0e734c63326/ascreenshot.jpeg?tl_px=960,429&br_px=1820,909&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,419"
      />
      <Step
        descricao='22. Clique em "Concluir" para finalizar seu cadastro.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-22/8e4ccb1a-63f1-46b5-a61c-eaad5fcd1023/ascreenshot.jpeg?tl_px=552,365&br_px=1412,846&force_format=png&width=860&wat_scale=76&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=402,212"
      />
      <Tip descricao='Tip: Ao final do cadastro, um e-mail será enviado com os dados de acesso a serem utilizados para acessar a plataforma. Utilize-os para realizar o login, os passos estão disponibilizados na seção "Acessando a plataforma Embarque Já".' />
    </Box>
  </Instruction>
);
