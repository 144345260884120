import { Box } from '@mui/material';

import { BASE_CONFIG } from '../../shared/baseConfig';
import Instruction from '../components/Instruction.doc';
import Step from '../components/Step.doc';

export const RegistrarAgente = () => (
  <Instruction titulo="Como registrar um agente de vendas no EmbarqueJá">
    <Box display="flex" flexDirection="column" alignItems="center">
      <Step descricao={`1. Navegue para ${BASE_CONFIG.App.BaseUrl}`} />
      <Step
        descricao='2. Clique na aba "Gestão de usuários"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-05/5d50f1d4-9473-4dbf-bf3d-26b1ab5ace5d/ascreenshot.jpeg?tl_px=1024,0&br_px=1770,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,69"
      />
      <Step
        descricao='3. Clique em "Cadastrar usuário"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-05/f703d327-5f07-4267-a9c4-9ced7864dfa1/ascreenshot.jpeg?tl_px=1019,0&br_px=1765,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,108"
      />
      <Step
        descricao='4. Clique no campo "*Tipo de usuário".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-05/be3f4ab5-610f-4d84-9f52-5488c7b6b336/ascreenshot.jpeg?tl_px=1173,0&br_px=1919,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=264,100"
      />
      <Step
        descricao='5. Clique em "Agente de vendas"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-05/255ebaad-1dbe-47dd-9475-d9526335031b/ascreenshot.jpeg?tl_px=1104,35&br_px=1850,455&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='6. Clique em "*Pesquise pelo nome da agência" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-05/f8c3aba4-6dcd-4304-9bc0-fe13e48d68bb/ascreenshot.jpeg?tl_px=1147,106&br_px=1893,526&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='7. Clique no campo "*Matrícula TotalBus".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-05/3fcda740-9978-445f-81dc-cd930a6b6693/ascreenshot.jpeg?tl_px=1156,145&br_px=1902,565&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='8. Clique no campo "*CPF" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-05/161f0b83-06bf-4676-aa1b-4bfafd44b898/ascreenshot.jpeg?tl_px=999,326&br_px=1745,746&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='9. Clique no campo "*E-mail do usuário" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-05/5ba380b9-98b3-4e32-aa34-a42c7fbebda7/ascreenshot.jpeg?tl_px=1025,421&br_px=1771,841&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,139"
      />
      <Step
        descricao='10. Clique em cadastrar "Cadastrar".'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-05/a2b4ff68-35cc-4cfb-9f19-a27517b4a189/ascreenshot.jpeg?tl_px=1173,509&br_px=1919,929&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=272,268"
      />
      <Step
        descricao='11. Clique em "Fechar" para voltar a tela de listagem.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-07-05/9433b347-14ec-423e-a3d9-e4b60c59ac22/ascreenshot.jpeg?tl_px=1050,509&br_px=1796,929&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,259"
      />
    </Box>
  </Instruction>
);
