import { DesassociarCartao } from '../../../models/AssociarCartao.model';
import { CartaoUsuario } from '../../../models/CartaoUsuario.model';
import Drawer from '../../components/Drawer';

import FormDesassociarCartao from './FormDesassociarCartao';

interface Props {
  cartaoUsuario: CartaoUsuario;
  open: boolean;
  onClose: () => void;
}

const DrawerDesassociarCartao = ({ open, cartaoUsuario, onClose }: Props) => {
  const desassociacaoCartao: DesassociarCartao = {
    idCarteira: cartaoUsuario.idCarteira,
    usuarioId: cartaoUsuario.usuarioId,
    numeroCartao: cartaoUsuario.numeroCartaoFisico
  };
  return (
    <Drawer title="Desassociar cartão" open={open} onClose={onClose}>
      <FormDesassociarCartao
        onClose={onClose}
        desassociacaoCartao={desassociacaoCartao}
      />
    </Drawer>
  );
};

export default DrawerDesassociarCartao;
