import { mdiAlertCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Dispatch } from 'react';

import { Colors } from '../../shared/colors';

interface Props {
  open: boolean;
  message?: string;
  onClose: () => void;
  setOpen: Dispatch<boolean>;
}

export default function ModalError({
  open,
  message = 'Ocorreu um erro inesperado, tente novamente!',
  onClose
}: Props) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { p: 2 } }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle
        id="responsive-dialog-title"
        align="center"
        fontWeight="bold"
        color={Colors.error.dark}
      >
        Algo deu errado!
      </DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center" mb={2}>
          <Icon
            size={3}
            color={Colors.error.light}
            path={mdiAlertCircleOutline}
          />
        </Box>
        <DialogContentText align="center">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          onClick={onClose}
          //color="error"
          variant="contained"
        >
          <Typography variant="body1" textTransform="none">
            Confirmar
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
