import { Box, Container } from '@mui/material';

import { AdicionarCargaDistancia } from './admin/AdicionarCargaDistancia';
import { AdicionarCargaDistanciaDebito } from './admin/AdicionarCargaDistanciaDebito';
import { AssociarCartao } from './admin/AssociarCartao';
import { BaixarRelatorio } from './admin/BaixarRelatorio';
import { CadastrarPF } from './admin/CadastrarPF';
import { CadastrarPJ } from './admin/CadastrarPJ';
import { CadastrarUsuario } from './admin/CadastrarUsuario';
import { FiltrarAprovarCliente } from './admin/FiltrarAprovarCliente';
import { FiltrarClientes } from './admin/FiltrarClientes';
import { FiltrarComissoes } from './admin/FiltrarComissoes.doc';
import { FiltrarTransacoes } from './admin/FiltrarTransacoes';
import { FiltrarTransacoesExecutadas } from './admin/FiltrarTransacoesExecutadas';
import { FiltrarTransacoesPJ } from './admin/FiltrarTransacoesPJ';
import { GerarListaCartoes } from './admin/GerarListaCartoes';
import { ImportarCartoes } from './admin/ImportarCartoes';
import { RegistrarAgente } from './admin/RegistrarAgente';
import Instruction from './components/Instruction.doc';
import AddUsuarioPFPelaContaPJ from './pessoa-fisica/AddUsuarioPFPelaContaPJ';
import { AdicionarSaldoCartaoCredito } from './pessoa-fisica/AdicionarSaldoCartaoCredito';
import { AdicionarSaldoDebito } from './pessoa-fisica/AdicionarSaldoDebito';
import { AdicionarSaldoPix } from './pessoa-fisica/AdicionarSaldoPix';
import { CriarUsuarioPF } from './pessoa-fisica/CriarUsuarioPF';
import { CriarUsuarioPJ } from './pessoa-fisica/CriarUsuarioPJ';
import { EditarUsuario } from './pessoa-fisica/EditarUsuario';
import { FiltrarTransacoesCartaoEmpresa } from './pessoa-fisica/FiltrarTransacoesCartaoEmpresa';
import { FiltrarTransacoesPessoais } from './pessoa-fisica/FiltrarTransacoesPessoais';
import { PrimeiroAcesso } from './pessoa-fisica/PrimeiroAcesso';
import { SolicitarMigracaoPosPago } from './pessoa-fisica/SolicitarMigracaoPosPago';

const Documentation = () => {
  return (
    <Container>
      <Box py={4} gap={2}>
        <Instruction titulo="Administrativo">
          <AdicionarCargaDistancia />
          <AdicionarCargaDistanciaDebito />
          <AssociarCartao />
          <BaixarRelatorio />
          <CadastrarPJ />
          <CadastrarPF />
          <CadastrarUsuario />
          <FiltrarTransacoes />
          <FiltrarAprovarCliente />
          <FiltrarClientes />
          <FiltrarComissoes />
          <FiltrarTransacoesExecutadas />
          <FiltrarTransacoesPJ />
          <GerarListaCartoes />
          <ImportarCartoes />
          <RegistrarAgente />
        </Instruction>

        <Instruction titulo="Agente de vendas">
          <AdicionarCargaDistancia />
          <AdicionarCargaDistanciaDebito />
          <BaixarRelatorio />
          <CadastrarPJ />
          <CadastrarPF />
          <FiltrarTransacoes />
          <FiltrarClientes />
          <FiltrarTransacoesExecutadas />
          <FiltrarTransacoesPJ />
        </Instruction>

        <Instruction titulo="Pessoa Física">
          <AdicionarSaldoCartaoCredito />
          <AdicionarSaldoDebito />
          <AdicionarSaldoPix />
          <CriarUsuarioPF />
          <FiltrarTransacoesCartaoEmpresa />
          <FiltrarTransacoesPessoais />
          <PrimeiroAcesso />
        </Instruction>

        <Instruction titulo="Pessoa Jurídica">
          <CriarUsuarioPJ />
          <EditarUsuario />
          <AdicionarSaldoCartaoCredito />
          <AdicionarSaldoDebito />
          <AdicionarSaldoPix />
          <FiltrarTransacoesPessoais />
          <PrimeiroAcesso />
          <AddUsuarioPFPelaContaPJ />
          <SolicitarMigracaoPosPago />
        </Instruction>
      </Box>
    </Container>
  );
};

export default Documentation;
