import * as yup from 'yup';

import { validarCNPJ } from '../shared/functions/validarCNPJ';
import { validarCPF } from '../shared/functions/validarCpf';

export const FILTRO_TIPO_CLIENTE = {
  TODOS: 1,
  PJ_POS: 2,
  PJ_PRE: 3,
  PESSOA_FISICA: 4
} as const;

export const FILTRO_CLIENTE = {
  Todos: FILTRO_TIPO_CLIENTE.TODOS,
  'PJ pós': FILTRO_TIPO_CLIENTE.PJ_POS,
  'PJ pré': FILTRO_TIPO_CLIENTE.PJ_PRE,
  'Pessoa física': FILTRO_TIPO_CLIENTE.PESSOA_FISICA
} as const;

export const FILTRO_SITUACAO_CLIENTE = {
  TODOS: 1,
  BLOQUEADO: 2,
  DESBLOQUEADO: 3
} as const;

export const FILTRO_SITUACAO = {
  Todos: FILTRO_SITUACAO_CLIENTE.TODOS,
  'Pós-pago ativo': FILTRO_SITUACAO_CLIENTE.DESBLOQUEADO,
  'Pós-pago inativo': FILTRO_SITUACAO_CLIENTE.BLOQUEADO
} as const;

export interface FiltroClientes {
  nome?: string;
  cpfCnpj?: string;
  tipoUsuario?: number;
  situacao?: number;
  apenasSaldoNegativo?: boolean;
  idClienteTotalBus?: number | '';
}

export const FILTRO_CLIENTE_DEFAULT: FiltroClientes = {
  nome: '',
  cpfCnpj: '',
  idClienteTotalBus: '',
  tipoUsuario: 1,
  situacao: 1,
  apenasSaldoNegativo: false
};

export const FiltroClientesFormModelSchema: yup.ObjectSchema<FiltroClientes> =
  yup.object().shape({
    nome: yup.string().optional(),
    idClienteTotalBus: yup.mixed<number | ''>(),
    tipoUsuario: yup.number().optional(),
    situacao: yup.number().optional(),
    cpfCnpj: yup
      .string()
      .test('Teste documento', 'Documento inválido', (value) => {
        if (!value) return true;
        if (value.length <= 11) return validarCPF(value);
        return validarCNPJ(value);
      })
      .transform((val) => val.replace(/[^\d]+/g, ''))
      .optional(),
    apenasSaldoNegativo: yup.boolean().optional()
  });
