import { Button, Grid, InputLabel, TextField, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { FieldError } from 'react-hook-form';
import * as yup from 'yup';

import { Colors } from '../../../shared/colors';
import InputError from '../../components/Form/InputError';

interface Props {
  gerarArquivo: (qtd: number) => void;
}

const gerarCartaoSchema = yup
  .number()
  .min(1, 'A quantidade mínima deve ser maior que 0')
  .max(9999, 'A quantidade mínima deve ser menor que 9999')
  .typeError('Campo obrigatório')
  .required('Campo obrigatório');

const FormGerarCartao = ({ gerarArquivo }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [errorMsg, setErrorMsg] = useState<FieldError>();

  const onClickGerarArquivo = () => {
    setErrorMsg(undefined);

    if (!inputRef.current) return;
    const { valueAsNumber: value } = inputRef.current;

    gerarCartaoSchema.validate(value).then(gerarArquivo, setErrorMsg);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" color={Colors.secondary}>
          Gerar cartões
        </Typography>
      </Grid>
      <Grid item xs={12} display="flex" flexDirection="column">
        <InputLabel>Quantidade</InputLabel>
        <TextField
          type="number"
          sx={{ mt: 0.5 }}
          inputRef={inputRef}
          inputProps={{ maxLength: 4 }}
          InputProps={{ inputProps: { min: 1, max: 9999 } }}
        />
        <InputError error={errorMsg} />
      </Grid>
      <Grid item xs={12}>
        <Button fullWidth variant="contained" onClick={onClickGerarArquivo}>
          <Typography textTransform="none">Gerar</Typography>
        </Button>
      </Grid>
    </>
  );
};

export default FormGerarCartao;
