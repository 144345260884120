import { mdiFilterVariant } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { useState } from 'react';

import { FiltroTransacoes } from '../../models/FiltroTransacoes.model';
import { BilhetePassagem } from '../../models/LinkPagamento.model';
import { Transacao } from '../../models/Transacao.model';
import ClienteService from '../../services/Cliente.service';
import { Colors } from '../../shared/colors';
import useInfiniteScroll from '../../shared/hooks/infiniteScroll';
import CardTransacao from '../components/CardTransacao';
import DrawerDetalhesViagem from '../features/detalhes-viagem/DrawerDetalhesViagem';
import DrawerFiltrarTransacao from '../features/filtrar-transacoes/DrawerFiltrarTransacaoUsuarios';

const Home = () => {
  const [openDrawerFiltroUsuario, setOpenDrawerFiltroUsuario] = useState(false);
  const [filtroTransacoes, setFiltroTransacoes] = useState<FiltroTransacoes>();
  const [bilhetesPassagem, setBilhetespassagem] = useState<
    BilhetePassagem[] | null
  >(null);

  const closeDrawerFiltro = (Filtro: FiltroTransacoes) => {
    setOpenDrawerFiltroUsuario(false);
    setFiltroTransacoes(Filtro);
  };

  const { loading, items, lastElementRef, hasNext } = useInfiniteScroll<
    Transacao,
    FiltroTransacoes
  >({
    load: ClienteService.listarTransacoes,
    search: filtroTransacoes
  });

  const carregandoItens = (loading && hasNext) || loading;

  return (
    <Grid container paddingY={2}>
      <Grid item container xs={12} spacing={2} alignItems="center" mb={2}>
        <Grid item justifyContent="flex-end" flex={1}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" color={Colors.secondary} fontWeight={700}>
              Transações de clientes
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
          sm={6}
          gap={2}
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          <Box
            display="flex"
            onClick={() => setOpenDrawerFiltroUsuario(true)}
            sx={{ cursor: 'pointer' }}
            gap={1}
          >
            <Typography fontWeight="bold" color={Colors.primary.main}>
              Filtrar
            </Typography>
            <Icon
              path={mdiFilterVariant}
              size={1}
              color={Colors.primary.main}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {items.map((transacao, index, arr) => {
          const lastElement = arr.length === index + 1;
          const ref = lastElement && hasNext ? lastElementRef : null;
          return (
            <div ref={ref} key={transacao.idTransacao}>
              <CardTransacao
                onClick={() => setBilhetespassagem(transacao.bilhetes ?? null)}
                transacao={transacao}
              />
            </div>
          );
        })}
      </Grid>

      {carregandoItens && (
        <Grid container gap={2}>
          {Array(5)
            .fill(1)
            .map((_item, index) => index)
            .map((value) => (
              <Skeleton
                key={value}
                variant="rounded"
                width="100%"
                height={70}
              />
            ))}
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography
          mt={3}
          variant="body1"
          fontWeight={500}
          color={Colors.gray1}
          textAlign="center"
        >
          {!hasNext && !loading && 'Não há dados a serem exibidos'}
        </Typography>
      </Grid>

      <DrawerFiltrarTransacao
        open={openDrawerFiltroUsuario}
        onClose={closeDrawerFiltro}
      />
      <DrawerDetalhesViagem
        bilhetes={bilhetesPassagem}
        onClose={() => setBilhetespassagem(null)}
      />
    </Grid>
  );
};

export default Home;
