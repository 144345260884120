import { Grid, Typography } from '@mui/material';
import { Colors } from '@vabembarqueja/embarque-ja-shared-components';

import { Cliente } from '../../../models/Cliente.model';
import Currency from '../Currency';

interface Props {
  cliente: Cliente;
}

export const SaldoCliente = ({ cliente }: Props) => (
  <Grid item display="flex" flexDirection="column" alignItems="center">
    <Typography
      fontWeight="bold"
      color={Colors.primary.dark}
      sx={{ fontSize: { xs: '1rem', sm: '1.1rem' } }}
    >
      <Currency value={cliente.saldo} />
    </Typography>
  </Grid>
);
