import { mdiMicrosoftExcel, mdiPaperclip } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

import { Colors } from '../../../shared/colors';

export interface FileUploadProps extends DropzoneOptions {
  onChange: (files: File[]) => void;
  value?: File[];
  icon?: string;
  size?: number;
}

export function bytesToSize(bytes: number) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';

  const index = Math.floor(Math.log(bytes) / Math.log(1024));
  const label = sizes[Number(index)];

  return `${Math.round(bytes / 1024 ** index)} ${label}`;
}

const FileUpload = React.forwardRef<HTMLInputElement, FileUploadProps>(
  ({ onChange, value, size, icon, ...props }, ref) => {
    const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
      useDropzone({
        onDrop: (acceptedFiles) => {
          onChange(acceptedFiles);
        },
        ...props
      });

    const hasFiles = acceptedFiles.length > 0 || (!!value && value.length > 0);
    const hasError = fileRejections.length > 0;

    const currentFiles = value || acceptedFiles;

    const acceptedFileItems = currentFiles.map((file: File) => {
      return (
        <Typography key={file.name} ml={1} color="secondary">
          {file.name} ({bytesToSize(file.size)})
        </Typography>
      );
    });

    return (
      <Box
        ref={ref}
        flex={1}
        border={1}
        minHeight={100}
        borderRadius={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ cursor: 'pointer' }}
        borderColor={hasError ? Colors.error.red : Colors.primary.main}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Icon
          size={size ?? 1.4}
          color={hasError ? Colors.error.red : Colors.primary.main}
          path={icon ?? hasFiles ? mdiMicrosoftExcel : mdiPaperclip}
        />
        {acceptedFileItems}
      </Box>
    );
  }
);

FileUpload.displayName = 'FileUpload';
export default FileUpload;
