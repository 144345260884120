import { Box } from '@mui/material';

import { BASE_CONFIG } from '../../shared/baseConfig';
import Alert from '../components/Alert.doc';
import Instruction from '../components/Instruction.doc';
import Step from '../components/Step.doc';
import Tip from '../components/Tip.doc';

export const BaixarRelatorio = () => (
  <Instruction
    titulo="Como filtrar e baixar o relatório das transações executadas no EmbarqueJá
    "
  >
    <Box display="flex" flexDirection="column" alignItems="center">
      <Step descricao={`1. Navegue para ${BASE_CONFIG.App.BaseUrl}`} />
      <Alert descricao='Alert: Para fazer o download das transações, é necessário que os campos de "Data de início" e "Data final" estejam preenchidos.' />

      <Step
        descricao="2. Click this icon."
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/d77cfa33-cad0-4d42-86c3-e5f37479739e/ascreenshot.jpeg?tl_px=1129,0&br_px=1875,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,108"
      />

      <Tip descricao="Tip: Preencha os campos pelos quais você deseja filtrar as transações."></Tip>

      <Step
        descricao='3. Clique em "Selecione o tipo de transação" e preencha com o tipo desejado.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/e8b95f50-0b99-49af-b2ac-192ca1390bb9/ascreenshot.jpeg?tl_px=1173,0&br_px=1919,420&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=275,108"
      />
      <Step
        descricao='4. Clique no campo "Data de início" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/f00d8654-1df6-4e4f-b19c-464afbeb20ed/ascreenshot.jpeg?tl_px=1173,428&br_px=1919,848&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=270,139"
      />
      <Step
        descricao='5. Clique no campo "Data final" e preencha-o.'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/53f56c86-088e-4798-a28f-13e9108b99b8/ascreenshot.jpeg?tl_px=1173,422&br_px=1919,842&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=509,139"
      />

      <Step
        descricao='6. Clique em "Download"'
        imagem="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-06-23/14c60192-676f-426c-b09e-6a549c25c52c/ascreenshot.jpeg?tl_px=1069,509&br_px=1815,929&force_format=png&width=560.0&wat_scale=50&wat=1&wat_opacity=0.7&wat_gravity=northwest&wat_url=https://colony-recorder.s3.us-west-1.amazonaws.com/images/watermarks/FB923C_standard.png&wat_pad=262,181"
      />

      <Tip descricao="Tip: Após clicar em download seu arquivo será baixado e estará na pasta Downloads de seu dispositivo."></Tip>
    </Box>
  </Instruction>
);
