import { useState } from 'react';

import {
  FiltroTransacoes,
  FILTRO_DEFAULT
} from '../../../models/FiltroTransacoes.model';
import Drawer from '../../components/Drawer';

import FiltrarTransacoes from './FiltrarTransacoes';

interface Props {
  open: boolean;
  onClose: (data: FiltroTransacoes) => void;
  permitirFiltrarUsuarios?: boolean;
}

const DrawerFiltrarTransacao = ({
  open,
  onClose,
  permitirFiltrarUsuarios = false
}: Props) => {
  const [filtroTransacao, setFiltroTransacao] =
    useState<FiltroTransacoes>(FILTRO_DEFAULT);

  const onCloseDrawer = (filtroTransacao: FiltroTransacoes) => {
    const filtroSemValoresVazios = Object.fromEntries(
      Object.entries(filtroTransacao).filter(([_, v]) => v)
    );
    setFiltroTransacao(filtroSemValoresVazios);
    onClose(filtroSemValoresVazios);
  };

  return (
    <Drawer
      title="Filtrar"
      open={open}
      onClose={() => {
        onCloseDrawer(filtroTransacao);
      }}
    >
      <FiltrarTransacoes
        filtroTransacoes={filtroTransacao}
        onChangeFilter={setFiltroTransacao}
        onClose={onCloseDrawer}
        permitirFiltrarUsuarios={permitirFiltrarUsuarios}
      />
    </Drawer>
  );
};

export default DrawerFiltrarTransacao;
