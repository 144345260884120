import { Usuario } from '../../../models/Usuario.model';
import Drawer from '../../components/Drawer';

import FormCadastrarUsuario from './FormCadastrarUsuario';

interface Props {
  open: boolean;
  onClose: () => void;
  permitirFiltrarUsuarios?: boolean;
  userDefault: Usuario;
}

const DrawerCadastrarUsuario = ({ open, onClose, userDefault }: Props) => {
  const onCloseDrawer = () => {
    onClose();
  };

  return (
    <Drawer
      title={userDefault.id ? 'Edição de usuário' : 'Cadastro de usuário'}
      open={open}
      onClose={() => {
        onCloseDrawer();
      }}
    >
      <FormCadastrarUsuario
        usuarioDefault={userDefault}
        onClose={onCloseDrawer}
      />
    </Drawer>
  );
};

export default DrawerCadastrarUsuario;
