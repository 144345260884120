import { mdiCreditCardPlusOutline, mdiCreditCardRemove } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Grid, Typography } from '@mui/material';
import { useState } from 'react';

import { useUserContext } from '../../contexts/User.context';
import { CartaoUsuario } from '../../models/CartaoUsuario.model';
import { Colors } from '../../shared/colors';
import DrawerAssociarCartao from '../features/associar-cartao/DrawerAssociarCartao';
import DrawerDesassociarCartao from '../features/desassociar-cartao/DrawerDesassociarCartao';

interface Props {
  cartaoUsuario: CartaoUsuario;
  onClose: () => void;
}

const CardAssociarCartao = ({ cartaoUsuario, onClose }: Props) => {
  const { userClaim } = useUserContext();
  const admin = userClaim === 'Administrativo';
  const [openDrawerAssociar, setOpenDrawerAssociar] = useState(false);
  const [openDrawerDesassociar, setOpenDrawerDesassociar] = useState(false);

  return (
    <>
      <Grid
        container
        border={1}
        paddingX={2}
        marginBottom={2}
        paddingY={1.5}
        borderRadius={1}
        alignItems="center"
        justifyContent="space-between"
        sx={{ borderColor: Colors.primary.main }}
      >
        <Grid item container xs={6} sm={5}>
          <Grid item container display="flex" flexDirection="column">
            <Typography variant="body1" color={Colors.gray1} lineHeight={1.5}>
              {cartaoUsuario.nome}
            </Typography>
            <Typography variant="caption" color={Colors.gray1}>
              CPF: {cartaoUsuario.cpf}
            </Typography>
          </Grid>
        </Grid>

        {cartaoUsuario.nomeEmpresa && (
          <Grid item container xs={6} sm={4} order={{ xs: 3, sm: 2 }}>
            <Grid
              item
              container
              display="flex"
              flexDirection="column"
              mt={{ xs: 1, sm: 0 }}
            >
              <Typography variant="body2" color={Colors.gray1} lineHeight={1.5}>
                {cartaoUsuario.nomeEmpresa}
              </Typography>
              <Typography variant="caption" color={Colors.gray1}>
                CNPJ: {cartaoUsuario.cnpjEmpresa ?? ''}
              </Typography>
            </Grid>
          </Grid>
        )}

        <Grid
          item
          xs={5}
          sm={3}
          spacing={1}
          container
          flexShrink={1}
          display="flex"
          alignItems="center"
          order={{ xs: 2 }}
          justifyContent="flex-end"
        >
          {cartaoUsuario.numeroCartaoFisico ? (
            <Box
              p={0.5}
              display="flex"
              alignItems="center"
              borderRadius={1}
              bgcolor={Colors.gray3}
              sx={{ cursor: admin ? 'pointer' : '' }}
              onClick={() => setOpenDrawerDesassociar(true)}
            >
              <Icon size={1} path={mdiCreditCardRemove} color={Colors.gray1} />
            </Box>
          ) : (
            <Box
              p={0.5}
              display="flex"
              borderRadius={1}
              onClick={() => setOpenDrawerAssociar(true)}
              sx={{ cursor: 'pointer' }}
              bgcolor={Colors.primary.main}
            >
              <Icon
                size={1}
                color={Colors.white}
                path={mdiCreditCardPlusOutline}
              />
            </Box>
          )}
          <Box pl={1}>
            <Typography variant="body1" color={Colors.gray1} lineHeight={1.5}>
              Cartão físico
            </Typography>
            <Box>
              <Typography
                variant="caption"
                color={Colors.gray1}
                textAlign="right"
                sx={{ textAlignLast: 'right' }}
                variantMapping={{ caption: 'p' }}
              >
                {cartaoUsuario.numeroCartaoFisico}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <DrawerAssociarCartao
        open={openDrawerAssociar}
        cartaoUsuario={cartaoUsuario}
        onClose={() => {
          setOpenDrawerAssociar(false);
          onClose();
        }}
      />
      <DrawerDesassociarCartao
        open={admin && openDrawerDesassociar}
        cartaoUsuario={cartaoUsuario}
        onClose={() => {
          setOpenDrawerDesassociar(false);
          onClose();
        }}
      />
    </>
  );
};

export default CardAssociarCartao;
