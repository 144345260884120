import { Agencia } from '../models/Agencia';
import { APIPaginatedRequest } from '../models/APIPaginatedRequest';
import { APIResponse } from '../models/ApiResponse.model';

import Service from './Service';

class TotalBusService extends Service {
  static async buscarAgencia(
    termoPesquisa: APIPaginatedRequest<{ termo: string }>
  ): Promise<Agencia[]> {
    return this.Http.get<APIResponse<{ listaAgencias: Agencia[] }>>(
      `/administrativo/totalbus/agencia/${termoPesquisa.search.termo}`
    )
      .then(this.getData)
      .then((resp) => resp.data.listaAgencias)
      .catch(() => []);
  }
}

export default TotalBusService;
