import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';

import UsuarioService from '../../../services/Usuario.service';
import Drawer from '../../components/Drawer';

import ConteudoBloqueio from './ConteudoBloqueio';

interface Props {
  open: boolean;
  onClose: () => void;
  usuario: Usuario;
}

interface Usuario {
  id: string;
  nome: string;
  cpfCnpj: string;
}

const DrawerBloqueioUsuario = ({ usuario, open, onClose }: Props) => {
  const [justificativa, setJustificativa] = useState('');
  const [bloqueioStatus, setBloqueioStatus] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  if (!usuario) return null;

  const onClick = () => {
    setLoading(true);
    UsuarioService.alterarBloqueio(usuario.id, !bloqueioStatus, justificativa)
      .then(() => {
        setCompleted(true);
        setBloqueioStatus(!bloqueioStatus);
      })
      .catch((err) => {
        setErrorMsg(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Drawer open={open} title="Bloqueio de Usuario" onClose={onClose}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        padding={3}
        width="100%"
      >
        <Box height="100%">
          <ConteudoBloqueio
            loading={loading}
            errorMsg={errorMsg}
            completed={completed}
            usuario={usuario}
            justificativa={justificativa}
            setJustificativa={setJustificativa}
            handleClose={onClose}
          />
        </Box>
        {!completed && !loading && (
          <Box display="flex" justifyContent="flex-end">
            <Button
              onClick={onClick}
              disabled={justificativa.trim() === ''}
              color="primary"
              variant="contained"
            >
              <Typography textTransform="none">Confirmar</Typography>
            </Button>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default DrawerBloqueioUsuario;
