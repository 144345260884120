import { Grid, Typography } from '@mui/material';

import { Cliente } from '../../../models/Cliente.model';
import { Colors } from '../../../shared/colors';

interface Props {
  cliente: Cliente;
}

export const DetalheCliente = ({ cliente }: Props) => (
  <Grid item container flex={1} marginLeft={2}>
    <Grid item xs={5} container display="flex" flexDirection="column">
      <Typography variant="body1" color={Colors.gray1} lineHeight={1.5}>
        {cliente.clientePosPago && `${cliente.idClienteTotalBus} - `}
        {cliente.nome}
      </Typography>

      <Typography variant="caption" color={Colors.gray1}>
        {cliente.cpfCnpj.length > 14 ? 'CNPJ: ' : 'CPF: '}
        {cliente.cpfCnpj}
      </Typography>
    </Grid>
  </Grid>
);
