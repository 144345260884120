import { useState } from 'react';

import {
  FiltroClientes,
  FILTRO_CLIENTE_DEFAULT
} from '../../../models/FiltroClientes.model';
import Drawer from '../../components/Drawer';

import FiltrarClientes from './FiltrarClientes';

interface Props {
  open: boolean;
  onClose: (data: FiltroClientes) => void;
  permitirFiltrarUsuarios?: boolean;
}

const DrawerFiltrarCliente = ({ open, onClose }: Props) => {
  const [filtroCliente, setFiltroCliente] = useState<FiltroClientes>(
    FILTRO_CLIENTE_DEFAULT
  );

  const onCloseDrawer = (filtroCliente: FiltroClientes) => {
    setFiltroCliente(filtroCliente);
    onClose(filtroCliente);
  };

  return (
    <Drawer
      title="Filtrar"
      open={open}
      onClose={() => {
        onCloseDrawer(filtroCliente);
      }}
    >
      <FiltrarClientes
        filtroClientes={filtroCliente}
        onChangeFilter={setFiltroCliente}
        onClose={onCloseDrawer}
      />
    </Drawer>
  );
};

export default DrawerFiltrarCliente;
