import { mdiCashClock, mdiCreditCardOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Radio, Typography } from '@mui/material';

import { ReactComponent as PicPay } from '../../../assets/picpay.svg';
import { ReactComponent as Pix } from '../../../assets/pix.svg';
import { TipoPagamento } from '../../../models/TipoPagamento.model';
import { Colors } from '../../../shared/colors';

interface Props {
  tipo: TipoPagamento;
  onChange: (tipo: TipoPagamento | null) => void;
  checked: (tipo: TipoPagamento | null) => boolean;
}

export const CardTipoPagamento = ({ tipo, checked, onChange }: Props) => {
  const getLogo = () => {
    if (
      tipo === TipoPagamento.CartaoCredito ||
      tipo === TipoPagamento.CartaoDebito
    )
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="start"
          flexDirection="column"
        >
          <Icon path={mdiCreditCardOutline} color={Colors.gray1} size={1.5} />
          <Typography fontWeight="bold">
            Cartão de
            {`${tipo === TipoPagamento.CartaoCredito ? ' crédito' : ' débito'}`}
          </Typography>
        </Box>
      );

    if (tipo === TipoPagamento.PJPosPago)
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="start"
          flexDirection="column"
        >
          <Icon path={mdiCashClock} color={Colors.gray1} size={1.5} />
          <Typography fontWeight="bold">Pós-Pago</Typography>
        </Box>
      );

    if (tipo === TipoPagamento.Pix) return <Pix />;
    if (tipo === TipoPagamento.PicPay) return <PicPay />;
  };

  return (
    <Box
      p={2.5}
      width={164}
      // height={164}
      borderRadius={1}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      boxShadow="0px 4px 12px rgba(7, 18, 112, 0.25)"
    >
      <Box m={-1.5} flex={1}>
        <Radio
          value={tipo}
          checked={checked(tipo)}
          onChange={(e, checked) => {
            onChange(checked ? tipo : null);
          }}
        />
      </Box>
      <Box
        flex={1}
        display="flex"
        alignContent="center"
        justifyContent="center"
      >
        <Box alignSelf="center">{getLogo()}</Box>
      </Box>
    </Box>
  );
};
