import { useEffect, useState } from 'react';

import {
  FiltroPosPago,
  FILTRO_POS_PAGO_DEFAULT
} from '../../../models/FiltroPosPago.model';
import Drawer from '../../components/Drawer';

import FiltrarConsumoPosPago from './FiltrarConsumoPosPago';

interface Props {
  open: boolean;
  periodo: Date;
  onClose: (data: FiltroPosPago) => void;
  permitirFiltrarUsuarios?: boolean;
}

const DrawerFiltrarConsumoPosPago = ({ open, onClose, periodo }: Props) => {
  const [filtroPosPago, setFiltroPosPago] = useState<FiltroPosPago>({});

  const onCloseDrawer = (filtroPosPago: FiltroPosPago) => {
    setFiltroPosPago(filtroPosPago);
    onClose(filtroPosPago);
  };

  useEffect(() => {
    const mes = periodo.getMonth() + 1;
    const ano = periodo.getFullYear();

    setFiltroPosPago({ ...FILTRO_POS_PAGO_DEFAULT, mes, ano });
  }, [periodo]);

  return (
    <Drawer
      title="Filtrar"
      open={open}
      onClose={() => {
        onCloseDrawer(filtroPosPago);
      }}
    >
      <FiltrarConsumoPosPago
        filtroPosPago={filtroPosPago}
        onChangeFilter={setFiltroPosPago}
        onClose={onCloseDrawer}
      />
    </Drawer>
  );
};

export default DrawerFiltrarConsumoPosPago;
