import { InteractionType } from '@azure/msal-browser';
import {
  MsalAuthenticationResult,
  MsalAuthenticationTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication
} from '@azure/msal-react';
import { Box, Container } from '@mui/material';
import { PropsWithChildren, useLayoutEffect } from 'react';
import { Routes as ReactRoutes, Route, useNavigate } from 'react-router-dom';

import AppBar from '../app/components/AppBar';
import { ErrorBoundary } from '../app/components/ErrorBoundary';
import { MsalConfig } from '../app/features/authentication/config/msalConfig';
import AccessDenied from '../app/pages/AccessDenied';
import AssociarCartao from '../app/pages/AssociarCartao';
import CargaDistancia from '../app/pages/CargaDistancia';
import ConsumoPosPago from '../app/pages/ConsumoPosPago';
import GestaoClientes from '../app/pages/GestaoClientes';
import GestaoComissoes from '../app/pages/GestaoComissoes';
import GestaoUsuarios from '../app/pages/GestaoUsuarios';
import Home from '../app/pages/Home';
import NotFound from '../app/pages/NotFound';
import { useUserContext } from '../contexts/User.context';
import Documentation from '../docs/Documentation';

import { CadastroRoutes } from './cadastro.routes';

export const Routes = {
  Home: '/',
  gestaoUsuarios: {
    Principal: '/gestao-usuarios'
  } as const,
  Cadastro: {
    Principal: '/cadastro',
    Pf: '/cadastro/pf',
    Pj: '/cadastro/pj'
  } as const,
  associacaoCartoes: {
    Principal: '/associacao-de-cartao'
  } as const,
  manual: {
    Principal: '/manual'
  } as const,
  cargaDistancia: {
    Principal: '/carga-distancia'
  } as const,
  gestaoClientes: {
    Principal: '/gestao-clientes'
  } as const,
  gestaoComissoes: {
    Principal: '/gestao-comissoes'
  } as const,
  consumoPosPago: {
    Principal: '/pos-pago'
  } as const,
  accessDenied: {
    Principal: '/access-denied'
  } as const
} as const;

const AuthError: React.ElementType<MsalAuthenticationResult> = () => {
  const request = { scopes: MsalConfig.scopes };
  useMsalAuthentication(InteractionType.Redirect, request);
  return null;
};

export const Layout = ({ children }: PropsWithChildren) => {
  const { usuario } = useUserContext();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (!usuario) return;
    if (!usuario.ativo) navigate(Routes.accessDenied.Principal);
  }, [navigate, usuario, usuario?.ativo]);

  //  if (loading) return <Loading fullscreen show />;

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <AppBar />
      <Container maxWidth="lg" sx={{ height: 1 }}>
        {children}
      </Container>
    </Box>
  );
};

export const AuthenticationTemplate = ({ children }: PropsWithChildren) => {
  return (
    <MsalAuthenticationTemplate
      errorComponent={AuthError}
      interactionType={InteractionType.Redirect}
      authenticationRequest={{ scopes: MsalConfig.scopes }}
    >
      <Layout>{children}</Layout>
    </MsalAuthenticationTemplate>
  );
};

export const withoutAuthentication = (Component: () => JSX.Element) => {
  return (
    <UnauthenticatedTemplate>
      <Layout>
        <Component />
      </Layout>
    </UnauthenticatedTemplate>
  );
};

export const AppRoutes = () => {
  const navigate = useNavigate();

  return (
    <ReactRoutes>
      <Route
        path={Routes.Home}
        element={
          <AuthenticationTemplate>
            <Home />
          </AuthenticationTemplate>
        }
      />
      <Route
        path={`${Routes.Cadastro.Principal}/*`}
        element={
          <AuthenticationTemplate>
            <ErrorBoundary navigate={navigate}>
              <CadastroRoutes />
            </ErrorBoundary>
          </AuthenticationTemplate>
        }
      />
      <Route
        path={Routes.gestaoUsuarios.Principal}
        element={
          <AuthenticationTemplate>
            <GestaoUsuarios />
          </AuthenticationTemplate>
        }
      />
      <Route
        path={Routes.gestaoComissoes.Principal}
        element={
          <AuthenticationTemplate>
            <GestaoComissoes />
          </AuthenticationTemplate>
        }
      />
      <Route
        path={Routes.associacaoCartoes.Principal}
        element={
          <AuthenticationTemplate>
            <AssociarCartao />
          </AuthenticationTemplate>
        }
      />
      <Route
        path={Routes.gestaoClientes.Principal}
        element={
          <AuthenticationTemplate>
            <GestaoClientes />
          </AuthenticationTemplate>
        }
      />
      <Route
        path={Routes.cargaDistancia.Principal}
        element={
          <AuthenticationTemplate>
            <CargaDistancia />
          </AuthenticationTemplate>
        }
      />
      <Route
        path={Routes.consumoPosPago.Principal}
        element={
          <AuthenticationTemplate>
            <ConsumoPosPago />
          </AuthenticationTemplate>
        }
      />
      <Route
        path={Routes.manual.Principal}
        element={
          <AuthenticationTemplate>
            <Documentation />
          </AuthenticationTemplate>
        }
      />
      <Route
        path={Routes.accessDenied.Principal}
        element={
          <AuthenticationTemplate>
            <AccessDenied />
          </AuthenticationTemplate>
        }
      />
      <Route
        path="*"
        element={
          <AuthenticationTemplate>
            <NotFound />
          </AuthenticationTemplate>
        }
      />
    </ReactRoutes>
  );
};
