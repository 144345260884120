import { isAfter, isBefore, isDate } from 'date-fns';
import * as yup from 'yup';

import { validarCNPJ } from '../shared/functions/validarCNPJ';
import { validarCPF } from '../shared/functions/validarCpf';

import { TipoTransacao } from './Transacao.model';
export interface FiltroTransacoes {
  agenteMatricula?: string;
  numeroAutorizacao?: string;
  dataInicio?: Date | null;
  dataFinal?: Date | null;
  tipoTransacao?: TipoTransacao | '';
  cliente?: string;
  cpfCnpj?: string;
  nomeAgencia?: string;
}

export const FILTRO_DEFAULT: FiltroTransacoes = {
  agenteMatricula: '',
  dataInicio: null as any,
  dataFinal: null as any,
  numeroAutorizacao: '',
  tipoTransacao: '',
  nomeAgencia: '',
  cliente: '',
  cpfCnpj: ''
};

export const filtroTransacoesFormModelSchema: yup.ObjectSchema<FiltroTransacoes> =
  yup.object().shape(
    {
      agenteMatricula: yup.string().optional(),
      cliente: yup.string().optional(),
      numeroAutorizacao: yup.string().optional(),
      cpfCnpj: yup
        .string()
        .transform((val) => val.replace(/[^\d]+/g, ''))
        .test('Teste documento', 'Documento inválido', (value) => {
          if (!value) return true;
          if (value.length <= 11) return validarCPF(value);
          return validarCNPJ(value);
        })
        .optional(),
      dataInicio: yup
        .date()
        .nullable()
        .when('dataFinal', ([dataFinal], schema) => {
          const REQUIRED = 'A data de início também deve ser preenchida!';
          if (!dataFinal) return schema.optional();
          if (!isDate(dataFinal)) return schema.required(REQUIRED);

          return schema
            .test(
              'test-endDate',
              'A data de início deve ser inferior à data final!',
              (dataInicio) => {
                return !!dataInicio && !isAfter(dataInicio, dataFinal);
              }
            )
            .required(REQUIRED);
        })
        .notRequired(),
      dataFinal: yup
        .date()
        .nullable()
        .when('dataInicio', ([dataInicio], schema) => {
          const REQUIRED = 'A data final também deve ser preenchida!';

          if (!dataInicio) return schema.optional();
          if (!isDate(dataInicio)) return schema.required(REQUIRED);
          return schema
            .test(
              'test-endDate',
              'A data final deve ser superior à data de início!',
              (dataFinal) => !!dataFinal && !isBefore(dataFinal, dataInicio)
            )
            .required(REQUIRED);
        })
        .notRequired(),
      tipoTransacao: yup.mixed<TipoTransacao | ''>().optional(),
      nomeAgencia: yup.string().optional()
    },
    [['dataInicio', 'dataFinal']]
  );
